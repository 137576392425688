import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import Button from "react-bootstrap/Button";
import useEventListener from "../../../utils/useEventListener";
import { Link } from "react-router-dom";

interface ILink {
  name: string;
  url: string;
}

const links: ILink[] = [
  {
    name: "Calendly",
    url: "/webhooks/calendly/create"
  },
  {
    name: "Copper CRM",
    url: "/webhooks/copper/create"
  },
  {
    name: "Salesforce",
    url: "/webhooks/salesforce/create"
  },
  {
    name: "Stripe",
    url: "/webhooks/stripe/create"
  }
];

const CreateLinks = () => {
  const [addAccountOpen, setAddAccountOpen] = useState(false);
  const handler = () => {
    if (addAccountOpen) {
      setAddAccountOpen(false);
    }
  };

  useEventListener("click", handler);

  return (
    <div className="dropdown no-arrow">
      <Button
        variant="link"
        data-toggle="dropdown"
        aria-haspopup="true"
        onClick={() => setAddAccountOpen(!addAccountOpen)}
        className="d-inline-block btn btn-sm btn-primary shadow dropdown-toggle text-white"
      >
        <FontAwesomeIcon icon={faPlus} fixedWidth={true} className="mr-1" />
        Create webhook
      </Button>
      <div
        className={clsx(
          "dropdown-menu dropdown-menu-right shadow animated--grow-in",
          { show: addAccountOpen }
        )}
        aria-labelledby="createWebhookDropdown"
      >
        {links.map(link => (
          <Link
            to={link.url}
            className="dropdown-item"
            key={`${link.name}-${link.url}`}
          >
            {link.name}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default CreateLinks;
