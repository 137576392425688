import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faCogs,
  faSignOutAlt,
  faUserCircle
} from "@fortawesome/free-solid-svg-icons";
import useEventListener from "../../utils/useEventListener";
import Button from "react-bootstrap/Button";
import clsx from "clsx";
import { Link } from "react-router-dom";

interface Props {
  setSideBarOpen: (value: boolean) => void;
  isSideBarOpen: boolean;
  userName: string;
}

const TopBar = (props: Props) => {
  const { setSideBarOpen, isSideBarOpen, userName } = props;
  const sidebarToggleTopHandler = () => {
    if (!isSideBarOpen) {
      document.body.classList.add("sidebar-toggled");
    } else {
      document.body.classList.remove("sidebar-toggled");
    }
    setSideBarOpen(!isSideBarOpen);
  };

  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const handler = () => {
    if (userMenuOpen) {
      setUserMenuOpen(false);
    }
  };

  useEventListener("click", handler);

  return (
    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
      <button
        id="sidebarToggleTop"
        className="btn btn-link d-md-none rounded-circle mr-3"
        onClick={sidebarToggleTopHandler}
      >
        <FontAwesomeIcon icon={faBars} />
      </button>

      {/* Remove for now, see if users need it later. */}
      {/* <Search /> */}

      <ul className="navbar-nav ml-auto">
        <li className="nav-item dropdown no-arrow">
          <Button
            className="nav-link dropdown-toggle"
            variant="link"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            onClick={() => setUserMenuOpen(!userMenuOpen)}
          >
            <span className="mr-2 d-inline text-gray-600 small">
              {userName}
            </span>
            <FontAwesomeIcon
              className="d-block"
              icon={faUserCircle}
              fixedWidth={true}
              size="lg"
            />
          </Button>
          <div
            className={clsx(
              "dropdown-menu dropdown-menu-right shadow animated--grow-in",
              { show: userMenuOpen }
            )}
            aria-labelledby="userDropdown"
          >
            <Link className="dropdown-item" to="/settings">
              <FontAwesomeIcon
                icon={faCogs}
                fixedWidth={true}
                size="sm"
                className="mr-2 text-gray-400"
              />
              Settings
            </Link>
            <div className="dropdown-divider" />
            <Link className="dropdown-item" to="/logout">
              <FontAwesomeIcon
                icon={faSignOutAlt}
                fixedWidth={true}
                size="sm"
                className="mr-2 text-gray-400"
              />
              Logout
            </Link>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default TopBar;
