import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faBoxes } from "@fortawesome/free-solid-svg-icons";
import { faSalesforce } from "@fortawesome/free-brands-svg-icons";
import clsx from "clsx";
import Button from "react-bootstrap/Button";
import useEventListener from "../../../utils/useEventListener";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import { LinkContainer } from "react-router-bootstrap";

const SFDC_LINKS_QUERY = gql`
  query SalesforceLinks {
    sandbox: salesforceOAuthInfo(environment: SANDBOX) {
      loginUrl
    }
    prod: salesforceOAuthInfo(environment: PROD) {
      loginUrl
    }
  }
`;

interface SalesforceLinksResponse {
  sandbox?: { loginUrl: string };
  prod?: { loginUrl: string };
}

const ConnectLinks = () => {
  const [addAccountOpen, setAddAccountOpen] = useState(false);
  const handler = () => {
    if (addAccountOpen) {
      setAddAccountOpen(false);
    }
  };

  useEventListener("click", handler);

  return (
    <Query<SalesforceLinksResponse>
      query={SFDC_LINKS_QUERY}
      fetchPolicy="cache-and-network"
    >
      {({ data, loading, error }) => {
        const prodUrl = data && data.prod ? data.prod.loginUrl : "#";
        const sandboxUrl = data && data.sandbox ? data.sandbox.loginUrl : "#";

        return (
          <div>
            <div className="dropdown no-arrow d-inline-block">
              <Button
                variant="link"
                data-toggle="dropdown"
                aria-haspopup="true"
                onClick={() => setAddAccountOpen(!addAccountOpen)}
                className="d-inline-block btn btn-sm btn-primary shadow dropdown-toggle text-white"
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  fixedWidth={true}
                  className="mr-1"
                />
                Add new account
              </Button>
              <div
                className={clsx(
                  "dropdown-menu dropdown-menu-right shadow animated--grow-in",
                  { show: addAccountOpen }
                )}
                aria-labelledby="sfdcAddAccountDropdown"
              >
                <a
                  className={clsx("dropdown-item", { disabled: loading })}
                  href={sandboxUrl}
                >
                  <FontAwesomeIcon
                    icon={faBoxes}
                    fixedWidth={true}
                    size="sm"
                    className="mr-2 text-gray-400"
                  />
                  Sandbox
                </a>
                <div className="dropdown-divider" />
                <a
                  className={clsx("dropdown-item", { disabled: loading })}
                  href={prodUrl}
                >
                  <FontAwesomeIcon
                    icon={faSalesforce}
                    fixedWidth={true}
                    size="sm"
                    className="mr-2 text-gray-400"
                  />
                  Production
                </a>
              </div>
            </div>
            <LinkContainer to="/webhooks/salesforce/create">
              <Button
                className="d-inline-block shadow ml-2"
                variant="secondary"
                size="sm"
              >
                Create webhook
              </Button>
            </LinkContainer>
          </div>
        );
      }}
    </Query>
  );
};

export default ConnectLinks;
