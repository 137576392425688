import React from "react";
import BootstrapTable, { TableProps } from "react-bootstrap/Table";
import CenteredSpinner from "../CenteredSpinner";
import clsx from "clsx";
import get from "lodash.get";
import BootstrapPagination from "react-bootstrap/Pagination";

export interface Column<T> {
  key: keyof T; // when iterating through the rows, this key will be used to get the value
  label?: string; // the label displayed in the table
  hidden?: boolean;
  renderer?: (data: { cell: any; row: T }) => React.ReactNode;
  disableOnRowClick?: boolean;
  className?: string;
}

interface PaginationProps {
  hasNextPage: boolean;
  hasPreviousPage: boolean;

  onNextPage: () => void;
  onPreviousPage: () => void;
}

export interface Props<T> extends TableProps {
  columns: Column<T>[];
  idKey: string;
  data: T[];
  loading: boolean;

  pagination?: PaginationProps;

  onRowClick?: (cell: T) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  hasNextPage,
  hasPreviousPage,
  onNextPage,
  onPreviousPage
}) => {
  return (
    <BootstrapPagination
      size="lg"
      className="d-flex align-items-center justify-content-center"
    >
      <BootstrapPagination.Prev
        disabled={!hasPreviousPage}
        onClick={() => hasPreviousPage && onPreviousPage()}
        className="font-weight-bold"
      />
      <BootstrapPagination.Next
        disabled={!hasNextPage}
        onClick={() => hasNextPage && onNextPage()}
        className="font-weight-bold"
      />
    </BootstrapPagination>
  );
};

export default function Table<T>(props: Props<T>) {
  const {
    columns,
    data,
    loading,
    idKey,
    pagination,
    onRowClick,
    ...rest
  } = props;

  if (loading) {
    return <CenteredSpinner />;
  }

  return (
    <>
      <BootstrapTable {...rest}>
        <thead>
          <tr>
            {columns.map((col, idx) => (
              <th
                key={`${col.key}-${idx}`}
                className={clsx({ "d-none": Boolean(col.hidden) })}
              >
                {col.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map(item => (
            <tr
              key={String(get(item, idKey))}
              className={clsx({ clickable: onRowClick != null })}
            >
              {columns.map((col, idx) => (
                <td
                  key={`${get(item, idKey)}-${col.key}-${idx}`}
                  className={clsx(col.className, {
                    "d-none": Boolean(col.hidden)
                  })}
                  onClick={
                    onRowClick != null && !col.disableOnRowClick
                      ? () => onRowClick(item)
                      : undefined
                  }
                >
                  {col.renderer
                    ? col.renderer({ cell: get(item, col.key), row: item })
                    : get(item, col.key)}
                </td>
              ))}
            </tr>
          ))}
          {data.length === 0 && (
            <tr>
              <td colSpan={columns.length} className="text-center font-italic">
                No data yet
              </td>
            </tr>
          )}
        </tbody>
      </BootstrapTable>
      {pagination && <Pagination {...pagination} />}
    </>
  );
}
