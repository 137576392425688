import React from "react";
import gql from "graphql-tag";
import { ApolloError } from "apollo-client";
import { Query } from "react-apollo";
import Helmet from "react-helmet";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { LinkContainer } from "react-router-bootstrap";
import Button from "react-bootstrap/Button";
import CenteredSpinner from "../../components/CenteredSpinner";
import GenericError from "../../components/GenericError";
import ChangeBillingEmailForm from "./ChangeBillingEmailForm";
import CancelSubscriptionForm from "./CancelSubscriptionForm";
import {
  BillingSummary,
  BillingSummary_me_organization,
  SubscriptionState
} from "../../graphql/types";
import Moment from "react-moment";
import Table from "react-bootstrap/Table";

interface Props {
  loading: boolean;
  error?: ApolloError;
  billingOrganization?: BillingSummary_me_organization | null;
}

const BillingSummaryPage: React.FC<Props> = ({
  loading,
  error,
  billingOrganization: organization
}) => {
  return (
    <>
      <Helmet>
        <title>Billing – HookActions</title>
      </Helmet>

      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Billing</h1>
      </div>

      <Row>
        <Col md={4} sm={6} xs={12}>
          <Card className="shadow mb-4">
            <Card.Header>Details</Card.Header>
            <Card.Body>
              {loading && <CenteredSpinner />}
              <GenericError error={error} />
              {organization && (
                <>
                  <Table className="kv kv--40">
                    <tbody>
                      <tr>
                        <td>Organization</td>
                        <td>{organization.name}</td>
                      </tr>
                      <tr>
                        <td>Billing email</td>
                        <td>{organization.billingEmailAddress}</td>
                      </tr>
                      {organization.freeTrialEnd &&
                        !organization.hasActiveSubscription && (
                          <tr>
                            <td>Free trial end date</td>
                            <td>
                              <Moment format="ll">
                                {organization.freeTrialEnd}
                              </Moment>
                            </td>
                          </tr>
                        )}
                      {organization.daysLeftInFreeTrial != null &&
                        !organization.hasActiveSubscription && (
                          <tr>
                            <td>Days left in free trial</td>
                            <td>
                              {organization.daysLeftInFreeTrial.toLocaleString()}
                            </td>
                          </tr>
                        )}
                      {organization.subscriptionDetails && (
                        <>
                          {organization.subscriptionDetails.state !==
                            SubscriptionState.UNKNOWN && (
                            <tr>
                              <td>Subscription state</td>
                              <td>
                                {organization.subscriptionDetails.state.toLowerCase()}
                              </td>
                            </tr>
                          )}
                          {organization.subscriptionDetails.nextBillingDate &&
                            organization.hasActiveSubscription && (
                              <tr>
                                <td>Next billing date</td>
                                <td>
                                  <Moment format="ll">
                                    {
                                      organization.subscriptionDetails
                                        .nextBillingDate
                                    }
                                  </Moment>
                                </td>
                              </tr>
                            )}
                        </>
                      )}
                    </tbody>
                  </Table>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} sm={6} xs={12}>
          <Card className="shadow mb-4">
            <Card.Header>Change billing email</Card.Header>
            <Card.Body>
              <p>
                This email will be associated with the billing account for your
                organization. It will receive important invoice notifications
                and receipts.
              </p>
              <ChangeBillingEmailForm />
            </Card.Body>
          </Card>
        </Col>
        {organization && !organization.hasActiveSubscription && (
          <Col md={4} sm={6} xs={12}>
            <Card className="shadow mb-4">
              <Card.Header>Start subscription</Card.Header>
              <Card.Body>
                <p>
                  If you don't have an active subscription, you can start your
                  subscription here.
                </p>
                <LinkContainer to="/billing/start-subscription">
                  <Button variant="outline-primary">Start subscription</Button>
                </LinkContainer>
              </Card.Body>
            </Card>
          </Col>
        )}
        {organization && organization.hasActiveSubscription && (
          <Col md={4} sm={6} xs={12}>
            <Card className="shadow mb-4">
              <Card.Header>Cancel subscription</Card.Header>
              <Card.Body>
                <p>
                  If you have an active subscription and wish to cancel, you can
                  do so below.
                </p>
                <p>
                  You will be given a prorated credit for the remaining
                  subscription period.
                </p>
                <CancelSubscriptionForm />
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    </>
  );
};

const BILLING_QUERY = gql`
  query BillingSummary {
    me {
      id

      organization {
        id
        name

        billingEmailAddress
        freeTrialEnd
        daysLeftInFreeTrial
        hasValidSubscription
        hasActiveSubscription

        subscriptionDetails {
          state
          nextBillingDate
        }
      }
    }
  }
`;

const WithQuery: React.FC = () => (
  <Query<BillingSummary> query={BILLING_QUERY}>
    {({ loading, data, error }) => (
      <BillingSummaryPage
        loading={loading}
        error={error}
        billingOrganization={data && data.me && data.me.organization}
      />
    )}
  </Query>
);

export default WithQuery;
