import React, { useState } from "react";
import gql from "graphql-tag";
import { SalesforceAccountNode } from "../../../graphql/salesforce";
import TimeRenderer from "../../../components/TimeRenderer";
import { Link } from "react-router-dom";
import { Column } from "../../../components/Table";
import Table from "../../../components/GraphQLTable";
import Button from "react-bootstrap/Button";
import { Mutation } from "react-apollo";
import ConfirmDialog from "../../../components/Dialog/ConfirmDialog";
import { ApolloError } from "apollo-boost";

const SALESFORCE_ACCOUNTS = gql`
  query SalesforceAccounts($first: Int, $after: String, $before: String) {
    salesforceAccountsConnection(
      first: $first
      after: $after
      before: $before
    ) {
      edges {
        node {
          id
          instanceURL
          environment
          createdAt
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

interface Props {
  deleteAccount: (id: string) => void;
  deleteInProgress: boolean;
  error?: ApolloError;
}

interface IModalState {
  show: boolean;
  accountId?: string;
}

const SalesforceAccountTable: React.FC<Props> = ({
  deleteAccount,
  deleteInProgress,
  error
}) => {
  const [modalState, setModalState] = useState<IModalState>({ show: false });

  const columns: Column<SalesforceAccountNode>[] = [
    {
      key: "id",
      hidden: true
    },
    {
      key: "createdAt",
      label: "Created at",
      renderer: TimeRenderer
    },
    {
      key: "instanceURL",
      label: "Instance URL"
    },
    {
      key: "environment",
      label: "Environment"
    },
    {
      key: "id",
      // TODO: link to specific account
      renderer: () => <Link to={`/webhooks/salesforce`}>View webhooks</Link>
    },
    {
      key: "id",
      renderer: ({ cell }) => (
        <Button
          variant="link"
          className="text-danger p-0"
          onClick={() => setModalState({ show: true, accountId: cell })}
        >
          Delete
        </Button>
      )
    }
  ];

  return (
    <>
      <Table<SalesforceAccountNode>
        query={SALESFORCE_ACCOUNTS}
        name="salesforceAccountsConnection"
        columns={columns}
      />
      <ConfirmDialog
        variant="danger"
        actionText="Delete"
        loading={deleteInProgress}
        text={
          <>
            <p>Are you sure you want to delete this Salesforce account?</p>
            <p>
              This will delete <strong>all</strong> associated webhooks.
            </p>
          </>
        }
        title="Delete Salesforce account"
        error={error}
        show={modalState.show}
        handleCancel={() => setModalState({ show: false })}
        handleAction={() => deleteAccount(modalState.accountId!)}
      />
    </>
  );
};

const DELETE_SALESFORCE_ACCOUNT = gql`
  mutation DeleteSalesforceAccount($id: ID!) {
    deleteSalesforceAccount(id: $id)
  }
`;

interface DeleteSalesforceAccountVars {
  id: string;
}

interface DeleteSalesforceAccountResponse {
  deleteSalesforceAccount: boolean;
}

const WithMutation: React.FC = () => {
  return (
    <Mutation<DeleteSalesforceAccountResponse, DeleteSalesforceAccountVars>
      mutation={DELETE_SALESFORCE_ACCOUNT}
    >
      {(mutate, { loading, error }) => (
        <SalesforceAccountTable
          deleteAccount={id =>
            mutate({ variables: { id } }).then(() => window.location.reload())
          }
          deleteInProgress={loading}
          error={error}
        />
      )}
    </Mutation>
  );
};

export default WithMutation;
