import React, { useState } from "react";
import { DocumentNode } from "graphql";
import Table, { Column } from "../Table";
import { Query } from "react-apollo";
import get from "lodash.get";
import { ConnectionQueryVars, Node, Connection } from "../../graphql/relay";
import GenericError from "../GenericError";

interface Props<T extends Node, V = {}> {
  query: DocumentNode; // gql`...`
  name: string; // the name of the query (e.g. userLoginsConnection)
  pageSize?: number; // number of rows per page, default is 10
  columns: Column<T>[];
  onRowClick?: (cell: T) => void;
  additionalQueryVars?: V;
}
interface Response<T extends Node> {
  [key: string]: Connection<T>;
}

interface IPageState {
  after?: string;
  before?: string;
  page: number;
}

export default function GraphQLTable<T extends Node, V = {}>(
  props: Props<T, V>
) {
  const {
    query,
    name,
    pageSize = 10,
    columns,
    onRowClick,
    additionalQueryVars
  } = props;
  const [pageState, setPageState] = useState<IPageState>({ page: 0 });

  const getTotalPages = (totalCount?: number): number => {
    if (totalCount == null) return 0;
    return Math.ceil(totalCount / pageSize);
  };

  const makeOnNextPage = (totalCount?: number, endCursor?: string) => () => {
    if (totalCount == null) return;
    if (endCursor) {
      setPageState({
        after: endCursor,
        before: undefined,
        page: pageState.page + 1
      });
    }
  };

  const makeOnPreviousPage = (
    totalCount?: number,
    startCursor?: string
  ) => () => {
    if (totalCount == null) return;
    if (startCursor) {
      setPageState({
        after: undefined,
        before: startCursor,
        page: pageState.page - 1
      });
    }
  };

  const getHasNextPage = (
    totalCount?: number,
    hasNextPage?: boolean
  ): boolean => {
    if (totalCount == null) return false;
    const numPages = getTotalPages(totalCount);
    return hasNextPage || pageState.page < numPages - 1;
  };

  const getHasPreviousPage = (
    totalCount?: number,
    hasPreviousPage?: boolean
  ): boolean => {
    if (totalCount == null) return false;
    return hasPreviousPage || pageState.page > 0;
  };

  return (
    <>
      <Query<Response<T>, ConnectionQueryVars>
        query={query}
        variables={{
          first: pageSize,
          before: pageState.before,
          after: pageState.after,
          ...additionalQueryVars
        }}
        // poll for updates every 5 seconds
        // TODO: make this configurable
        pollInterval={5000}
      >
        {({ data, loading, error }) => {
          if (error) {
            return <GenericError error={error} />;
          }

          const query = get(data, name);

          return (
            <Table<T>
              loading={loading}
              data={query && query.edges ? query.edges!.map(i => i.node!) : []}
              idKey={"id" as any} // todo: fix this "any"
              pagination={{
                hasNextPage: getHasNextPage(
                  query && query.totalCount,
                  query && query.pageInfo.hasNextPage
                ),
                hasPreviousPage: getHasPreviousPage(
                  query && query.totalCount,
                  query && query.pageInfo.hasPreviousPage
                ),
                onNextPage: makeOnNextPage(
                  query && query.totalCount,
                  query && query.pageInfo.endCursor
                ),
                onPreviousPage: makeOnPreviousPage(
                  query && query.totalCount,
                  query && query.pageInfo.startCursor
                )
              }}
              columns={columns}
              hover={true}
              onRowClick={onRowClick}
            />
          );
        }}
      </Query>
    </>
  );
}
