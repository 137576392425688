import React from "react";
import Helmet from "react-helmet";
import Card from "react-bootstrap/Card";
import SalesforceWebhookForm from "../SalesforceWebhookForm";

const CreateSalesforceWebhookPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Create Salesforce Webhook – HookActions</title>
      </Helmet>

      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Create Salesforce Webhook</h1>
      </div>

      <Card className="shadow mb-2">
        <Card.Header>New webhook details</Card.Header>
        <Card.Body>
          <SalesforceWebhookForm />
        </Card.Body>
      </Card>
    </>
  );
};

export default CreateSalesforceWebhookPage;
