import React, { useState } from "react";
import useForm from "react-hook-form";
import FormError, { HasError } from "../../components/FormError";
import { ApolloError, gql } from "apollo-boost";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { Mutation } from "react-apollo";
import Alert from "react-bootstrap/Alert";

interface Props {
  patchUser: (firstName: string, lastName: string) => Promise<any>;
  error?: ApolloError;
  loading: boolean;
  data?: PatchUserResponse;
}

const UserForm: React.FC<Props> = (props: Props) => {
  const { patchUser, error, loading, data } = props;
  const { register, handleSubmit, reset } = useForm();
  const [showAlert, setShowAlert] = useState(true);

  const onSubmit = (data: Record<string, any>) =>
    patchUser(data.firstName, data.lastName).then(() => {
      reset();
      setShowAlert(true);
    });

  return (
    <Form className="user" onSubmit={handleSubmit(onSubmit)}>
      {data && data.patchUser && data.patchUser.id && showAlert && (
        <Alert
          dismissible={true}
          onClose={() => setShowAlert(false)}
          variant="success"
        >
          User updated.
        </Alert>
      )}

      <Form.Group>
        <Form.Control
          className="form-control-user"
          type="name"
          placeholder="First Name"
          name="firstName"
          ref={register}
          isInvalid={HasError(error, "first_name")}
          disabled={loading}
        />
        <FormError errors={error} fieldName="first_name" />
      </Form.Group>
      <Form.Group>
        <Form.Control
          className="form-control-user"
          type="name"
          placeholder="Last Name"
          name="lastName"
          ref={register}
          isInvalid={HasError(error, "last_name")}
          disabled={loading}
        />
        <FormError errors={error} fieldName="last_name" />
      </Form.Group>

      <Button
        type="submit"
        className="btn-user"
        block={true}
        disabled={loading}
      >
        <span>Update</span>
        {loading && <Spinner animation="border" className="ml-2" size="sm" />}
      </Button>
    </Form>
  );
};

const PATCH_USER = gql`
  mutation PatchUser(
    $id: ID!
    $firstName: String
    $lastName: String
    $password: String
  ) {
    patchUser(
      id: $id
      patch: { firstName: $firstName, lastName: $lastName, password: $password }
    ) {
      id
      emailAddress
      firstName
      lastName
    }
  }
`;

interface PatchUserVars {
  id: string;
  firstName?: string;
  lastName?: string;
  password?: string;
}

interface PatchUserResponse {
  patchUser?: {
    id: string;
    emailAddress: string;
    firstName: string;
    lastName: string;
  };
}

interface MutationProps {
  userId: string;
}

const WithMutation: React.FC<MutationProps> = (props: MutationProps) => {
  const { userId: id } = props;

  return (
    <Mutation<PatchUserResponse, PatchUserVars> mutation={PATCH_USER}>
      {(mutate, { data, loading, error }) => (
        <UserForm
          patchUser={(firstName, lastName) =>
            mutate({ variables: { firstName, lastName, id } })
          }
          data={data}
          loading={loading}
          error={error}
        />
      )}
    </Mutation>
  );
};

export default WithMutation;
