import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import clsx from "clsx";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faAngleRight, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import useEventListener from "../../utils/useEventListener";

interface Props {
  icon: IconProp;
  name: string;
  header?: string;
  items: Array<{
    to: string;
    name: string;
  }>;
  isSideBarOpen: boolean;
}

const NavLinkCollapse: React.FC<Props> = props => {
  const [isOpen, setIsOpen] = useState(false);
  const { name, icon, header, items, isSideBarOpen } = props;
  const handler = () => {
    if (isOpen && !isSideBarOpen) {
      setIsOpen(false);
    }
  };

  useEventListener("click", handler);

  return (
    <li className="nav-item">
      <Button
        className={clsx("nav-link", { collapsed: !isOpen })}
        type="button"
        variant="link"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div>
          <FontAwesomeIcon
            fixedWidth={true}
            icon={icon}
            className={clsx({ "mr-2": isSideBarOpen })}
          />
          <span>{name}</span>
        </div>
        {isOpen && <FontAwesomeIcon icon={faAngleDown} />}
        {!isOpen && <FontAwesomeIcon icon={faAngleRight} />}
      </Button>
      <div className={clsx("collapse", { show: isOpen })}>
        <div className="bg-white py-2 collapse-inner rounded">
          {header && <h6 className="collapse-header">{header}</h6>}
          {items.map(item => (
            <Link
              className="collapse-item"
              to={item.to}
              key={`${item.to}-${item.name}`}
              onClick={() => setIsOpen(false)}
            >
              {item.name}
            </Link>
          ))}
        </div>
      </div>
    </li>
  );
};

export default NavLinkCollapse;
