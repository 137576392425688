import React, { useState } from "react";
import Autosuggest, { ChangeEvent } from "react-autosuggest";
import Form from "react-bootstrap/Form";
import "./auto-suggest.scss";

const makeSuggestionsGetter = (suggestions: string[]) => (value: string) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0
    ? suggestions
    : suggestions.filter(
        s => s.toLowerCase().slice(0, inputLength) === inputValue
      );
};

const getSuggestionValue = (suggestion: string) => suggestion;
const renderSuggestion = (suggestion: string) => suggestion;

function renderInputComponent<T>(inputProps: any) {
  return (
    <Form.Control
      type="text"
      {...inputProps}
      onChange={(e: any) =>
        inputProps.onChange(e, { newValue: e.target.value, method: "up" })
      }
    />
  );
}

type OnChange = (event: React.FormEvent<any>, params: ChangeEvent) => void;

interface Props {
  suggestions: string[];

  name: string;
  placeholder?: string;
  value: string;
  onChange: (newValue: string) => void;
}

const StringAutoSuggestField: React.FC<Props> = ({
  suggestions,
  name,
  placeholder,
  value,
  onChange
}) => {
  const [availableSuggestions, setAvailableSuggestions] = useState<string[]>(
    []
  );
  const getSuggestions = makeSuggestionsGetter(suggestions);
  const clearSuggestions = () => setAvailableSuggestions([]);
  const fetchSuggestions = ({ value }: { value: string }) =>
    setAvailableSuggestions(getSuggestions(value));
  const handleOnChange: OnChange = (event, { newValue }) => onChange(newValue);

  return (
    <Autosuggest
      id={name}
      suggestions={availableSuggestions}
      onSuggestionsFetchRequested={fetchSuggestions}
      onSuggestionsClearRequested={clearSuggestions}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      renderInputComponent={renderInputComponent}
      alwaysRenderSuggestions={true}
      inputProps={{
        name,
        placeholder,
        value: value || "",
        onChange: handleOnChange
      }}
    />
  );
};

export default StringAutoSuggestField;
