import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import CreateSalesforceWebhookPage from "./salesforce/create";
import CreateStripeWebhookPage from "./stripe/create";
import WebhooksListPage from "./webhooks-list";
import Page404 from "../404";
import CreateCalendlyWebhookPage from "./calendly/create";
import CreateCopperWebhookPage from "./copper/create";

const WebhooksPage: React.FC = () => {
  return (
    <Switch>
      <Route path="/webhooks" exact={true} component={WebhooksListPage} />

      {/* legacy redirects */}
      <Route
        path="/webhooks/stripe"
        exact={true}
        component={() => <Redirect to="/webhooks" />}
      />
      <Route
        path="/webhooks/salesforce"
        exact={true}
        component={() => <Redirect to="/webhooks" />}
      />

      <Route
        path="/webhooks/salesforce/create"
        exact={true}
        component={CreateSalesforceWebhookPage}
      />
      <Route
        path="/webhooks/stripe/create"
        exact={true}
        component={CreateStripeWebhookPage}
      />
      <Route
        path="/webhooks/calendly/create"
        exact={true}
        component={CreateCalendlyWebhookPage}
      />
      <Route
        path="/webhooks/copper/create"
        exact={true}
        component={CreateCopperWebhookPage}
      />

      <Route component={Page404} />
    </Switch>
  );
};

export default WebhooksPage;
