import React from "react";
import gql from "graphql-tag";
import clsx from "clsx";
import Button from "react-bootstrap/Button";
import { Query } from "react-apollo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { LinkContainer } from "react-router-bootstrap";

const STRIPE_LINK_QUERY = gql`
  query StripeLink {
    stripeConnectInfo {
      redirectUrl
    }
  }
`;

interface StripeLinkResponse {
  stripeConnectInfo?: {
    redirectUrl: string;
  };
}

const ConnectLink: React.FC = () => {
  return (
    <Query<StripeLinkResponse>
      query={STRIPE_LINK_QUERY}
      fetchPolicy="cache-and-network"
    >
      {({ data, loading, error }) => {
        const href =
          data && data.stripeConnectInfo
            ? data.stripeConnectInfo.redirectUrl
            : "#";

        return (
          <div>
            <Button
              href={href}
              className={clsx(
                "d-inline-block btn btn-sm btn-primary shadow text-white",
                { disabled: loading }
              )}
            >
              <FontAwesomeIcon
                icon={faPlus}
                fixedWidth={true}
                className="mr-1"
              />
              Add new account
            </Button>
            <LinkContainer to="/webhooks/stripe/create">
              <Button
                className="d-inline-block shadow ml-2"
                variant="secondary"
                size="sm"
              >
                Create webhook
              </Button>
            </LinkContainer>
          </div>
        );
      }}
    </Query>
  );
};

export default ConnectLink;
