import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

interface Props {
  cell: any;
}

const BooleanRenderer: React.FC<Props> = ({ cell }) =>
  Boolean(cell) ? (
    <FontAwesomeIcon title="true/yes" icon={faCheck} />
  ) : (
    <FontAwesomeIcon title="false/no" icon={faTimes} />
  );

export default BooleanRenderer;
