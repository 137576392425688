import React from "react";
import { Redirect } from "react-router";
import { removeToken, saveSidebarPreferences } from "../utils/localStorage";
import { toast } from "react-toastify";
import { ApolloConsumer } from "react-apollo";

const LogoutPage = () => (
  <ApolloConsumer>
    {client => {
      removeToken();
      saveSidebarPreferences(true);
      client.resetStore();
      toast("You are now logged out.", { type: "info" });
      return <Redirect to="/login" />;
    }}
  </ApolloConsumer>
);

export default LogoutPage;
