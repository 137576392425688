import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import gql from "graphql-tag";
import { Mutation, Query } from "react-apollo";
import { loadStripe, StripeError } from "@stripe/stripe-js";
import { ApolloError } from "apollo-client";
import Card from "react-bootstrap/Card";
import CenteredSpinner from "../../components/CenteredSpinner";
import GenericError from "../../components/GenericError";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ContactUs from "../../components/ContactUs";

interface Props {
  mutateInProgress: boolean;
  queryInProgress: boolean;
  error?: ApolloError;
  createCheckoutSession: () => void;

  checkoutSessionId?: string;
  stripePublishableKey?: string;
  hasActiveSubscription: boolean;
}

const StripeErrorDisplay: React.FC<{
  error?: StripeError | string;
}> = ({ error }) => {
  if (!error) {
    return null;
  }

  return (
    <Alert variant="danger">
      <Alert.Heading>Stripe error</Alert.Heading>
      {typeof error === "string" && (
        <>
          <p>{error}</p>
          <ContactUs
            subject="Stripe setup error"
            message="error loading stripe"
          />
        </>
      )}
      {typeof error !== "string" && (
        <>
          <p>There was an error redirecting you to checkout with Stripe.</p>
          <ContactUs
            subject="Stripe checkout error"
            message={JSON.stringify(error)}
          />
        </>
      )}
    </Alert>
  );
};

const StartSubscriptionPage: React.FC<Props> = ({
  mutateInProgress,
  queryInProgress,
  error,
  createCheckoutSession,
  checkoutSessionId,
  stripePublishableKey,
  hasActiveSubscription
}) => {
  const [runningStripe, setRunningStripe] = useState<boolean>(false);
  const [stripeError, setStripeError] = useState<
    StripeError | string | undefined
  >();

  useEffect(() => {
    (async () => {
      if (!!checkoutSessionId && !!stripePublishableKey) {
        setRunningStripe(true);

        const stripe = await loadStripe(stripePublishableKey);
        if (stripe == null) {
          setRunningStripe(false);
          setStripeError("Unable to setup stripe.");
          console.error("unable to setup stripe");
          return;
        }

        try {
          const { error } = await stripe.redirectToCheckout({
            sessionId: checkoutSessionId
          });
          setRunningStripe(false);
          setStripeError(error);
        } catch (e) {
          setRunningStripe(false);
          console.error(e);
          setStripeError("Unable to redirect to checkout.");
        }
      }
    })();
  }, [checkoutSessionId, stripePublishableKey]);

  return (
    <>
      <Helmet>
        <title>Start subscription – HookActions</title>
      </Helmet>

      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Start subscription</h1>
      </div>

      <Card className="shadow mb-4">
        <Card.Header>Setup a subscription for your organization</Card.Header>
        <Card.Body>
          <GenericError error={error} />
          <StripeErrorDisplay error={stripeError} />

          {hasActiveSubscription && (
            <p>
              Your organization already has a subscription set up. No action is
              needed right now.
            </p>
          )}
          {!hasActiveSubscription && (
            <>
              <p>
                After your free trial ends, you will no longer have access to
                HookActions. Start your subscription now to avoid service
                interruptions.
              </p>
              <p>
                Click the button below to be redirected to our payment provider
                (Stripe), to start your subscription. We currently accept
                payment via credit card.
              </p>
              <Button variant="primary" onClick={createCheckoutSession}>
                Start subscription
              </Button>
            </>
          )}
          {(queryInProgress || mutateInProgress || runningStripe) && (
            <CenteredSpinner />
          )}
        </Card.Body>
      </Card>
    </>
  );
};

const ORG_QUERY = gql`
  query OrgSubInfo {
    me {
      id

      organization {
        id

        hasActiveSubscription
      }
    }
  }
`;

interface Response {
  me?: {
    id: string;

    organization: {
      id: string;
      hasActiveSubscription: boolean;
    };
  };
}

const MUTATION = gql`
  mutation CreateCheckoutSession {
    createCheckoutSession {
      checkoutSessionId
      stripePublishableKey
    }
  }
`;

interface Response {
  createCheckoutSession?: {
    checkoutSessionId: string;
    stripePublishableKey: string;
  };
}

const WithMutation: React.FC = () => {
  return (
    <Query<Response> query={ORG_QUERY}>
      {({ data, loading: queryLoading, error: queryError }) => (
        <Mutation<Response> mutation={MUTATION}>
          {(mutate, { loading, error, data }) => (
            <StartSubscriptionPage
              mutateInProgress={loading}
              queryInProgress={queryLoading}
              error={queryError || error}
              createCheckoutSession={mutate}
              checkoutSessionId={
                data &&
                data.createCheckoutSession &&
                data.createCheckoutSession.checkoutSessionId
              }
              stripePublishableKey={
                data &&
                data.createCheckoutSession &&
                data.createCheckoutSession.stripePublishableKey
              }
              hasActiveSubscription={
                data && data.me
                  ? !data.me.organization.hasActiveSubscription
                  : false
              }
            />
          )}
        </Mutation>
      )}
    </Query>
  );
};

export default WithMutation;
