import React, { useState } from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import GenericError from "../GenericError";
import {
  AllFeaturesBulk,
  FeatureFlag,
  AllFeaturesBulk_features
} from "../../graphql/types";

interface FeaturesByflag {
  [key: string]: AllFeaturesBulk_features;
}

interface FeatureContext {
  features: AllFeaturesBulk_features[];
  featuresByFlag: FeaturesByflag;
}

const FEATURES_QUERY = gql`
  query AllFeaturesBulk {
    features {
      flag
      isEnabled
    }
  }
`;

export const Context = React.createContext<FeatureContext>({
  features: [],
  featuresByFlag: {}
});

const Features: React.FC = ({ children }) => {
  const [features, setFeatures] = useState<AllFeaturesBulk_features[]>([]);
  const featuresByFlag: FeaturesByflag = {};

  features.forEach(feature => {
    featuresByFlag[FeatureFlag[feature.flag] as string] = feature;
  });

  return (
    <Context.Provider value={{ features, featuresByFlag }}>
      <>
        <Query<AllFeaturesBulk>
          query={FEATURES_QUERY}
          fetchPolicy="cache-and-network"
          pollInterval={5000} // every 5s
          onCompleted={data => {
            if (data && data.features) {
              setFeatures(data.features);
            }
          }}
        >
          {({ loading, error }) => {
            if (!loading && error) {
              return <GenericError error={error} />;
            }

            return null;
          }}
        </Query>
        {children}
      </>
    </Context.Provider>
  );
};

export default Features;
