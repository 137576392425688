import React from "react";
import { Switch, Route } from "react-router";
import SalesforceOAuthCallbackPage from "./callback/salesforce";
import StripeOauthCallbackPage from "./callback/stripe";
import Page404 from "../404";

const OAuthPage = () => {
  return (
    <Switch>
      <Route
        path="/oauth/callback/salesforce"
        exact={true}
        component={SalesforceOAuthCallbackPage}
      />
      <Route
        path="/oauth/callback/stripe"
        exact={true}
        component={StripeOauthCallbackPage}
      />

      <Route component={Page404} />
    </Switch>
  );
};

export default OAuthPage;
