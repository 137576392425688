import React from "react";
import gql from "graphql-tag";
import Table from "../../components/GraphQLTable";
import { OrganizationUsers_me_organization_usersConnection_edges_node as UserNode } from "../../graphql/types";
import { Column } from "../../components/Table";
import TimeRenderer from "../../components/TimeRenderer";
import ResendActivationLink from "./ResendActivationLink";
import DeleteUserLink from "./DeleteUserLink";
import { AppContext } from "../../App";

const QUERY = gql`
  query OrganizationUsers($first: Int, $after: String, $before: String) {
    me {
      id

      organization {
        id

        usersConnection(first: $first, after: $after, before: $before) {
          edges {
            node {
              id
              firstName
              lastName
              emailAddress
              verifiedEmailAt
            }

            cursor
          }

          totalCount
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
        }
      }
    }
  }
`;

const UsersList: React.FC = () => (
  <AppContext.Consumer>
    {({ userId }) => {
      const columns: Column<UserNode>[] = [
        {
          key: "id",
          hidden: true
        },
        {
          key: "firstName",
          label: "First name"
        },
        {
          key: "lastName",
          label: "Last name"
        },
        {
          key: "emailAddress",
          label: "Email"
        },
        {
          key: "verifiedEmailAt",
          label: "Verified email at",
          renderer: ({ cell }) =>
            cell ? (
              <TimeRenderer cell={cell} />
            ) : (
              <span className="text-danger">unverified</span>
            )
        },
        {
          key: "id",
          renderer: ({ row }) =>
            row.verifiedEmailAt == null ? (
              <ResendActivationLink userId={row.id} />
            ) : null,
          disableOnRowClick: true
        },
        {
          key: "id",
          renderer: ({ row }) =>
            row.id === userId ? null : <DeleteUserLink userId={row.id} />,
          disableOnRowClick: true
        }
      ];

      return (
        <Table<UserNode>
          query={QUERY}
          columns={columns}
          name="me.organization.usersConnection"
        />
      );
    }}
  </AppContext.Consumer>
);

export default UsersList;
