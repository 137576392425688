/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllFeaturesBulk
// ====================================================

export interface AllFeaturesBulk_features {
  __typename: "Feature";
  flag: FeatureFlag;
  isEnabled: boolean;
}

export interface AllFeaturesBulk {
  features: AllFeaturesBulk_features[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AvailableTagsQuery
// ====================================================

export interface AvailableTagsQuery_webhookTagKeysConnection_edges_node_values {
  __typename: "WebhookTagValue";
  id: string;
  value: string;
}

export interface AvailableTagsQuery_webhookTagKeysConnection_edges_node {
  __typename: "WebhookTagKey";
  id: string;
  name: string;
  values: AvailableTagsQuery_webhookTagKeysConnection_edges_node_values[] | null;
}

export interface AvailableTagsQuery_webhookTagKeysConnection_edges {
  __typename: "WebhookTagKeyEdge";
  cursor: string;
  node: AvailableTagsQuery_webhookTagKeysConnection_edges_node | null;
}

export interface AvailableTagsQuery_webhookTagKeysConnection_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface AvailableTagsQuery_webhookTagKeysConnection {
  __typename: "WebhookTagKeyConnection";
  edges: (AvailableTagsQuery_webhookTagKeysConnection_edges | null)[] | null;
  totalCount: number;
  pageInfo: AvailableTagsQuery_webhookTagKeysConnection_pageInfo;
}

export interface AvailableTagsQuery {
  webhookTagKeysConnection: AvailableTagsQuery_webhookTagKeysConnection | null;
}

export interface AvailableTagsQueryVariables {
  first?: number | null;
  before?: string | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaymentsBannerQuery
// ====================================================

export interface PaymentsBannerQuery_me_organization {
  __typename: "Organization";
  id: string;
  freeTrialEnd: Time | null;
  daysLeftInFreeTrial: number | null;
  /**
   * if not in a free trial, then this returns whether or not the current org subscription state is valid
   */
  hasValidSubscription: boolean;
}

export interface PaymentsBannerQuery_me {
  __typename: "User";
  id: string;
  organization: PaymentsBannerQuery_me_organization;
}

export interface PaymentsBannerQuery {
  me: PaymentsBannerQuery_me | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AcceptInvitation
// ====================================================

export interface AcceptInvitation {
  acceptUserInvitation: boolean;
}

export interface AcceptInvitationVariables {
  code: UUID;
  newPassword: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CalendlyAccounts
// ====================================================

export interface CalendlyAccounts_calendlyAccountsConnection_edges_node {
  __typename: "CalendlyAccount";
  id: string;
  createdAt: Time;
  updatedAt: Time | null;
  name: string;
}

export interface CalendlyAccounts_calendlyAccountsConnection_edges {
  __typename: "CalendlyAccountEdge";
  node: CalendlyAccounts_calendlyAccountsConnection_edges_node | null;
  cursor: string;
}

export interface CalendlyAccounts_calendlyAccountsConnection_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface CalendlyAccounts_calendlyAccountsConnection {
  __typename: "CalendlyAccountConnection";
  edges: (CalendlyAccounts_calendlyAccountsConnection_edges | null)[] | null;
  pageInfo: CalendlyAccounts_calendlyAccountsConnection_pageInfo;
  totalCount: number;
}

export interface CalendlyAccounts {
  calendlyAccountsConnection: CalendlyAccounts_calendlyAccountsConnection | null;
}

export interface CalendlyAccountsVariables {
  first?: number | null;
  after?: string | null;
  before?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteCalendlyAccount
// ====================================================

export interface DeleteCalendlyAccount {
  deleteCalendlyAccount: boolean;
}

export interface DeleteCalendlyAccountVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCalendlyAccount
// ====================================================

export interface CreateCalendlyAccount_createCalendlyAccount {
  __typename: "CalendlyAccount";
  id: string;
  name: string;
  createdAt: Time;
  updatedAt: Time | null;
}

export interface CreateCalendlyAccount {
  createCalendlyAccount: CreateCalendlyAccount_createCalendlyAccount | null;
}

export interface CreateCalendlyAccountVariables {
  name: string;
  apiKey: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCopperCRMAccount
// ====================================================

export interface CreateCopperCRMAccount_createCopperCRMAccount {
  __typename: "CopperCRMAccount";
  id: string;
  name: string;
  createdAt: Time;
  updatedAt: Time | null;
}

export interface CreateCopperCRMAccount {
  createCopperCRMAccount: CreateCopperCRMAccount_createCopperCRMAccount | null;
}

export interface CreateCopperCRMAccountVariables {
  name?: string | null;
  apiKey: string;
  tokenOwnerEmail: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CopperCRMAccounts
// ====================================================

export interface CopperCRMAccounts_copperCRMAccountsConnection_edges_node {
  __typename: "CopperCRMAccount";
  id: string;
  createdAt: Time;
  updatedAt: Time | null;
  name: string;
  tokenOwnerEmail: string;
}

export interface CopperCRMAccounts_copperCRMAccountsConnection_edges {
  __typename: "CopperCRMAccountEdge";
  node: CopperCRMAccounts_copperCRMAccountsConnection_edges_node | null;
  cursor: string;
}

export interface CopperCRMAccounts_copperCRMAccountsConnection_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface CopperCRMAccounts_copperCRMAccountsConnection {
  __typename: "CopperCRMAccountConnection";
  edges: (CopperCRMAccounts_copperCRMAccountsConnection_edges | null)[] | null;
  pageInfo: CopperCRMAccounts_copperCRMAccountsConnection_pageInfo;
  totalCount: number;
}

export interface CopperCRMAccounts {
  copperCRMAccountsConnection: CopperCRMAccounts_copperCRMAccountsConnection | null;
}

export interface CopperCRMAccountsVariables {
  first?: number | null;
  after?: string | null;
  before?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteCopperCRMAccount
// ====================================================

export interface DeleteCopperCRMAccount {
  deleteCopperCRMAccount: boolean;
}

export interface DeleteCopperCRMAccountVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SalesforceLinks
// ====================================================

export interface SalesforceLinks_sandbox {
  __typename: "InitiateSalesforceOauth";
  loginUrl: string;
}

export interface SalesforceLinks_prod {
  __typename: "InitiateSalesforceOauth";
  loginUrl: string;
}

export interface SalesforceLinks {
  sandbox: SalesforceLinks_sandbox | null;
  prod: SalesforceLinks_prod | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SalesforceAccounts
// ====================================================

export interface SalesforceAccounts_salesforceAccountsConnection_edges_node {
  __typename: "SalesforceAccount";
  id: string;
  instanceURL: string;
  environment: SalesforceEnvironment;
  createdAt: Time;
}

export interface SalesforceAccounts_salesforceAccountsConnection_edges {
  __typename: "SalesforceAccountEdge";
  node: SalesforceAccounts_salesforceAccountsConnection_edges_node | null;
  cursor: string;
}

export interface SalesforceAccounts_salesforceAccountsConnection_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface SalesforceAccounts_salesforceAccountsConnection {
  __typename: "SalesforceAccountConnection";
  edges: (SalesforceAccounts_salesforceAccountsConnection_edges | null)[] | null;
  pageInfo: SalesforceAccounts_salesforceAccountsConnection_pageInfo;
  totalCount: number;
}

export interface SalesforceAccounts {
  salesforceAccountsConnection: SalesforceAccounts_salesforceAccountsConnection | null;
}

export interface SalesforceAccountsVariables {
  first?: number | null;
  after?: string | null;
  before?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteSalesforceAccount
// ====================================================

export interface DeleteSalesforceAccount {
  deleteSalesforceAccount: boolean;
}

export interface DeleteSalesforceAccountVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StripeLink
// ====================================================

export interface StripeLink_stripeConnectInfo {
  __typename: "StripeOauth";
  redirectUrl: string;
}

export interface StripeLink {
  stripeConnectInfo: StripeLink_stripeConnectInfo | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StripeAccounts
// ====================================================

export interface StripeAccounts_stripeAccountsConnection_edges_node {
  __typename: "StripeAccount";
  id: string;
  isAuthorized: boolean;
  livemode: boolean;
  name: string | null;
  createdAt: Time;
}

export interface StripeAccounts_stripeAccountsConnection_edges {
  __typename: "StripeAccountEdge";
  node: StripeAccounts_stripeAccountsConnection_edges_node | null;
  cursor: string;
}

export interface StripeAccounts_stripeAccountsConnection_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface StripeAccounts_stripeAccountsConnection {
  __typename: "StripeAccountConnection";
  edges: (StripeAccounts_stripeAccountsConnection_edges | null)[] | null;
  pageInfo: StripeAccounts_stripeAccountsConnection_pageInfo;
  totalCount: number;
}

export interface StripeAccounts {
  stripeAccountsConnection: StripeAccounts_stripeAccountsConnection | null;
}

export interface StripeAccountsVariables {
  first?: number | null;
  after?: string | null;
  before?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PatchStripeAccount
// ====================================================

export interface PatchStripeAccount_patchStripeAccount {
  __typename: "StripeAccount";
  id: string;
  name: string | null;
}

export interface PatchStripeAccount {
  patchStripeAccount: PatchStripeAccount_patchStripeAccount | null;
}

export interface PatchStripeAccountVariables {
  id: string;
  name?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteStripeAccount
// ====================================================

export interface DeleteStripeAccount {
  deleteStripeAccount: boolean;
}

export interface DeleteStripeAccountVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BillingSummary
// ====================================================

export interface BillingSummary_me_organization_subscriptionDetails {
  __typename: "SubscriptionDetails";
  state: SubscriptionState;
  nextBillingDate: Time | null;
}

export interface BillingSummary_me_organization {
  __typename: "Organization";
  id: string;
  name: string;
  billingEmailAddress: string;
  freeTrialEnd: Time | null;
  daysLeftInFreeTrial: number | null;
  /**
   * if not in a free trial, then this returns whether or not the current org subscription state is valid
   */
  hasValidSubscription: boolean;
  /**
   * this indicates whether or not the subscription for the organization is active, excluding free trials
   */
  hasActiveSubscription: boolean;
  subscriptionDetails: BillingSummary_me_organization_subscriptionDetails | null;
}

export interface BillingSummary_me {
  __typename: "User";
  id: string;
  organization: BillingSummary_me_organization;
}

export interface BillingSummary {
  me: BillingSummary_me | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CancelSubscription
// ====================================================

export interface CancelSubscription {
  cancelSubscription: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeBillingEmail
// ====================================================

export interface ChangeBillingEmail_patchOrganization {
  __typename: "Organization";
  id: string;
  billingEmailAddress: string;
}

export interface ChangeBillingEmail {
  patchOrganization: ChangeBillingEmail_patchOrganization | null;
}

export interface ChangeBillingEmailVariables {
  orgId: string;
  newEmail: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrgSubInfo
// ====================================================

export interface OrgSubInfo_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * this indicates whether or not the subscription for the organization is active, excluding free trials
   */
  hasActiveSubscription: boolean;
}

export interface OrgSubInfo_me {
  __typename: "User";
  id: string;
  organization: OrgSubInfo_me_organization;
}

export interface OrgSubInfo {
  me: OrgSubInfo_me | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCheckoutSession
// ====================================================

export interface CreateCheckoutSession_createCheckoutSession {
  __typename: "CheckoutSessionResult";
  checkoutSessionId: string;
  stripePublishableKey: string;
}

export interface CreateCheckoutSession {
  createCheckoutSession: CreateCheckoutSession_createCheckoutSession | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConfirmUserEmail
// ====================================================

export interface ConfirmUserEmail_confirmUserEmail {
  __typename: "User";
  id: string;
}

export interface ConfirmUserEmail {
  confirmUserEmail: ConfirmUserEmail_confirmUserEmail | null;
}

export interface ConfirmUserEmailVariables {
  code: UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: User
// ====================================================

export interface User_me_organization {
  __typename: "Organization";
  id: string;
  name: string;
}

export interface User_me {
  __typename: "User";
  id: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  organization: User_me_organization;
}

export interface User {
  me: User_me | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DashboardItems
// ====================================================

export interface DashboardItems_dashboardItems {
  __typename: "DashboardItem";
  header: string;
  body: string;
  link: string | null;
  linkText: string | null;
}

export interface DashboardItems {
  dashboardItems: DashboardItems_dashboardItems[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DebugInfo
// ====================================================

export interface DebugInfo_version {
  __typename: "VersionInfo";
  buildVersion: string;
  buildCommit: string;
}

export interface DebugInfo_features {
  __typename: "Feature";
  flag: FeatureFlag;
  isEnabled: boolean;
}

export interface DebugInfo_me_organization {
  __typename: "Organization";
  id: string;
  name: string;
}

export interface DebugInfo_me {
  __typename: "User";
  id: string;
  emailAddress: string;
  organization: DebugInfo_me_organization;
}

export interface DebugInfo {
  version: DebugInfo_version;
  features: DebugInfo_features[] | null;
  me: DebugInfo_me | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ForgotPassword
// ====================================================

export interface ForgotPassword {
  requestPasswordReset: boolean;
}

export interface ForgotPasswordVariables {
  emailAddress: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Login
// ====================================================

export interface Login_createAccessToken {
  __typename: "AccessToken";
  token: string;
  expiration: Time;
}

export interface Login {
  createAccessToken: Login_createAccessToken | null;
}

export interface LoginVariables {
  emailAddress: string;
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConnectSalesforceAccount
// ====================================================

export interface ConnectSalesforceAccount_completeSalesforceOauth {
  __typename: "SalesforceAccount";
  id: string;
}

export interface ConnectSalesforceAccount {
  completeSalesforceOauth: ConnectSalesforceAccount_completeSalesforceOauth | null;
}

export interface ConnectSalesforceAccountVariables {
  code: string;
  environment: SalesforceEnvironment;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CompleteStripeOauth
// ====================================================

export interface CompleteStripeOauth_completeStripeOauth {
  __typename: "StripeAccount";
  id: string;
}

export interface CompleteStripeOauth {
  completeStripeOauth: CompleteStripeOauth_completeStripeOauth | null;
}

export interface CompleteStripeOauthVariables {
  code: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeOrgName
// ====================================================

export interface ChangeOrgName_patchOrganization {
  __typename: "Organization";
  id: string;
  name: string;
}

export interface ChangeOrgName {
  patchOrganization: ChangeOrgName_patchOrganization | null;
}

export interface ChangeOrgNameVariables {
  orgId: string;
  newName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrgQuery
// ====================================================

export interface OrgQuery_me_organization_usersConnection {
  __typename: "UserConnection";
  totalCount: number;
}

export interface OrgQuery_me_organization {
  __typename: "Organization";
  id: string;
  name: string;
  createdAt: Time;
  /**
   * retrieve the users for this organization
   */
  usersConnection: OrgQuery_me_organization_usersConnection | null;
}

export interface OrgQuery_me {
  __typename: "User";
  id: string;
  organization: OrgQuery_me_organization;
}

export interface OrgQuery {
  me: OrgQuery_me | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResendActivationLink
// ====================================================

export interface ResendActivationLink {
  resendUserInvitation: boolean;
}

export interface ResendActivationLinkVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InviteUser
// ====================================================

export interface InviteUser {
  inviteUserToOrganization: boolean;
}

export interface InviteUserVariables {
  firstName: string;
  lastName: string;
  emailAddress: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrganizationUsers
// ====================================================

export interface OrganizationUsers_me_organization_usersConnection_edges_node {
  __typename: "User";
  id: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  verifiedEmailAt: Time | null;
}

export interface OrganizationUsers_me_organization_usersConnection_edges {
  __typename: "UserEdge";
  node: OrganizationUsers_me_organization_usersConnection_edges_node | null;
  cursor: string;
}

export interface OrganizationUsers_me_organization_usersConnection_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface OrganizationUsers_me_organization_usersConnection {
  __typename: "UserConnection";
  edges: (OrganizationUsers_me_organization_usersConnection_edges | null)[] | null;
  totalCount: number;
  pageInfo: OrganizationUsers_me_organization_usersConnection_pageInfo;
}

export interface OrganizationUsers_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * retrieve the users for this organization
   */
  usersConnection: OrganizationUsers_me_organization_usersConnection | null;
}

export interface OrganizationUsers_me {
  __typename: "User";
  id: string;
  organization: OrganizationUsers_me_organization;
}

export interface OrganizationUsers {
  me: OrganizationUsers_me | null;
}

export interface OrganizationUsersVariables {
  first?: number | null;
  after?: string | null;
  before?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PreRegistrationUser
// ====================================================

export interface PreRegistrationUser_preRegistrationUser {
  __typename: "PreRegistrationUser";
  emailAddress: string;
}

export interface PreRegistrationUser {
  preRegistrationUser: PreRegistrationUser_preRegistrationUser | null;
}

export interface PreRegistrationUserVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RegisterUser
// ====================================================

export interface RegisterUser {
  registerUser: boolean;
}

export interface RegisterUserVariables {
  emailAddress: string;
  password: string;
  firstName: string;
  lastName: string;
  preRegistrationUserId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResetPassword
// ====================================================

export interface ResetPassword {
  finishPasswordReset: boolean;
}

export interface ResetPasswordVariables {
  code: UUID;
  newPassword: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangePassword
// ====================================================

export interface ChangePassword_patchUser {
  __typename: "User";
  id: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
}

export interface ChangePassword {
  patchUser: ChangePassword_patchUser | null;
}

export interface ChangePasswordVariables {
  id: string;
  password?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PatchUser
// ====================================================

export interface PatchUser_patchUser {
  __typename: "User";
  id: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
}

export interface PatchUser {
  patchUser: PatchUser_patchUser | null;
}

export interface PatchUserVariables {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  password?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserLogins
// ====================================================

export interface UserLogins_userLoginsConnection_edges_node {
  __typename: "UserLogin";
  id: string;
  time: Time;
  ipAddress: string;
}

export interface UserLogins_userLoginsConnection_edges {
  __typename: "UserLoginEdge";
  node: UserLogins_userLoginsConnection_edges_node | null;
}

export interface UserLogins_userLoginsConnection_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface UserLogins_userLoginsConnection {
  __typename: "UserLoginConnection";
  edges: (UserLogins_userLoginsConnection_edges | null)[] | null;
  totalCount: number;
  pageInfo: UserLogins_userLoginsConnection_pageInfo;
}

export interface UserLogins {
  userLoginsConnection: UserLogins_userLoginsConnection | null;
}

export interface UserLoginsVariables {
  first?: number | null;
  after?: string | null;
  before?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CalendlyAccountsBulk
// ====================================================

export interface CalendlyAccountsBulk_calendlyAccountsConnection_edges_node {
  __typename: "CalendlyAccount";
  id: string;
  name: string;
  createdAt: Time;
  updatedAt: Time | null;
}

export interface CalendlyAccountsBulk_calendlyAccountsConnection_edges {
  __typename: "CalendlyAccountEdge";
  node: CalendlyAccountsBulk_calendlyAccountsConnection_edges_node | null;
}

export interface CalendlyAccountsBulk_calendlyAccountsConnection {
  __typename: "CalendlyAccountConnection";
  edges: (CalendlyAccountsBulk_calendlyAccountsConnection_edges | null)[] | null;
}

export interface CalendlyAccountsBulk {
  calendlyAccountsConnection: CalendlyAccountsBulk_calendlyAccountsConnection | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CalendlyWebhook
// ====================================================

export interface CalendlyWebhook_calendlyWebhook_account {
  __typename: "CalendlyAccount";
  id: string;
  name: string;
}

export interface CalendlyWebhook_calendlyWebhook_tags {
  __typename: "Tag";
  key: string;
  value: string;
}

export interface CalendlyWebhook_calendlyWebhook {
  __typename: "CalendlyWebhook";
  id: string;
  createdAt: Time;
  updatedAt: Time | null;
  url: string;
  events: CalendlyEvent[];
  account: CalendlyWebhook_calendlyWebhook_account;
  tags: CalendlyWebhook_calendlyWebhook_tags[];
}

export interface CalendlyWebhook {
  calendlyWebhook: CalendlyWebhook_calendlyWebhook | null;
}

export interface CalendlyWebhookVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCalendlyWebhook
// ====================================================

export interface CreateCalendlyWebhook_createCalendlyWebhook_tags {
  __typename: "Tag";
  key: string;
  value: string;
}

export interface CreateCalendlyWebhook_createCalendlyWebhook_account {
  __typename: "CalendlyAccount";
  id: string;
  name: string;
}

export interface CreateCalendlyWebhook_createCalendlyWebhook {
  __typename: "CalendlyWebhook";
  id: string;
  createdAt: Time;
  updatedAt: Time | null;
  tags: CreateCalendlyWebhook_createCalendlyWebhook_tags[];
  account: CreateCalendlyWebhook_createCalendlyWebhook_account;
  url: string;
  events: CalendlyEvent[];
}

export interface CreateCalendlyWebhook {
  createCalendlyWebhook: CreateCalendlyWebhook_createCalendlyWebhook | null;
}

export interface CreateCalendlyWebhookVariables {
  accountId: string;
  events: CalendlyEvent[];
  url: string;
  tags?: TagInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CopperCRMAccountsBulk
// ====================================================

export interface CopperCRMAccountsBulk_copperCRMAccountsConnection_edges_node {
  __typename: "CopperCRMAccount";
  id: string;
  name: string;
}

export interface CopperCRMAccountsBulk_copperCRMAccountsConnection_edges {
  __typename: "CopperCRMAccountEdge";
  node: CopperCRMAccountsBulk_copperCRMAccountsConnection_edges_node | null;
}

export interface CopperCRMAccountsBulk_copperCRMAccountsConnection {
  __typename: "CopperCRMAccountConnection";
  edges: (CopperCRMAccountsBulk_copperCRMAccountsConnection_edges | null)[] | null;
}

export interface CopperCRMAccountsBulk {
  copperCRMAccountsConnection: CopperCRMAccountsBulk_copperCRMAccountsConnection | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCopperCRMWebhook
// ====================================================

export interface CreateCopperCRMWebhook_createCopperCRMWebhook {
  __typename: "CopperCRMWebhook";
  id: string;
}

export interface CreateCopperCRMWebhook {
  createCopperCRMWebhook: CreateCopperCRMWebhook_createCopperCRMWebhook | null;
}

export interface CreateCopperCRMWebhookVariables {
  accountId: string;
  event: CopperCRMEvent;
  webhookType: CopperCRMWebhookType;
  url: string;
  secret: string;
  tags?: TagInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CopperCRMWebhook
// ====================================================

export interface CopperCRMWebhook_copperCRMWebhook_account {
  __typename: "CopperCRMAccount";
  id: string;
  name: string;
}

export interface CopperCRMWebhook_copperCRMWebhook_tags {
  __typename: "Tag";
  key: string;
  value: string;
}

export interface CopperCRMWebhook_copperCRMWebhook {
  __typename: "CopperCRMWebhook";
  id: string;
  createdAt: Time;
  updatedAt: Time | null;
  url: string;
  event: CopperCRMEvent;
  secret: string;
  webhookType: CopperCRMWebhookType;
  account: CopperCRMWebhook_copperCRMWebhook_account;
  tags: CopperCRMWebhook_copperCRMWebhook_tags[];
}

export interface CopperCRMWebhook {
  copperCRMWebhook: CopperCRMWebhook_copperCRMWebhook | null;
}

export interface CopperCRMWebhookVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SalesforceAccountsBulk
// ====================================================

export interface SalesforceAccountsBulk_salesforceAccountsConnection_edges_node_objects {
  __typename: "SalesforceObject";
  name: string;
}

export interface SalesforceAccountsBulk_salesforceAccountsConnection_edges_node {
  __typename: "SalesforceAccount";
  id: string;
  instanceURL: string;
  environment: SalesforceEnvironment;
  objects: SalesforceAccountsBulk_salesforceAccountsConnection_edges_node_objects[];
}

export interface SalesforceAccountsBulk_salesforceAccountsConnection_edges {
  __typename: "SalesforceAccountEdge";
  node: SalesforceAccountsBulk_salesforceAccountsConnection_edges_node | null;
}

export interface SalesforceAccountsBulk_salesforceAccountsConnection {
  __typename: "SalesforceAccountConnection";
  edges: (SalesforceAccountsBulk_salesforceAccountsConnection_edges | null)[] | null;
}

export interface SalesforceAccountsBulk {
  salesforceAccountsConnection: SalesforceAccountsBulk_salesforceAccountsConnection | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SalesforceWebhook
// ====================================================

export interface SalesforceWebhook_salesforceWebhook_object {
  __typename: "SalesforceObject";
  name: string;
}

export interface SalesforceWebhook_salesforceWebhook_tags {
  __typename: "Tag";
  key: string;
  value: string;
}

export interface SalesforceWebhook_salesforceWebhook {
  __typename: "SalesforceWebhook";
  id: string;
  name: string;
  createdAt: Time;
  updatedAt: Time | null;
  object: SalesforceWebhook_salesforceWebhook_object;
  url: string;
  events: SalesforceEvent[];
  hmacHeaderName: string;
  hmacKey: string;
  tags: SalesforceWebhook_salesforceWebhook_tags[];
}

export interface SalesforceWebhook {
  salesforceWebhook: SalesforceWebhook_salesforceWebhook | null;
}

export interface SalesforceWebhookVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateSalesforceWebhook
// ====================================================

export interface CreateSalesforceWebhook_createSalesforceWebhook_object {
  __typename: "SalesforceObject";
  name: string;
}

export interface CreateSalesforceWebhook_createSalesforceWebhook {
  __typename: "SalesforceWebhook";
  id: string;
  name: string;
  createdAt: Time;
  updatedAt: Time | null;
  object: CreateSalesforceWebhook_createSalesforceWebhook_object;
  url: string;
  events: SalesforceEvent[];
  hmacHeaderName: string;
  hmacKey: string;
}

export interface CreateSalesforceWebhook {
  createSalesforceWebhook: CreateSalesforceWebhook_createSalesforceWebhook | null;
}

export interface CreateSalesforceWebhookVariables {
  accountId: string;
  events: SalesforceEvent[];
  objectName: string;
  url: string;
  hmacKey: string;
  hmacHeader: string;
  name: string;
  tags?: TagInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StripeAccountsBulk
// ====================================================

export interface StripeAccountsBulk_stripeAccountsConnection_edges_node {
  __typename: "StripeAccount";
  id: string;
  name: string | null;
  livemode: boolean;
  createdAt: Time;
  stripeUserId: string;
}

export interface StripeAccountsBulk_stripeAccountsConnection_edges {
  __typename: "StripeAccountEdge";
  node: StripeAccountsBulk_stripeAccountsConnection_edges_node | null;
}

export interface StripeAccountsBulk_stripeAccountsConnection {
  __typename: "StripeAccountConnection";
  edges: (StripeAccountsBulk_stripeAccountsConnection_edges | null)[] | null;
}

export interface StripeAccountsBulk {
  stripeAccountsConnection: StripeAccountsBulk_stripeAccountsConnection | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StripeWebhook
// ====================================================

export interface StripeWebhook_stripeWebhook_account {
  __typename: "StripeAccount";
  id: string;
  name: string | null;
  stripeUserId: string;
}

export interface StripeWebhook_stripeWebhook_tags {
  __typename: "Tag";
  key: string;
  value: string;
}

export interface StripeWebhook_stripeWebhook {
  __typename: "StripeWebhook";
  id: string;
  name: string;
  createdAt: Time;
  updatedAt: Time | null;
  account: StripeWebhook_stripeWebhook_account;
  secret: string;
  url: string;
  events: StripeWebhookEvent[];
  tags: StripeWebhook_stripeWebhook_tags[];
}

export interface StripeWebhook {
  stripeWebhook: StripeWebhook_stripeWebhook | null;
}

export interface StripeWebhookVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateStripeWebhook
// ====================================================

export interface CreateStripeWebhook_createStripeWebhook {
  __typename: "StripeWebhook";
  id: string;
  name: string;
  createdAt: Time;
  updatedAt: Time | null;
  url: string;
  events: StripeWebhookEvent[];
  secret: string;
}

export interface CreateStripeWebhook {
  createStripeWebhook: CreateStripeWebhook_createStripeWebhook | null;
}

export interface CreateStripeWebhookVariables {
  accountId: string;
  events: StripeWebhookEvent[];
  name: string;
  url: string;
  tags?: TagInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: WebhookFilters
// ====================================================

export interface WebhookFilters_webhookTagKeysConnection_edges_node_values {
  __typename: "WebhookTagValue";
  id: string;
  value: string;
}

export interface WebhookFilters_webhookTagKeysConnection_edges_node {
  __typename: "WebhookTagKey";
  id: string;
  name: string;
  values: WebhookFilters_webhookTagKeysConnection_edges_node_values[] | null;
}

export interface WebhookFilters_webhookTagKeysConnection_edges {
  __typename: "WebhookTagKeyEdge";
  node: WebhookFilters_webhookTagKeysConnection_edges_node | null;
  cursor: string;
}

export interface WebhookFilters_webhookTagKeysConnection_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface WebhookFilters_webhookTagKeysConnection {
  __typename: "WebhookTagKeyConnection";
  edges: (WebhookFilters_webhookTagKeysConnection_edges | null)[] | null;
  totalCount: number;
  pageInfo: WebhookFilters_webhookTagKeysConnection_pageInfo;
}

export interface WebhookFilters {
  webhookTagKeysConnection: WebhookFilters_webhookTagKeysConnection | null;
}

export interface WebhookFiltersVariables {
  first?: number | null;
  after?: string | null;
  before?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllWebhooks
// ====================================================

export interface AllWebhooks_webhooksConnection_edges_node_tags {
  __typename: "Tag";
  key: string;
  value: string;
}

export interface AllWebhooks_webhooksConnection_edges_node_account {
  __typename: "WebhookAccount";
  id: string;
  category: WebhookCategory;
  createdAt: Time;
}

export interface AllWebhooks_webhooksConnection_edges_node {
  __typename: "Webhook";
  id: string;
  category: WebhookCategory;
  webhookId: string;
  createdAt: Time;
  tags: AllWebhooks_webhooksConnection_edges_node_tags[];
  account: AllWebhooks_webhooksConnection_edges_node_account;
}

export interface AllWebhooks_webhooksConnection_edges {
  __typename: "WebhookEdge";
  node: AllWebhooks_webhooksConnection_edges_node | null;
  cursor: string;
}

export interface AllWebhooks_webhooksConnection_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface AllWebhooks_webhooksConnection {
  __typename: "WebhookConnection";
  edges: (AllWebhooks_webhooksConnection_edges | null)[] | null;
  pageInfo: AllWebhooks_webhooksConnection_pageInfo;
  totalCount: number;
}

export interface AllWebhooks {
  webhooksConnection: AllWebhooks_webhooksConnection | null;
}

export interface AllWebhooksVariables {
  first?: number | null;
  after?: string | null;
  before?: string | null;
  filters?: WebhookFilter[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteMaterializedWebhook
// ====================================================

export interface DeleteMaterializedWebhook {
  /**
   * given a materialized webhook id, the concrete webhook is looked up and also
   * deleted, this is a shortcut over using things like deleteStripeWebhook
   */
  deleteMaterializedWebhook: boolean;
}

export interface DeleteMaterializedWebhookVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveUser
// ====================================================

export interface RemoveUser {
  removeUserFromOrganization: boolean;
}

export interface RemoveUserVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CalendlyEvent {
  INVITEE__CANCELED = "INVITEE__CANCELED",
  INVITEE__CREATED = "INVITEE__CREATED",
}

export enum CopperCRMEvent {
  DELETE = "DELETE",
  NEW = "NEW",
  UPDATE = "UPDATE",
}

export enum CopperCRMWebhookType {
  ACTIVITY_LOG = "ACTIVITY_LOG",
  COMPANY = "COMPANY",
  LEAD = "LEAD",
  OPPORTUNITY = "OPPORTUNITY",
  PERSON = "PERSON",
  PROJECT = "PROJECT",
  TASK = "TASK",
}

export enum FeatureFlag {
  PAYMENTS = "PAYMENTS",
}

export enum SalesforceEnvironment {
  PROD = "PROD",
  SANDBOX = "SANDBOX",
}

export enum SalesforceEvent {
  AFTER_DELETE = "AFTER_DELETE",
  AFTER_INSERT = "AFTER_INSERT",
  AFTER_UNDELETE = "AFTER_UNDELETE",
  AFTER_UPDATE = "AFTER_UPDATE",
  BEFORE_DELETE = "BEFORE_DELETE",
  BEFORE_INSERT = "BEFORE_INSERT",
  BEFORE_UPDATE = "BEFORE_UPDATE",
}

export enum StripeWebhookEvent {
  ACCOUNT__APPLICATION__AUTHORIZED = "ACCOUNT__APPLICATION__AUTHORIZED",
  ACCOUNT__APPLICATION__DEAUTHORIZED = "ACCOUNT__APPLICATION__DEAUTHORIZED",
  ACCOUNT__EXTERNAL_ACCOUNT__CREATED = "ACCOUNT__EXTERNAL_ACCOUNT__CREATED",
  ACCOUNT__EXTERNAL_ACCOUNT__DELETED = "ACCOUNT__EXTERNAL_ACCOUNT__DELETED",
  ACCOUNT__EXTERNAL_ACCOUNT__UPDATED = "ACCOUNT__EXTERNAL_ACCOUNT__UPDATED",
  ACCOUNT__UPDATED = "ACCOUNT__UPDATED",
  ALL_EVENTS = "ALL_EVENTS",
  APPLICATION_FEE__CREATED = "APPLICATION_FEE__CREATED",
  APPLICATION_FEE__REFUNDED = "APPLICATION_FEE__REFUNDED",
  APPLICATION_FEE__REFUND__UPDATED = "APPLICATION_FEE__REFUND__UPDATED",
  BALANCE__AVAILABLE = "BALANCE__AVAILABLE",
  CAPABILITY__UPDATED = "CAPABILITY__UPDATED",
  CHARGE__CAPTURED = "CHARGE__CAPTURED",
  CHARGE__DISPUTE__CLOSED = "CHARGE__DISPUTE__CLOSED",
  CHARGE__DISPUTE__CREATED = "CHARGE__DISPUTE__CREATED",
  CHARGE__DISPUTE__FUNDS_REINSTATED = "CHARGE__DISPUTE__FUNDS_REINSTATED",
  CHARGE__DISPUTE__FUNDS_WITHDRAWN = "CHARGE__DISPUTE__FUNDS_WITHDRAWN",
  CHARGE__DISPUTE__UPDATED = "CHARGE__DISPUTE__UPDATED",
  CHARGE__EXPIRED = "CHARGE__EXPIRED",
  CHARGE__FAILED = "CHARGE__FAILED",
  CHARGE__PENDING = "CHARGE__PENDING",
  CHARGE__REFUNDED = "CHARGE__REFUNDED",
  CHARGE__REFUND__UPDATED = "CHARGE__REFUND__UPDATED",
  CHARGE__SUCCEEDED = "CHARGE__SUCCEEDED",
  CHARGE__UPDATED = "CHARGE__UPDATED",
  CHECKOUT__SESSION__COMPLETED = "CHECKOUT__SESSION__COMPLETED",
  COUPON__CREATED = "COUPON__CREATED",
  COUPON__DELETED = "COUPON__DELETED",
  COUPON__UPDATED = "COUPON__UPDATED",
  CREDIT_NOTE__CREATED = "CREDIT_NOTE__CREATED",
  CREDIT_NOTE__UPDATED = "CREDIT_NOTE__UPDATED",
  CREDIT_NOTE__VOIDED = "CREDIT_NOTE__VOIDED",
  CUSTOMER__CREATED = "CUSTOMER__CREATED",
  CUSTOMER__DELETED = "CUSTOMER__DELETED",
  CUSTOMER__DISCOUNT__CREATED = "CUSTOMER__DISCOUNT__CREATED",
  CUSTOMER__DISCOUNT__DELETED = "CUSTOMER__DISCOUNT__DELETED",
  CUSTOMER__DISCOUNT__UPDATED = "CUSTOMER__DISCOUNT__UPDATED",
  CUSTOMER__SOURCE__CREATED = "CUSTOMER__SOURCE__CREATED",
  CUSTOMER__SOURCE__DELETED = "CUSTOMER__SOURCE__DELETED",
  CUSTOMER__SOURCE__EXPIRING = "CUSTOMER__SOURCE__EXPIRING",
  CUSTOMER__SOURCE__UPDATED = "CUSTOMER__SOURCE__UPDATED",
  CUSTOMER__SUBSCRIPTION__CREATED = "CUSTOMER__SUBSCRIPTION__CREATED",
  CUSTOMER__SUBSCRIPTION__DELETED = "CUSTOMER__SUBSCRIPTION__DELETED",
  CUSTOMER__SUBSCRIPTION__PENDING_UPDATE_APPLIED = "CUSTOMER__SUBSCRIPTION__PENDING_UPDATE_APPLIED",
  CUSTOMER__SUBSCRIPTION__PENDING_UPDATE_EXPIRED = "CUSTOMER__SUBSCRIPTION__PENDING_UPDATE_EXPIRED",
  CUSTOMER__SUBSCRIPTION__TRIAL_WILL_END = "CUSTOMER__SUBSCRIPTION__TRIAL_WILL_END",
  CUSTOMER__SUBSCRIPTION__UPDATED = "CUSTOMER__SUBSCRIPTION__UPDATED",
  CUSTOMER__TAX_ID__CREATED = "CUSTOMER__TAX_ID__CREATED",
  CUSTOMER__TAX_ID__DELETED = "CUSTOMER__TAX_ID__DELETED",
  CUSTOMER__TAX_ID__UPDATED = "CUSTOMER__TAX_ID__UPDATED",
  CUSTOMER__UPDATED = "CUSTOMER__UPDATED",
  FILE__CREATED = "FILE__CREATED",
  INVOICEITEM__CREATED = "INVOICEITEM__CREATED",
  INVOICEITEM__DELETED = "INVOICEITEM__DELETED",
  INVOICEITEM__UPDATED = "INVOICEITEM__UPDATED",
  INVOICE__CREATED = "INVOICE__CREATED",
  INVOICE__DELETED = "INVOICE__DELETED",
  INVOICE__FINALIZED = "INVOICE__FINALIZED",
  INVOICE__MARKED_UNCOLLECTIBLE = "INVOICE__MARKED_UNCOLLECTIBLE",
  INVOICE__PAYMENT_ACTION_REQUIRED = "INVOICE__PAYMENT_ACTION_REQUIRED",
  INVOICE__PAYMENT_FAILED = "INVOICE__PAYMENT_FAILED",
  INVOICE__PAYMENT_SUCCEEDED = "INVOICE__PAYMENT_SUCCEEDED",
  INVOICE__SENT = "INVOICE__SENT",
  INVOICE__UPCOMING = "INVOICE__UPCOMING",
  INVOICE__UPDATED = "INVOICE__UPDATED",
  INVOICE__VOIDED = "INVOICE__VOIDED",
  ISSUING_AUTHORIZATION__CREATED = "ISSUING_AUTHORIZATION__CREATED",
  ISSUING_AUTHORIZATION__REQUEST = "ISSUING_AUTHORIZATION__REQUEST",
  ISSUING_AUTHORIZATION__UPDATED = "ISSUING_AUTHORIZATION__UPDATED",
  ISSUING_CARDHOLDER__CREATED = "ISSUING_CARDHOLDER__CREATED",
  ISSUING_CARDHOLDER__UPDATED = "ISSUING_CARDHOLDER__UPDATED",
  ISSUING_CARD__CREATED = "ISSUING_CARD__CREATED",
  ISSUING_CARD__UPDATED = "ISSUING_CARD__UPDATED",
  ISSUING_DISPUTE__CREATED = "ISSUING_DISPUTE__CREATED",
  ISSUING_DISPUTE__UPDATED = "ISSUING_DISPUTE__UPDATED",
  ISSUING_SETTLEMENT__CREATED = "ISSUING_SETTLEMENT__CREATED",
  ISSUING_SETTLEMENT__UPDATED = "ISSUING_SETTLEMENT__UPDATED",
  ISSUING_TRANSACTION__CREATED = "ISSUING_TRANSACTION__CREATED",
  ISSUING_TRANSACTION__UPDATED = "ISSUING_TRANSACTION__UPDATED",
  MANDATE__UPDATED = "MANDATE__UPDATED",
  ORDER_RETURN__CREATED = "ORDER_RETURN__CREATED",
  ORDER__CREATED = "ORDER__CREATED",
  ORDER__PAYMENT_FAILED = "ORDER__PAYMENT_FAILED",
  ORDER__PAYMENT_SUCCEEDED = "ORDER__PAYMENT_SUCCEEDED",
  ORDER__UPDATED = "ORDER__UPDATED",
  PAYMENT_INTENT__AMOUNT_CAPTURABLE_UPDATED = "PAYMENT_INTENT__AMOUNT_CAPTURABLE_UPDATED",
  PAYMENT_INTENT__CANCELED = "PAYMENT_INTENT__CANCELED",
  PAYMENT_INTENT__CREATED = "PAYMENT_INTENT__CREATED",
  PAYMENT_INTENT__PAYMENT_FAILED = "PAYMENT_INTENT__PAYMENT_FAILED",
  PAYMENT_INTENT__SUCCEEDED = "PAYMENT_INTENT__SUCCEEDED",
  PAYMENT_METHOD__ATTACHED = "PAYMENT_METHOD__ATTACHED",
  PAYMENT_METHOD__CARD_AUTOMATICALLY_UPDATED = "PAYMENT_METHOD__CARD_AUTOMATICALLY_UPDATED",
  PAYMENT_METHOD__DETACHED = "PAYMENT_METHOD__DETACHED",
  PAYMENT_METHOD__UPDATED = "PAYMENT_METHOD__UPDATED",
  PAYOUT__CANCELED = "PAYOUT__CANCELED",
  PAYOUT__CREATED = "PAYOUT__CREATED",
  PAYOUT__FAILED = "PAYOUT__FAILED",
  PAYOUT__PAID = "PAYOUT__PAID",
  PAYOUT__UPDATED = "PAYOUT__UPDATED",
  PERSON__CREATED = "PERSON__CREATED",
  PERSON__DELETED = "PERSON__DELETED",
  PERSON__UPDATED = "PERSON__UPDATED",
  PLAN__CREATED = "PLAN__CREATED",
  PLAN__DELETED = "PLAN__DELETED",
  PLAN__UPDATED = "PLAN__UPDATED",
  PRODUCT__CREATED = "PRODUCT__CREATED",
  PRODUCT__DELETED = "PRODUCT__DELETED",
  PRODUCT__UPDATED = "PRODUCT__UPDATED",
  RADAR__EARLY_FRAUD_WARNING__CREATED = "RADAR__EARLY_FRAUD_WARNING__CREATED",
  RADAR__EARLY_FRAUD_WARNING__UPDATED = "RADAR__EARLY_FRAUD_WARNING__UPDATED",
  RECIPIENT__CREATED = "RECIPIENT__CREATED",
  RECIPIENT__DELETED = "RECIPIENT__DELETED",
  RECIPIENT__UPDATED = "RECIPIENT__UPDATED",
  REPORTING__REPORT_RUN__FAILED = "REPORTING__REPORT_RUN__FAILED",
  REPORTING__REPORT_RUN__SUCCEEDED = "REPORTING__REPORT_RUN__SUCCEEDED",
  REPORTING__REPORT_TYPE__UPDATED = "REPORTING__REPORT_TYPE__UPDATED",
  REVIEW__CLOSED = "REVIEW__CLOSED",
  REVIEW__OPENED = "REVIEW__OPENED",
  SETUP_INTENT__CANCELED = "SETUP_INTENT__CANCELED",
  SETUP_INTENT__CREATED = "SETUP_INTENT__CREATED",
  SETUP_INTENT__SETUP_FAILED = "SETUP_INTENT__SETUP_FAILED",
  SETUP_INTENT__SUCCEEDED = "SETUP_INTENT__SUCCEEDED",
  SIGMA__SCHEDULED_QUERY_RUN__CREATED = "SIGMA__SCHEDULED_QUERY_RUN__CREATED",
  SKU__CREATED = "SKU__CREATED",
  SKU__DELETED = "SKU__DELETED",
  SKU__UPDATED = "SKU__UPDATED",
  SOURCE__CANCELED = "SOURCE__CANCELED",
  SOURCE__CHARGEABLE = "SOURCE__CHARGEABLE",
  SOURCE__FAILED = "SOURCE__FAILED",
  SOURCE__MANDATE_NOTIFICATION = "SOURCE__MANDATE_NOTIFICATION",
  SOURCE__REFUND_ATTRIBUTES_REQUIRED = "SOURCE__REFUND_ATTRIBUTES_REQUIRED",
  SOURCE__TRANSACTION__CREATED = "SOURCE__TRANSACTION__CREATED",
  SOURCE__TRANSACTION__UPDATED = "SOURCE__TRANSACTION__UPDATED",
  SUBSCRIPTION_SCHEDULE__ABORTED = "SUBSCRIPTION_SCHEDULE__ABORTED",
  SUBSCRIPTION_SCHEDULE__CANCELED = "SUBSCRIPTION_SCHEDULE__CANCELED",
  SUBSCRIPTION_SCHEDULE__COMPLETED = "SUBSCRIPTION_SCHEDULE__COMPLETED",
  SUBSCRIPTION_SCHEDULE__CREATED = "SUBSCRIPTION_SCHEDULE__CREATED",
  SUBSCRIPTION_SCHEDULE__EXPIRING = "SUBSCRIPTION_SCHEDULE__EXPIRING",
  SUBSCRIPTION_SCHEDULE__RELEASED = "SUBSCRIPTION_SCHEDULE__RELEASED",
  SUBSCRIPTION_SCHEDULE__UPDATED = "SUBSCRIPTION_SCHEDULE__UPDATED",
  TAX_RATE__CREATED = "TAX_RATE__CREATED",
  TAX_RATE__UPDATED = "TAX_RATE__UPDATED",
  TOPUP__CANCELED = "TOPUP__CANCELED",
  TOPUP__CREATED = "TOPUP__CREATED",
  TOPUP__FAILED = "TOPUP__FAILED",
  TOPUP__REVERSED = "TOPUP__REVERSED",
  TOPUP__SUCCEEDED = "TOPUP__SUCCEEDED",
  TRANSFER__CREATED = "TRANSFER__CREATED",
  TRANSFER__FAILED = "TRANSFER__FAILED",
  TRANSFER__PAID = "TRANSFER__PAID",
  TRANSFER__REVERSED = "TRANSFER__REVERSED",
  TRANSFER__UPDATED = "TRANSFER__UPDATED",
}

export enum SubscriptionState {
  ACTIVE = "ACTIVE",
  CANCELED = "CANCELED",
  INCOMPLETE = "INCOMPLETE",
  INCOMPLETE_EXPIRED = "INCOMPLETE_EXPIRED",
  PAST_DUE = "PAST_DUE",
  TRIALING = "TRIALING",
  UNKNOWN = "UNKNOWN",
  UNPAID = "UNPAID",
}

export enum WebhookCategory {
  CALENDLY = "CALENDLY",
  COPPER_CRM = "COPPER_CRM",
  SALESFORCE = "SALESFORCE",
  STRIPE = "STRIPE",
}

export interface TagInput {
  key: string;
  value: string;
}

export interface WebhookFilter {
  name: string;
  includeValues: string[];
}

//==============================================================
// END Enums and Input Objects
//==============================================================
