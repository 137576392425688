import React from "react";
import { SalesforceAccountNode } from "../../../graphql/salesforce";
import gql from "graphql-tag";
import { ApolloError } from "apollo-boost";
import { Query } from "react-apollo";
import { Connection } from "../../../graphql/relay";
import Form from "react-bootstrap/Form";
import GenericError from "../../../components/GenericError";
import LoadingDots from "../../../components/LoadingDots";
import Alert from "react-bootstrap/Alert";
import { LinkContainer } from "react-router-bootstrap";
import { RBRef, RegisterFn } from "../../../components/form/types";

interface Props {
  register: RegisterFn;
  watch: (
    fieldNames?: string | string[] | undefined,
    defaultValue?: string
  ) => any;
  accounts: SalesforceAccountNode[];
  loading: boolean;
  error?: ApolloError;
}

const SalesforceAccountSelect: React.FC<Props> = props => {
  const { register, accounts, loading, error, watch } = props;
  const watchAccountId = watch("accountId");

  const accountLookup: Map<string, SalesforceAccountNode> = new Map();
  accounts.forEach(account => {
    accountLookup.set(account.id, account);
  });

  return (
    <>
      {!error && !loading && accounts.length === 0 && (
        <Alert variant="danger">
          <span className="mr-2">
            A Salesforce account must be connected first.
          </span>
          <LinkContainer to="/accounts/salesforce">
            <Alert.Link>Connect now &rsaquo;</Alert.Link>
          </LinkContainer>
        </Alert>
      )}

      <GenericError error={error} />

      <Form.Group>
        <Form.Label htmlFor="accountId">Account</Form.Label>
        <Form.Control
          as="select"
          name="accountId"
          disabled={loading}
          ref={register({ required: true }) as RBRef}
        >
          <option value="" />
          {accounts.map(account => (
            <option key={account.id} value={account.id}>
              {`${account.environment} - ${account.instanceURL}`}
            </option>
          ))}
        </Form.Control>
        {loading && (
          <Form.Text>
            Loading
            <LoadingDots />
          </Form.Text>
        )}
      </Form.Group>
      <Form.Group>
        <Form.Label htmlFor="objectName">SObject</Form.Label>
        <Form.Control
          as="select"
          name="objectName"
          disabled={loading || !watchAccountId}
          ref={register({ required: true }) as RBRef}
        >
          {watchAccountId &&
            accountLookup.get(watchAccountId)!.objects.map(o => (
              <option key={o.name} value={o.name}>
                {o.name}
              </option>
            ))}
        </Form.Control>
        {!loading && !watchAccountId && (
          <Form.Text className="text-warning">Select an account.</Form.Text>
        )}
      </Form.Group>
    </>
  );
};

const SALESFORCE_ACCOUNTS = gql`
  query SalesforceAccountsBulk {
    salesforceAccountsConnection(first: 1000) {
      edges {
        node {
          id
          instanceURL
          environment
          objects {
            name
          }
        }
      }
    }
  }
`;

interface QueryProps {
  register: RegisterFn;
  watch: (
    fieldNames?: string | string[] | undefined,
    defaultValue?: string
  ) => any;
}

interface Response {
  salesforceAccountsConnection?: Connection<SalesforceAccountNode>;
}

const WithQuery: React.FC<QueryProps> = ({ register, watch }) => (
  <Query<Response> query={SALESFORCE_ACCOUNTS}>
    {({ data, loading, error }) => (
      <SalesforceAccountSelect
        watch={watch}
        register={register}
        loading={loading}
        error={error}
        accounts={
          data &&
          data.salesforceAccountsConnection &&
          data.salesforceAccountsConnection.edges
            ? data.salesforceAccountsConnection.edges.map(i => i.node!)
            : []
        }
      />
    )}
  </Query>
);

export default WithQuery;
