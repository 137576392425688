import React from "react";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { LinkContainer } from "react-router-bootstrap";

interface Props {
  connectAccountLink: string;
  createWebhookLink: string;
}

const AccountConnectLinks: React.FC<Props> = ({
  connectAccountLink,
  createWebhookLink
}) => {
  return (
    <div>
      <LinkContainer to={connectAccountLink}>
        <Button className="d-inline-block shadow" variant="primary" size="sm">
          <FontAwesomeIcon icon={faPlus} fixedWidth={true} className="mr-1" />
          Add new account
        </Button>
      </LinkContainer>
      <LinkContainer to={createWebhookLink}>
        <Button
          className="d-inline-block shadow ml-2"
          variant="secondary"
          size="sm"
        >
          Create webhook
        </Button>
      </LinkContainer>
    </div>
  );
};

export default AccountConnectLinks;
