import { Node } from "./relay";
import { WebhookTag } from "./webhooks";

export interface CalendlyAccountNode extends Node {
  createdAt: string;
  updatedAt: string;
  name: string;
}

export enum CalendlyWebhookEvent {
  INVITEE__CREATED = "INVITEE__CREATED",
  INVITEE__CANCELED = "INVITEE__CANCELED"
}

export interface CalendlyWebhookNode extends Node {
  createdAt: string;
  updatedAt: string;
  tags: WebhookTag[];
  account: {
    id: string;
    name: string;
  };
  url: string;
  events: CalendlyWebhookEvent[];
}

export const webhookEventToCalendlyName = (event: string): string => {
  return event.replace(/__/g, ".").toLowerCase();
};
