import React from "react";
import useBodyClass from "../utils/useBodyClass";
import Container from "react-bootstrap/Container";
import Helmet from "react-helmet";
import HeaderLoggedOut from "../components/HeaderLoggedOut";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import FormError, { HasError } from "../components/FormError";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { ApolloError, gql } from "apollo-boost";
import useForm from "react-hook-form";
import StatusEventsBanner from "../components/StatusEvents";
import { Mutation } from "react-apollo";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import { RBRef } from "../components/form/types";
import AccountCreationLegalease from "../components/form/AccountCreationLegalease";

interface Props {
  hasCode: boolean;
  acceptInvitation: (newPassword: string) => Promise<any>;
  data?: Response;
  error?: ApolloError;
  loading: boolean;
}

const AcceptInvitationPage: React.FC<Props> = ({
  acceptInvitation,
  data,
  hasCode,
  error,
  loading
}) => {
  const { handleSubmit, register } = useForm();
  useBodyClass("bg-gradient-primary");

  const onSubmit = (data: Record<string, any>) =>
    acceptInvitation(data.newPassword);

  if (!hasCode) {
    toast("Missing verification code, try again.", { type: "error" });
    return <Redirect to="/login" />;
  }

  if (data && data.acceptUserInvitation === true) {
    toast("Invitation accepted! You can login now.", { type: "success" });
    return <Redirect to="/login" />;
  }

  return (
    <Container>
      <Helmet>
        <title>Accept invitation – HookActions</title>
      </Helmet>

      <HeaderLoggedOut />

      <Row className="justify-content-center">
        <Col xl="10" lg="12" md="9">
          <Card className="o-hidden border-0 shadow-lg my-5">
            <Card.Body className="p-0">
              <Row>
                <Col lg="6" className="d-lg-block p-5">
                  <img
                    className="img-fluid"
                    src="/img/reset_password.svg"
                    alt="reset password"
                  />
                </Col>
                <Col
                  lg="6"
                  className="d-flex flex-col justify-content-center align-items-center"
                >
                  <div className="p-5">
                    <StatusEventsBanner />
                    <div className="text-center">
                      <h1 className="h4 text-gray-900 mb-2">
                        Accept invitation
                      </h1>
                      <p className="mb-4">
                        Set your password to finish accepting your invitation to
                        HookActions.
                      </p>
                    </div>
                    <Form className="user" onSubmit={handleSubmit(onSubmit)}>
                      <Form.Group>
                        <Form.Control
                          type="password"
                          name="newPassword"
                          placeholder="Password"
                          className="form-control-user"
                          autoComplete="new-password"
                          ref={register({ required: true }) as RBRef}
                          disabled={loading}
                          isInvalid={
                            HasError(error, "password") ||
                            HasError(error, "code")
                          }
                        />
                        <FormError errors={error} fieldName="password" />
                        <FormError errors={error} fieldName="code" />
                      </Form.Group>
                      <Button
                        className="btn-user"
                        block={true}
                        type="submit"
                        disabled={loading}
                      >
                        <span>Set Password</span>
                        {loading && (
                          <Spinner
                            className="ml-2"
                            animation="border"
                            size="sm"
                          />
                        )}
                      </Button>

                      <AccountCreationLegalease className="mt-2 text-center" />
                    </Form>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

interface Vars {
  code: string;
  newPassword: string;
}

interface Response {
  acceptUserInvitation: boolean;
}

const MUTATION = gql`
  mutation AcceptInvitation($code: UUID!, $newPassword: String!) {
    acceptUserInvitation(code: $code, newPassword: $newPassword)
  }
`;

const WithMutation: React.FC = () => {
  const params = new URLSearchParams(window.location.search);
  const code = params.get("code");

  return (
    <Mutation<Response, Vars> mutation={MUTATION}>
      {(mutate, { data, loading, error }) => (
        <AcceptInvitationPage
          hasCode={params.has("code")}
          acceptInvitation={newPassword =>
            mutate({
              variables: {
                newPassword,
                code: code!
              }
            })
          }
          loading={loading}
          error={error}
          data={data}
        />
      )}
    </Mutation>
  );
};

export default WithMutation;
