import React from "react";
import Helmet from "react-helmet";
import Card from "react-bootstrap/Card";
import ConnectCalendlyAccountForm from "./ConnectCalendlyAccountForm";

const ConnectPage: React.FC = () => (
  <>
    <Helmet>
      <title>Connect Calendly Account – HookActions</title>
    </Helmet>

    <div className="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 className="h3 mb-0 text-gray-800">Connect Calendly Account</h1>
    </div>

    <Card className="shadow">
      <Card.Header>New account details</Card.Header>
      <Card.Body>
        <ConnectCalendlyAccountForm />
      </Card.Body>
    </Card>
  </>
);

export default ConnectPage;
