import React from "react";
import Helmet from "react-helmet";
import ConnectLink from "./ConnectLink";
import StripeAccountsTable from "./StripeAccountsTable";
import Card from "react-bootstrap/Card";

const StripeConnectedAccountsPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Stripe Accounts – HookActions</title>
      </Helmet>

      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Stripe Accounts</h1>
        <ConnectLink />
      </div>

      <Card className="shadow mb-4">
        <Card.Header>Accounts</Card.Header>
        <Card.Body>
          <StripeAccountsTable />
        </Card.Body>
      </Card>
    </>
  );
};

export default StripeConnectedAccountsPage;
