import React from "react";
import Form from "react-bootstrap/Form";

interface Props {
  className?: string;
}

const AccountCreationLegalease: React.FC<Props> = ({ className }) => (
  <Form.Text className={className || ""}>
    By creating an account, you agree to our{" "}
    <a href="https://hookactions.com/terms-and-conditions">Terms of Use</a> and{" "}
    <a href="https://hookactions.com/privacy-policy">Privacy Policy</a>.
  </Form.Text>
);

export default AccountCreationLegalease;
