import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LoginPage from "./pages/login";
import RegisterPage from "./pages/register";
import ForgotPasswordPage from "./pages/forgot-password";
import Page404 from "./pages/404";
import DashboardContainerPage from "./pages/dashboard-container";
import LogoutPage from "./pages/logout";
import { getToken, setToken } from "./utils/localStorage";
import { InMemoryCache } from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { ApolloClient } from "apollo-client";
import { ToastContainer } from "react-toastify";
import ForgotPasswordCompletePage from "./pages/forgot-password/complete";
import ResetPasswordPage from "./pages/reset-password";
import RegistrationCompletePage from "./pages/register/complete";
import FinishRegistrationPage from "./pages/register/finish";
import ConfirmEmailPage from "./pages/confirm-email";
import ConfirmEmailCompletePage from "./pages/confirm-email/complete";
import MaintenancePage from "./pages/maintenance";
import AcceptInvitationPage from "./pages/accept-invitation";

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}/v1/graphql`
});
interface Context {
  userId?: string;
  organizationId?: string;
}
export const AppContext = React.createContext<Context>({});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  };
});

const errorLink = onError(({ response, networkError }) => {
  // TODO
});

const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  cache: new InMemoryCache()
});

const App: React.FC = () => {
  const [userId, setUserId] = useState();
  const [organizationId, setOrganizationId] = useState();
  const [userToken, setUserTokenState] = useState(getToken());
  const setUserToken = (token: string | null) => {
    setUserTokenState(token);
    setToken(token);
  };

  const dashboardContainerPage = () => (
    <DashboardContainerPage
      setUserId={setUserId}
      userToken={userToken}
      setUserToken={setUserToken}
      setOrganizationId={setOrganizationId}
    />
  );
  const loginPage = () => <LoginPage setUserToken={setUserToken} />;

  return (
    <ApolloProvider client={client}>
      <AppContext.Provider value={{ userId, organizationId }}>
        <Router>
          <Switch>
            <Route
              path="/maintenance"
              exact={true}
              component={MaintenancePage}
            />
            <Route path="/logout" exact={true} component={LogoutPage} />
            <Route path="/login" exact={true} component={loginPage} />
            <Route path="/register" exact={true} component={RegisterPage} />
            <Route
              path="/register/complete"
              exact={true}
              component={RegistrationCompletePage}
            />
            <Route
              path="/register/finish"
              exact={true}
              component={FinishRegistrationPage}
            />
            <Route
              path="/forgot-password"
              exact={true}
              component={ForgotPasswordPage}
            />
            <Route
              path="/forgot-password/complete"
              exact={true}
              component={ForgotPasswordCompletePage}
            />
            <Route
              path="/reset-password"
              exact={true}
              component={ResetPasswordPage}
            />
            <Route
              path="/confirm-email"
              exact={true}
              component={ConfirmEmailPage}
            />
            <Route
              path="/confirm-email/complete"
              exact={true}
              component={ConfirmEmailCompletePage}
            />
            <Route
              exact={true}
              path="/accept-invitation"
              component={AcceptInvitationPage}
            />

            {/* All dashboard, aka logged in pages go in here */}
            <Route path="/" render={dashboardContainerPage} />

            {/* Catch all for 404s */}
            <Route component={Page404} />
          </Switch>
        </Router>
      </AppContext.Provider>
      <ToastContainer />
    </ApolloProvider>
  );
};

export default App;
