import React from "react";
import gql from "graphql-tag";
import { ApolloError } from "apollo-boost";
import { Query } from "react-apollo";
import { Connection } from "../../../graphql/relay";
import Form from "react-bootstrap/Form";
import GenericError from "../../../components/GenericError";
import LoadingDots from "../../../components/LoadingDots";
import Alert from "react-bootstrap/Alert";
import { LinkContainer } from "react-router-bootstrap";
import { RegisterFn, RBRef } from "../../../components/form/types";
import { CalendlyAccountNode } from "../../../graphql/calendly";

interface Props {
  register: RegisterFn;
  accounts: CalendlyAccountNode[];
  loading: boolean;
  error?: ApolloError;
}

const CalendlyAccountSelect: React.FC<Props> = ({
  register,
  accounts,
  loading,
  error
}) => {
  const accountLookup: Map<string, CalendlyAccountNode> = new Map();
  accounts.forEach(account => {
    accountLookup.set(account.id, account);
  });

  return (
    <>
      {!error && !loading && accounts.length === 0 && (
        <Alert variant="danger">
          <span className="mr-2">
            A Calendly account must be connected first.
          </span>
          <LinkContainer to="/accounts/calendly/new">
            <Alert.Link>Connect now &rsaquo;</Alert.Link>
          </LinkContainer>
        </Alert>
      )}

      <GenericError error={error} />

      <Form.Group>
        <Form.Label htmlFor="accountId">Account</Form.Label>
        <Form.Control
          as="select"
          name="accountId"
          disabled={loading}
          ref={register({ required: true }) as RBRef}
        >
          <option value="" />
          {accounts.map(account => (
            <option key={account.id} value={account.id}>
              {account.name}
            </option>
          ))}
        </Form.Control>
        {loading && (
          <Form.Text>
            Loading
            <LoadingDots />
          </Form.Text>
        )}
      </Form.Group>
    </>
  );
};

interface QProps {
  register: RegisterFn;
}

interface Response {
  calendlyAccountsConnection?: Connection<CalendlyAccountNode>;
}

const CALENDLY_ACCOUNTS = gql`
  query CalendlyAccountsBulk {
    calendlyAccountsConnection(first: 1000) {
      edges {
        node {
          id
          name
          createdAt
          updatedAt
        }
      }
    }
  }
`;

const WithQuery: React.FC<QProps> = props => (
  <Query<Response> query={CALENDLY_ACCOUNTS}>
    {({ data, loading, error }) => (
      <CalendlyAccountSelect
        {...props}
        loading={loading}
        error={error}
        accounts={
          data &&
          data.calendlyAccountsConnection &&
          data.calendlyAccountsConnection.edges
            ? data.calendlyAccountsConnection.edges.map(i => i.node!)
            : []
        }
      />
    )}
  </Query>
);

export default WithQuery;
