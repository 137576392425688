import React from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import { ApolloError } from "apollo-boost";
import RegistrationPage from "./index";
import { Redirect } from "react-router";
import GenericError from "../../components/GenericError";

interface Props {
  preRegistrationUserId: string;
  emailAddress?: string;
  error?: ApolloError;
  loading: boolean;
}

const FinishRegistrationPage: React.FC<Props> = props => {
  const { emailAddress, error, loading, preRegistrationUserId } = props;

  if (loading) return null; // should be "fast"

  return (
    <>
      <GenericError error={error} />
      <RegistrationPage
        emailAddress={emailAddress}
        preRegistrationUserId={preRegistrationUserId}
      />
    </>
  );
};

const PRE_REGISTRATION_USER_QUERY = gql`
  query PreRegistrationUser($id: ID!) {
    preRegistrationUser(id: $id) {
      emailAddress
    }
  }
`;

interface QueryVars {
  id: string;
}

interface QueryResponse {
  preRegistrationUser?: {
    emailAddress: string;
  };
}

const WithQuery: React.FC = () => {
  const preRegistrationUserId = new URLSearchParams(window.location.search).get(
    "id"
  );

  if (!preRegistrationUserId) {
    return <Redirect to="/register" />;
  }

  return (
    <Query<QueryResponse, QueryVars>
      query={PRE_REGISTRATION_USER_QUERY}
      variables={{ id: preRegistrationUserId }}
    >
      {({ data, loading, error }) => {
        return (
          <FinishRegistrationPage
            emailAddress={
              data &&
              data.preRegistrationUser &&
              data.preRegistrationUser.emailAddress
            }
            loading={loading}
            error={error}
            preRegistrationUserId={preRegistrationUserId}
          />
        );
      }}
    </Query>
  );
};

export default WithQuery;
