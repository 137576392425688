import { Node } from "./relay";
import { WebhookTag } from "./webhooks";

export enum SalesforceEnvironment {
  PROD = "PROD",
  SANDBOX = "SANDBOX"
}

export interface SalesforceAccountNode extends Node {
  instanceURL: string;
  environment: SalesforceEnvironment;
  createdAt: string;
  objects: SalesforceObject[];
}

export interface SalesforceObject {
  name: string;
}

export enum SalesforceEvent {
  BEFORE_INSERT = "BEFORE_INSERT",
  BEFORE_UPDATE = "BEFORE_UPDATE",
  BEFORE_DELETE = "BEFORE_DELETE",
  AFTER_INSERT = "AFTER_INSERT",
  AFTER_UPDATE = "AFTER_UPDATE",
  AFTER_DELETE = "AFTER_DELETE",
  AFTER_UNDELETE = "AFTER_UNDELETE"
}

export interface SalesforceWebhookNode extends Node {
  name: string;
  createdAt: string;
  updatedAt: string;
  object: SalesforceObject;
  url: string;
  events: SalesforceEvent[];
  hmacHeaderName: string;
  hmacKey: string;
  tags: WebhookTag[];
}
