import React from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";

const Page404 = () => {
  return (
    <Container fluid={true}>
      <Helmet>
        <title>404 – HookActions</title>
      </Helmet>
      <div className="text-center p-5">
        <div className="error mx-auto" data-text="404">
          404
        </div>
        <p className="lead text-gray-800 mb-5">Page Not Found</p>
        <p className="text-gray-500 mb-0">
          It looks like you found a glitch in the matrix...
        </p>
        <Link to="/">&larr; Back home</Link>
      </div>
    </Container>
  );
};

export default Page404;
