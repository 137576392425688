import React from "react";
import Moment from "react-moment";

interface Props {
  cell: any;
}

const TimeRenderer: React.FC<Props> = ({ cell }) => <Moment>{cell}</Moment>;

export default TimeRenderer;
