import React from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import { ApolloError } from "apollo-boost";
import Card from "react-bootstrap/Card";
import Helmet from "react-helmet";
import Alert from "react-bootstrap/Alert";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "react-bootstrap/Table";
import CenteredSpinner from "../../components/CenteredSpinner";
import GenericError from "../../components/GenericError";
import BooleanRenderer from "../../components/BooleanRenderer";

interface Props {
  debugInfo?: Response;
  loading: boolean;
  error?: ApolloError;
}

const webAppCommitSha = process.env.REACT_APP_COMMIT_REF || "dev";

const DebugPage: React.FC<Props> = ({ debugInfo, loading, error }) => {
  return (
    <>
      <Helmet>
        <title>Dev Debug – HookActions</title>
      </Helmet>

      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Developer Debug Info</h1>
      </div>

      <Card className="shadow mb-4">
        <Card.Body>
          <Alert variant="warning">
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <span className="ml-2">
              If you are not a developer, this info isn't important.
            </span>
          </Alert>

          {loading && <CenteredSpinner />}
          <GenericError error={error} />

          {!loading && debugInfo && (
            <Table className="kv">
              <tbody>
                <tr>
                  <td>API build version</td>
                  <td>{debugInfo.version.buildVersion}</td>
                </tr>
                <tr>
                  <td>API build commit</td>
                  <td>{debugInfo.version.buildCommit}</td>
                </tr>
                <tr>
                  <td>React app commit SHA</td>
                  <td>{webAppCommitSha}</td>
                </tr>
                <tr>
                  <td>User id</td>
                  <td>{debugInfo.me.id}</td>
                </tr>
                <tr>
                  <td>User email</td>
                  <td>{debugInfo.me.emailAddress}</td>
                </tr>
                <tr>
                  <td>Org id</td>
                  <td>{debugInfo.me.organization.id}</td>
                </tr>
                <tr>
                  <td>Org name</td>
                  <td>{debugInfo.me.organization.name}</td>
                </tr>
              </tbody>
            </Table>
          )}
        </Card.Body>
      </Card>

      <Card className="shadow mb-4">
        <Card.Header>Feature flags</Card.Header>
        <Card.Body>
          {loading && <CenteredSpinner />}
          {!loading && debugInfo && (
            <Table className="kv">
              <tbody>
                {debugInfo.features.map(feature => (
                  <tr key={feature.flag}>
                    <td>{feature.flag}</td>
                    <td>
                      <BooleanRenderer cell={feature.isEnabled} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

const DEBUG_QUERY = gql`
  query DebugInfo {
    version {
      buildVersion
      buildCommit
    }

    features {
      flag
      isEnabled
    }

    me {
      id
      emailAddress

      organization {
        id
        name
      }
    }
  }
`;

interface Response {
  version: {
    buildVersion: string;
    buildCommit: string;
  };
  features: Array<{
    flag: string;
    isEnabled: boolean;
  }>;
  me: {
    id: string;
    emailAddress: string;
    organization: {
      id: string;
      name: string;
    };
  };
}

const WithQuery: React.FC = () => {
  return (
    <Query<Response> query={DEBUG_QUERY}>
      {({ loading, data, error }) => {
        return <DebugPage loading={loading} debugInfo={data} error={error} />;
      }}
    </Query>
  );
};

export default WithQuery;
