import React from "react";
import gql from "graphql-tag";
import PaymentsBanner from "./PaymentsBanner";
import { Context as FeaturesContext } from "../Features";
import { Query } from "react-apollo";
import { FeatureFlag } from "../../graphql/types";

const QUERY = gql`
  query PaymentsBannerQuery {
    me {
      id
      organization {
        id
        freeTrialEnd
        daysLeftInFreeTrial
        hasValidSubscription
      }
    }
  }
`;

interface Response {
  me?: {
    organization: {
      freeTrialEnd?: string;
      daysLeftInFreeTrial?: number;
      hasValidSubscription: boolean;
    };
  };
}

const PaymentsBannerWithQuery: React.FC = () => {
  return (
    <FeaturesContext.Consumer>
      {({ featuresByFlag }) => (
        <Query<Response> query={QUERY}>
          {({ data, loading, error }) => {
            if (loading && !error) {
              return null;
            }

            if (!loading && error) {
              console.error(error);
              return null;
            }

            if (!loading && !data && !data!.me) {
              return null;
            }

            return data && data.me ? (
              <PaymentsBanner
                paymentsFeatureEnabled={
                  featuresByFlag[FeatureFlag.PAYMENTS] != null
                    ? featuresByFlag[FeatureFlag.PAYMENTS].isEnabled
                    : false
                }
                daysLeftInFreeTrial={data.me.organization.daysLeftInFreeTrial}
                freeTrialEndDate={data.me.organization.freeTrialEnd}
                hasValidSubscription={data.me.organization.hasValidSubscription}
              />
            ) : null;
          }}
        </Query>
      )}
    </FeaturesContext.Consumer>
  );
};

export default PaymentsBannerWithQuery;
