import React from "react";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";

const CheckoutSuccessPage: React.FC = () => {
  toast("Your subscription has been set up", { type: "success" });
  return <Redirect to="/billing" />;
};

export default CheckoutSuccessPage;
