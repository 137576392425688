import React from "react";
import Helmet from "react-helmet";
import ConnectLinks from "./ConnectLinks";
import SalesforceAccountsTable from "./SalesforceAccountsTable";
import Card from "react-bootstrap/Card";

const SalesforceConnectedAccountsPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Salesforce Accounts – HookActions</title>
      </Helmet>

      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Salesforce Accounts</h1>
        <ConnectLinks />
      </div>

      <Card className="shadow mb-4">
        <Card.Header>Accounts</Card.Header>
        <Card.Body>
          <SalesforceAccountsTable />
        </Card.Body>
      </Card>
    </>
  );
};

export default SalesforceConnectedAccountsPage;
