import React from "react";
import { WebhookCategory } from "../../../graphql/types";
import StripeWebhookModal from "../stripe/StripeWebhookDetailModalWithQuery";
import SalesforceWebhookModal from "../salesforce/SalesforceWebhookDetailModalWithQuery";
import CalendlyWebhookModal from "../calendly/CalendlyDetailModalWithQuery";
import CopperCRMWebhookModal from "../copper/DetailModalWithQuery";

interface Props {
  show: boolean;
  webhookId?: string;
  category?: WebhookCategory;
  onHide: () => void;
}

const WebhookModalManager: React.FC<Props> = ({
  show,
  webhookId,
  category,
  onHide
}) => {
  if (!webhookId || !show) {
    return null;
  }

  switch (category) {
    case WebhookCategory.SALESFORCE:
      return (
        <SalesforceWebhookModal
          show={show}
          onHide={onHide}
          webhookId={webhookId}
        />
      );
    case WebhookCategory.STRIPE:
      return (
        <StripeWebhookModal show={show} onHide={onHide} webhookId={webhookId} />
      );
    case WebhookCategory.CALENDLY:
      return (
        <CalendlyWebhookModal
          show={show}
          onHide={onHide}
          webhookId={webhookId}
        />
      );
    case WebhookCategory.COPPER_CRM:
      return (
        <CopperCRMWebhookModal
          show={show}
          onHide={onHide}
          webhookId={webhookId}
        />
      );
  }

  return null;
};

export default WebhookModalManager;
