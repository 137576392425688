import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

export const FilterSection: React.FC = ({ children }) => {
  return <div className="filter__section">{children}</div>;
};

interface FilterHeaderProps {
  toggleSelectAll: () => void;
  allSelected: boolean;
}

export const FilterHeader: React.FC<FilterHeaderProps> = ({
  children,
  toggleSelectAll,
  allSelected
}) => {
  return (
    <div className="filter__header d-flex flex-row justify-content-between align-items-center">
      <h5>{children}</h5>
      <Button onClick={toggleSelectAll} variant="link">
        {!allSelected && "Select all"}
        {allSelected && "Deselect all"}
      </Button>
    </div>
  );
};

export const FilterBody: React.FC = ({ children }) => {
  return <div className="filter__body">{children}</div>;
};

interface FilterOptionProps {
  keyName: string;
  value: string;
  checked: boolean;
  onChange: (newValue: boolean) => void;
}

export const FilterOption: React.FC<FilterOptionProps> = ({
  keyName,
  value,
  checked,
  onChange
}) => {
  return (
    <Form.Check title={value}>
      <Form.Check.Input
        type="checkbox"
        id={`filter-option-${keyName}-${value}`}
        checked={checked}
        onChange={(e: any) => onChange(e.target.checked)}
      />
      <Form.Check.Label
        htmlFor={`filter-option-${keyName}-${value}`}
        title={value}
      >
        {value}
      </Form.Check.Label>
    </Form.Check>
  );
};
