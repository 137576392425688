import React from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import { ApolloError } from "apollo-boost";
import CenteredSpinner from "../../components/CenteredSpinner";
import GenericError from "../../components/GenericError";
import Table from "react-bootstrap/Table";
import Moment from "react-moment";

interface Props {
  data?: Response;
  error?: ApolloError;
  loading: boolean;
}

const OrgDetails: React.FC<Props> = ({ data, error, loading }) => {
  if (loading) {
    return <CenteredSpinner />;
  }
  if (error) {
    return <GenericError error={error} />;
  }
  if (!data) {
    return null;
  }
  const { organization } = data.me!;

  return (
    <Table className="kv">
      <tbody>
        <tr>
          <td>Name</td>
          <td>{organization.name}</td>
        </tr>
        <tr>
          <td>Created at</td>
          <td>
            <Moment>{organization.createdAt}</Moment>
          </td>
        </tr>
        {organization.usersConnection && (
          <tr>
            <td>Total users</td>
            <td>{organization.usersConnection.totalCount.toLocaleString()}</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

const QUERY = gql`
  query OrgQuery {
    me {
      id

      organization {
        id
        name
        createdAt

        usersConnection {
          totalCount
        }
      }
    }
  }
`;

interface Response {
  me?: {
    id: string;
    organization: {
      id: string;
      name: string;
      createdAt: string;
      usersConnection?: {
        totalCount: number;
      };
    };
  };
}

const WithQuery: React.FC = () => {
  return (
    <Query<Response> query={QUERY}>
      {({ data, loading, error }) => (
        <OrgDetails data={data} loading={loading} error={error} />
      )}
    </Query>
  );
};

export default WithQuery;
