import React, { useState } from "react";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import {
  CreateCopperCRMWebhook,
  CreateCopperCRMWebhookVariables,
  CopperCRMEvent,
  CopperCRMWebhookType
} from "../../../graphql/types";
import { ApolloError } from "apollo-client";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import useForm from "react-hook-form";
import Form from "react-bootstrap/Form";
import GenericError from "../../../components/GenericError";
import CopperCRMAccountSelect from "./CopperCRMAccountSelect";
import TagEditor from "../../../components/form/tags/TagEditorWithQuery";
import { RBRef } from "../../../components/form/types";
import FormError, { HasError } from "../../../components/FormError";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import uuid4 from "uuid/v4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";

interface Props {
  loading: boolean;
  error?: ApolloError;
  createWebhook: (variables: CreateCopperCRMWebhookVariables) => void;
}

const CopperCRMWebhookForm: React.FC<Props> = ({
  loading,
  error,
  createWebhook
}) => {
  const [tags, setTags] = useState();
  const { register, handleSubmit } = useForm();

  const onSubmit = (record: Record<string, any>) =>
    createWebhook({
      accountId: record.accountId,
      url: record.url,
      event: record.event,
      webhookType: record.webhookType,
      secret: record.secret,
      tags
    });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <GenericError error={error} isForm={true} />

      <CopperCRMAccountSelect register={register} />
      <Form.Group>
        <Form.Label htmlFor="event">Event</Form.Label>
        <Form.Control
          as="select"
          ref={register({ required: true }) as RBRef}
          name="event"
          isInvalid={HasError(error, "event")}
        >
          <option value="" />
          {Object.keys(CopperCRMEvent).map(event => (
            <option value={event} key={event}>
              {event.toLocaleLowerCase()}
            </option>
          ))}
        </Form.Control>

        <FormError errors={error} fieldName="event" />
      </Form.Group>
      <Form.Group>
        <Form.Label htmlFor="webhookType">Type</Form.Label>
        <Form.Control
          as="select"
          ref={register({ required: true }) as RBRef}
          name="webhookType"
          isInvalid={HasError(error, "webhook_type")}
        >
          <option value="" />
          {Object.keys(CopperCRMWebhookType).map(event => (
            <option value={event} key={event}>
              {event.toLocaleLowerCase()}
            </option>
          ))}
        </Form.Control>

        <FormError errors={error} fieldName="webhook_type" />
      </Form.Group>
      <Form.Group>
        <Form.Label htmlFor="url">URL</Form.Label>
        <Form.Control
          type="text"
          placeholder="e.g. https://example.com/my/webhook"
          name="url"
          ref={register({ required: true }) as RBRef}
          isInvalid={HasError(error, "url")}
        />
        <FormError errors={error} fieldName="url" />
      </Form.Group>
      <Form.Group>
        <Form.Label htmlFor="secret">
          <span className="mr-2">Secret</span>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="secret-help">
                The secret to be included in the webhook body as the{" "}
                <code>key</code> attribute.
              </Tooltip>
            }
          >
            <FontAwesomeIcon icon={faQuestionCircle} />
          </OverlayTrigger>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="e.g. 792e641e-c1ab-11e9-a484-3c15c2eb7774"
          name="secret"
          defaultValue={uuid4()}
          ref={register({ required: true }) as RBRef}
          isInvalid={HasError(error, "secret")}
        />
        <Form.Text>
          More information about this field can be found{" "}
          <a
            href="https://developer.copper.com/webhooks/create-new-subscription.html"
            target="_blank"
            rel="noreferrer noopener"
          >
            here
          </a>
          .
        </Form.Text>
        <FormError errors={error} fieldName="secret" />
      </Form.Group>
      <Form.Group>
        <Form.Label htmlFor="tags">Tags</Form.Label>
        <Form.Text>
          <strong>Pro tip:</strong> add a "name" tag which will be used in the
          webhooks table.
        </Form.Text>
        <TagEditor
          name="tags"
          setValue={(_, tags) => setTags(tags)}
          existingTags={[]}
          mutationError={error}
        />
      </Form.Group>

      <Button type="submit" block={true} variant="primary" disabled={loading}>
        <span>Create webhook</span>
        {loading && <Spinner animation="border" className="ml-2" size="sm" />}
      </Button>
    </Form>
  );
};

const CREATE_WEBHOOK = gql`
  mutation CreateCopperCRMWebhook(
    $accountId: ID!
    $event: CopperCRMEvent!
    $webhookType: CopperCRMWebhookType!
    $url: String!
    $secret: String!
    $tags: [TagInput!]
  ) {
    createCopperCRMWebhook(
      input: {
        accountId: $accountId
        event: $event
        webhookType: $webhookType
        url: $url
        secret: $secret
        tags: $tags
      }
    ) {
      id
    }
  }
`;

const WithMutation: React.FC = () => (
  <Mutation<CreateCopperCRMWebhook, CreateCopperCRMWebhookVariables>
    mutation={CREATE_WEBHOOK}
  >
    {(mutate, { loading, error, data }) => {
      if (
        !loading &&
        !error &&
        data &&
        data.createCopperCRMWebhook &&
        data.createCopperCRMWebhook.id
      ) {
        toast("Webhook created", { type: "success" });
        return <Redirect to="/webhooks" />;
      }

      return (
        <CopperCRMWebhookForm
          loading={loading}
          error={error}
          createWebhook={variables => mutate({ variables })}
        />
      );
    }}
  </Mutation>
);

export default WithMutation;
