import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { ApolloError, gql } from "apollo-boost";
import useForm from "react-hook-form";
import Alert from "react-bootstrap/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import FormError, { HasError } from "../../components/FormError";
import Spinner from "react-bootstrap/Spinner";
import { Redirect } from "react-router";
import { toast } from "react-toastify";
import { Mutation } from "react-apollo";
import { RBRef } from "../../components/form/types";

interface Props {
  patchUser: (password: string) => void;
  error?: ApolloError;
  loading: boolean;
  data?: {
    patchUser?: {
      id: string;
    };
  };
}

const ChangePasswordForm: React.FC<Props> = (props: Props) => {
  const { patchUser, error, loading, data } = props;
  const { register, handleSubmit } = useForm();

  const onSubmit = (data: Record<string, any>) => patchUser(data.password);

  if (data && data.patchUser && data.patchUser.id) {
    toast("Password changed. Login again.", { type: "success" });
    return <Redirect to="/logout" />;
  }

  return (
    <Form className="user" onSubmit={handleSubmit(onSubmit)}>
      <Alert variant="warning">
        <FontAwesomeIcon icon={faExclamationTriangle} />
        <span className="ml-2">
          Changing your password will log you out of all active sessions.
        </span>
      </Alert>
      <Form.Group>
        <Form.Control
          className="form-control-user"
          type="password"
          placeholder="New password"
          autoComplete="new-password"
          name="password"
          ref={register({ required: true }) as RBRef}
          disabled={loading}
          isInvalid={HasError(error, "password")}
        />
        <FormError errors={error} fieldName="password" />
      </Form.Group>

      <Button
        type="submit"
        className="btn-user"
        block={true}
        variant="danger"
        disabled={loading}
      >
        <span>Change password</span>
        {loading && <Spinner animation="border" className="ml-2" size="sm" />}
      </Button>
    </Form>
  );
};

const PATCH_USER = gql`
  mutation ChangePassword($id: ID!, $password: String) {
    patchUser(id: $id, patch: { password: $password }) {
      id
      emailAddress
      firstName
      lastName
    }
  }
`;

interface PatchUserVars {
  id: string;
  firstName?: string;
  lastName?: string;
  password?: string;
}

interface PatchUserResponse {
  patchUser?: {
    id: string;
    emailAddress: string;
    firstName: string;
    lastName: string;
  };
}

interface MutationProps {
  userId: string;
}

const WithMutation: React.FC<MutationProps> = (props: MutationProps) => {
  const { userId: id } = props;

  return (
    <Mutation<PatchUserResponse, PatchUserVars> mutation={PATCH_USER}>
      {(mutate, { data, loading, error }) => (
        <ChangePasswordForm
          patchUser={password => mutate({ variables: { password, id } })}
          data={data}
          loading={loading}
          error={error}
        />
      )}
    </Mutation>
  );
};

export default WithMutation;
