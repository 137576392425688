import React, { useState } from "react";
import gql from "graphql-tag";
import { StripeAccountNode } from "../../../graphql/stripe";
import { Column } from "../../../components/Table";
import Table from "../../../components/GraphQLTable";
import TimeRenderer from "../../../components/TimeRenderer";
import BooleanRenderer from "../../../components/BooleanRenderer";
import { ApolloError } from "apollo-boost";
import Button from "react-bootstrap/Button";
import ConfirmDialog from "../../../components/Dialog/ConfirmDialog";
import { Mutation } from "react-apollo";
import SimpleEditDialog from "../../../components/Dialog/SimpleEditDialog";

const STRIPE_ACCOUNTS_QUERY = gql`
  query StripeAccounts($first: Int, $after: String, $before: String) {
    stripeAccountsConnection(first: $first, after: $after, before: $before) {
      edges {
        node {
          id
          isAuthorized
          livemode
          name
          createdAt
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

interface Props {
  error?: ApolloError;

  deleteAccount: (id: string) => void;
  deleteInProgress: boolean;

  editAccount: (id: string, name?: string) => void;
  editInProgress: boolean;
}

interface IModalState {
  show: boolean;
  accountId?: string;
}

const StripeAccountsTable: React.FC<Props> = ({
  deleteAccount,
  deleteInProgress,
  error,
  editAccount,
  editInProgress
}) => {
  const [deleteModalState, setDeleteModalState] = useState<IModalState>({
    show: false
  });
  const [editModalState, setEditModalState] = useState<IModalState>({
    show: false
  });

  const columns: Column<StripeAccountNode>[] = [
    {
      key: "id",
      hidden: true
    },
    {
      key: "createdAt",
      label: "Created at",
      renderer: TimeRenderer
    },
    {
      key: "name",
      label: "Name"
    },
    {
      key: "isAuthorized",
      label: "Is Authorized?",
      renderer: BooleanRenderer
    },
    {
      key: "livemode",
      label: "Livemode?",
      renderer: BooleanRenderer
    },
    {
      key: "id",
      renderer: ({ cell }) => (
        <>
          <Button
            variant="link"
            onClick={() => setEditModalState({ show: true, accountId: cell })}
          >
            Edit
          </Button>
          <Button
            variant="link"
            className="text-danger p-0"
            onClick={() => setDeleteModalState({ show: true, accountId: cell })}
          >
            Delete
          </Button>
        </>
      )
    }
  ];

  return (
    <>
      <Table<StripeAccountNode>
        query={STRIPE_ACCOUNTS_QUERY}
        name="stripeAccountsConnection"
        columns={columns}
      />
      <ConfirmDialog
        variant="danger"
        actionText="Delete"
        loading={deleteInProgress}
        text={
          <>
            <p>Are you sure you want to delete this Stripe account?</p>
            <p>
              This will delete <strong>all</strong> associated webhooks.
            </p>
          </>
        }
        title="Delete Stripe account"
        error={error}
        show={deleteModalState.show}
        handleCancel={() => setDeleteModalState({ show: false })}
        handleAction={() => deleteAccount(deleteModalState.accountId!)}
      />
      <SimpleEditDialog
        variant="primary"
        text={null}
        actionText="Save"
        loading={editInProgress}
        title="Edit Stripe account name"
        error={error}
        show={editModalState.show}
        handleCancel={() => setEditModalState({ show: false })}
        handleAction={name => editAccount(editModalState.accountId!, name)}
        fieldName="name"
      />
    </>
  );
};

const PATCH_STRIPE_ACCOUNT_MUTATION = gql`
  mutation PatchStripeAccount($id: ID!, $name: String) {
    patchStripeAccount(id: $id, patch: { name: $name }) {
      id
      name
    }
  }
`;

interface PatchStripeAccountVars {
  id: string;
  name?: string;
}

interface PatchStripeAccountResponse {
  patchStripeAccount?: {
    id: string;
    name: string;
  };
}

const DELETE_STRIPE_ACCOUNT_MUTATION = gql`
  mutation DeleteStripeAccount($id: ID!) {
    deleteStripeAccount(id: $id)
  }
`;

interface DeleteStripeAccountVars {
  id: string;
}

interface DeleteStripeAccountResponse {
  deleteStripeAccount: boolean;
}

const WithMutation: React.FC = () => {
  return (
    <Mutation<DeleteStripeAccountResponse, DeleteStripeAccountVars>
      mutation={DELETE_STRIPE_ACCOUNT_MUTATION}
    >
      {(deleteMutate, { loading: deleteLoading, error: deleteError }) => (
        <Mutation<PatchStripeAccountResponse, PatchStripeAccountVars>
          mutation={PATCH_STRIPE_ACCOUNT_MUTATION}
        >
          {(editMutation, { loading: editLoading, error: editError }) => (
            <StripeAccountsTable
              deleteAccount={id =>
                deleteMutate({ variables: { id } }).then(() =>
                  window.location.reload()
                )
              }
              deleteInProgress={deleteLoading}
              error={deleteError || editError}
              editAccount={(id, name) =>
                editMutation({ variables: { id, name } }).then(() =>
                  window.location.reload()
                )
              }
              editInProgress={editLoading}
            />
          )}
        </Mutation>
      )}
    </Mutation>
  );
};

export default WithMutation;
