import React from "react";
import useBodyClass from "../../utils/useBodyClass";
import Container from "react-bootstrap/Container";
import Helmet from "react-helmet";
import HeaderLoggedOut from "../../components/HeaderLoggedOut";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import StatusEventsBanner from "../../components/StatusEvents";

const MaintenancePage: React.FC = () => {
  useBodyClass("bg-gradient-primary");

  return (
    <Container>
      <Helmet>
        <title>Maintenance – HookActions</title>
      </Helmet>

      <HeaderLoggedOut />

      <Row className="justify-content-center">
        <Col xl="10" lg="12" md="9">
          <Card className="o-hidden border-0 shadow-lg my-5">
            <Card.Body className="p-0">
              <Row>
                <Col lg="5" className="d-lg-lbock p-5">
                  <img
                    src="/img/maintenance.svg"
                    alt="maintenance"
                    style={{ width: "100%" }}
                  />
                </Col>
                <Col lg="7">
                  <div className="p-5">
                    <div className="text-center">
                      <h1 className="h4 text-gray-900 mb-4">
                        Site under maintenance
                      </h1>
                      <p>We're upgrading our site right now.</p>
                      <p>
                        You can view our status{" "}
                        <a
                          href="https://status.hookactions.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          here &rsaquo;
                        </a>
                      </p>
                      <StatusEventsBanner />
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MaintenancePage;
