import React from "react";
import Spinner from "react-bootstrap/Spinner";

const CenteredSpinner = () => (
  <div className="d-flex justify-content-center align-items-center">
    <Spinner animation="border" />
  </div>
);

export default CenteredSpinner;
