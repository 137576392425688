import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ApolloError } from "apollo-boost";
import CenteredSpinner from "../CenteredSpinner";
import GenericError from "../GenericError";
import Form from "react-bootstrap/Form";
import useForm from "react-hook-form";
import FormError, { HasError } from "../FormError";

interface Props {
  variant: "primary" | "danger";
  actionText: string;
  show: boolean;

  text: React.ReactNode;
  title: React.ReactNode;

  loading: boolean;
  error?: ApolloError;

  handleCancel: () => void;
  handleAction: (newValue?: string) => void;

  placeholder?: string;
  fieldName: string;
}

const SimpleEditDialog: React.FC<Props> = props => {
  const {
    variant,
    actionText,
    handleAction,
    handleCancel,
    show,
    text,
    title,
    loading,
    error,
    placeholder,
    fieldName
  } = props;

  const { register, getValues } = useForm();
  const handleSubmit = () => handleAction(getValues()[fieldName]);

  return (
    <Modal show={show} onHide={handleCancel} centered={true} size="sm">
      <Modal.Header closeButton={true}>{title}</Modal.Header>
      <Modal.Body>
        <GenericError error={error} isForm={true} />
        {loading && <CenteredSpinner />}
        {!loading && text}
        <Form
          onSubmit={(e: any) => {
            handleSubmit();
            e.preventDefault();
          }}
        >
          <Form.Group>
            <Form.Control
              type="text"
              placeholder={placeholder}
              name={fieldName}
              ref={register}
              isInvalid={HasError(error, fieldName)}
            />
            <FormError errors={error} fieldName={fieldName} />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant={variant} onClick={handleSubmit}>
          {actionText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

SimpleEditDialog.defaultProps = {
  variant: "primary",
  text: null
};

export default SimpleEditDialog;
