import React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { StripeWebhookNode } from "../../../graphql/stripe";
import StripeWebhookDetailModal from "./StripeWebhookDetailModal";

const WEBHOOK_QUERY = gql`
  query StripeWebhook($id: ID!) {
    stripeWebhook(id: $id) {
      id
      name
      createdAt
      updatedAt
      account {
        id
        name
        stripeUserId
      }
      secret
      url
      events
      tags {
        key
        value
      }
    }
  }
`;

interface Vars {
  id: string;
}

interface Response {
  stripeWebhook?: StripeWebhookNode;
}

interface Props {
  show: boolean;
  onHide: () => void;
  webhookId: string;
}

const withQuery: React.FC<Props> = ({ show, onHide, webhookId }) => {
  return (
    <Query<Response, Vars> variables={{ id: webhookId }} query={WEBHOOK_QUERY}>
      {({ loading, data, error }) => (
        <StripeWebhookDetailModal
          show={show}
          onHide={onHide}
          webhook={data && data.stripeWebhook}
          loading={loading}
          error={error}
        />
      )}
    </Query>
  );
};

export default withQuery;
