import React, { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import {
  faExclamationTriangle,
  faTimesCircle,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useInterval from "../../utils/useInterval";
import { Redirect, Link } from "react-router-dom";

enum Component {
  API = "api",
  INFRA_AWS = "infra-aws",
  FRONTEND_APP = "frontend-app",
  FRONTEND_WWW = "frontend-www",
  ASYNC_PROCESSING = "async-processing"
}

enum Status {
  UPCOMING_MAINTENANCE = "upcoming_maintenance",
  DEGRADED = "degraded",
  DOWN = "down",
  INFO = "info"
}

interface Event {
  component: Component;
  status: Status;
  message: string;
}

interface Props {
  event: Event;
}

const apiUrl = process.env.REACT_APP_STATUS_EVENTS_API_URL;
const statusToVariant = {
  [Status.UPCOMING_MAINTENANCE]: "warning",
  [Status.DEGRADED]: "warning",
  [Status.DOWN]: "danger",
  [Status.INFO]: "primary"
};
const statusToIcon = {
  [Status.UPCOMING_MAINTENANCE]: faExclamationTriangle,
  [Status.DEGRADED]: faExclamationTriangle,
  [Status.DOWN]: faTimesCircle,
  [Status.INFO]: faInfoCircle
};

const EventDisplay: React.FC<Props> = ({ event }) => {
  // if the API is marked as down, just redirect to the maintenance page
  const onMaintenancePage = window.location.pathname === "/maintenance";
  if (
    event.component === Component.API &&
    event.status === Status.DOWN &&
    !onMaintenancePage
  ) {
    return <Redirect to="/maintenance" />;
  }

  if (event.status === Status.INFO && !onMaintenancePage) {
    return null;
  }

  return (
    <Alert variant={statusToVariant[event.status] as any} className="text-left">
      <FontAwesomeIcon icon={statusToIcon[event.status]} />
      <span className="px-2">{event.message}</span>
      {event.status === Status.INFO && onMaintenancePage && (
        <Link to="/">Go home &rsaquo;</Link>
      )}
    </Alert>
  );
};

const StatusEventsBanner: React.FC = () => {
  const [events, setEvents] = useState<Event[]>([]);

  const fetchEvents = () => {
    if (!apiUrl) {
      return;
    }

    fetch(apiUrl)
      .then(r => r.json())
      .then(apiEvents => setEvents(apiEvents))
      .catch(e => console.error(e));
  };

  useInterval(fetchEvents, 5000);
  useEffect(fetchEvents, []); // initial fetch

  if (events.length === 0) {
    return null;
  }

  return (
    <>
      {events.map(e => (
        <EventDisplay key={`${e.component}-${e.status}`} event={e} />
      ))}
    </>
  );
};

export default StatusEventsBanner;
