import React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { SalesforceWebhookNode } from "../../../graphql/salesforce";
import SalesforceWebhookDetailModal from "./SalesforceWebhookDetailModal";

const WEBHOOK_QUERY = gql`
  query SalesforceWebhook($id: ID!) {
    salesforceWebhook(id: $id) {
      id
      name
      createdAt
      updatedAt
      object {
        name
      }
      url
      events
      hmacHeaderName
      hmacKey
      tags {
        key
        value
      }
    }
  }
`;

interface Vars {
  id: string;
}

interface Response {
  salesforceWebhook?: SalesforceWebhookNode;
}

interface Props {
  show: boolean;
  onHide: () => void;
  webhookId: string;
}

const withQuery: React.FC<Props> = ({ show, onHide, webhookId }) => {
  return (
    <Query<Response, Vars> variables={{ id: webhookId }} query={WEBHOOK_QUERY}>
      {({ loading, data, error }) => (
        <SalesforceWebhookDetailModal
          show={show}
          onHide={onHide}
          webhook={data && data.salesforceWebhook}
          loading={loading}
          error={error}
        />
      )}
    </Query>
  );
};

export default withQuery;
