import React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import CalendlyWebhookDetailModal from "./CalendlyWebhookDetailModal";
import {
  CalendlyWebhook,
  CalendlyWebhookVariables
} from "../../../graphql/types";

const WEBHOOK_QUERY = gql`
  query CalendlyWebhook($id: ID!) {
    calendlyWebhook(id: $id) {
      id
      createdAt
      updatedAt
      url
      events
      account {
        id
        name
      }
      tags {
        key
        value
      }
    }
  }
`;

interface Props {
  show: boolean;
  onHide: () => void;
  webhookId: string;
}

const withQuery: React.FC<Props> = ({ show, onHide, webhookId }) => {
  return (
    <Query<CalendlyWebhook, CalendlyWebhookVariables>
      variables={{ id: webhookId }}
      query={WEBHOOK_QUERY}
    >
      {({ loading, data, error }) => (
        <CalendlyWebhookDetailModal
          show={show}
          onHide={onHide}
          webhook={data && data.calendlyWebhook}
          loading={loading}
          error={error}
        />
      )}
    </Query>
  );
};

export default withQuery;
