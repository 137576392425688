import React from "react";

export interface Filter {
  name: string;
  includeValues: string[];
}

export interface Filters {
  filters: Filter[];
}

export const FilterContext = React.createContext<Filters>({ filters: [] });
