import React, { useState } from "react";
import Table from "../../../components/GraphQLTable";
import { Column } from "../../../components/Table";
import gql from "graphql-tag";
import { WebhookNode, WebhookCategory } from "../../../graphql/webhooks";
import { FilterContext, Filter } from "./context";
import {
  MakeSingleValueTagRenderer,
  MultiTagRenderer
} from "../../../components/renderers/tags";
import WebhookModalManager from "./modal";
import Button from "react-bootstrap/Button";
import { ApolloError } from "apollo-boost";
import ConfirmDialog from "../../../components/Dialog/ConfirmDialog";
import { Mutation } from "react-apollo";
import { toast } from "react-toastify";

const ALL_WEBHOOKS = gql`
  query AllWebhooks(
    $first: Int
    $after: String
    $before: String
    $filters: [WebhookFilter!]
  ) {
    webhooksConnection(
      first: $first
      after: $after
      before: $before
      filters: $filters
    ) {
      edges {
        node {
          id
          category
          webhookId
          createdAt
          tags {
            key
            value
          }

          account {
            id
            category
            createdAt
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

interface Props {
  deleteWebhook: (id: string) => Promise<any>;
  deleteInProgress: boolean;
  error?: ApolloError;
}

interface AdditionalVars {
  filters: Filter[];
}

interface DetailModalState {
  show: boolean;
  webhookId?: string;
  category?: WebhookCategory;
}

interface DeleteModalState {
  show: boolean;
  webhookId?: string;
}

const AllWebhooksTable: React.FC<Props> = ({
  deleteWebhook,
  deleteInProgress,
  error
}) => {
  const [deleteModalState, setDeleteModalState] = useState<DeleteModalState>({
    show: false
  });
  const [detailModalState, setDetailModalState] = useState<DetailModalState>({
    show: false
  });
  const detailModalOnHide = () => setDetailModalState({ show: false });

  const columns: Column<WebhookNode>[] = [
    {
      key: "id",
      hidden: true
    },
    {
      key: "tags",
      label: "Name",
      renderer: MakeSingleValueTagRenderer(/^name$/i)
    },
    {
      key: "category",
      label: "Category"
    },
    {
      key: "tags",
      label: "Tags",
      renderer: MultiTagRenderer
    },
    {
      key: "id",
      renderer: ({ cell: webhookId }) => (
        <Button
          variant="link"
          className="text-danger p-0"
          onClick={() => setDeleteModalState({ show: true, webhookId })}
        >
          Delete
        </Button>
      ),
      disableOnRowClick: true
    }
  ];

  return (
    <FilterContext.Consumer>
      {({ filters }) => {
        return (
          <>
            <h5>Results</h5>
            <Table<WebhookNode, AdditionalVars>
              query={ALL_WEBHOOKS}
              columns={columns}
              name="webhooksConnection"
              additionalQueryVars={{ filters }}
              onRowClick={({ webhookId, category }: WebhookNode) => {
                setDetailModalState({ show: true, webhookId, category });
              }}
            />
            <WebhookModalManager
              show={detailModalState.show}
              webhookId={detailModalState.webhookId}
              category={detailModalState.category}
              onHide={detailModalOnHide}
            />
            <ConfirmDialog
              variant="danger"
              actionText="Delete"
              loading={deleteInProgress}
              text="Are you sure you want to delete this webhook?"
              title="Delete webhook"
              error={error}
              show={deleteModalState.show}
              handleCancel={() => setDeleteModalState({ show: false })}
              handleAction={() =>
                deleteWebhook(deleteModalState.webhookId!).then(() =>
                  setDeleteModalState({ show: false })
                )
              }
            />
          </>
        );
      }}
    </FilterContext.Consumer>
  );
};

interface MutateVars {
  id: string;
}

const DELETE_WEBHOOK_MUTATION = gql`
  mutation DeleteMaterializedWebhook($id: ID!) {
    deleteMaterializedWebhook(id: $id)
  }
`;

const WithMutation: React.FC = () => {
  return (
    <Mutation<{}, MutateVars> mutation={DELETE_WEBHOOK_MUTATION}>
      {(mutate, { loading, error }) => (
        <AllWebhooksTable
          deleteInProgress={loading}
          error={error}
          deleteWebhook={id =>
            mutate({ variables: { id } }).then(() =>
              toast("Webhook deleted", { type: "success" })
            )
          }
        />
      )}
    </Mutation>
  );
};

export default WithMutation;
