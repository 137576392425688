import React, { useState, useEffect } from "react";
import CenteredSpinner from "../../../components/CenteredSpinner";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import { ApolloError } from "apollo-boost";
import {
  WebhookCategory,
  WebhookFilters,
  WebhookFiltersVariables,
  WebhookFilters_webhookTagKeysConnection_edges_node
} from "../../../graphql/types";
import GenericError from "../../../components/GenericError";
import {
  FilterSection,
  FilterHeader,
  FilterBody,
  FilterOption
} from "./Filter";
import { Filter } from "./context";

interface Props {
  loading: boolean;
  error?: ApolloError;
  filters?: WebhookFilters_webhookTagKeysConnection_edges_node[] | null;
  setAppliedFilters: (filters: Filter[]) => void;
}

interface FilterCheckboxesState {
  // key is the filter.name
  [key: string]: {
    // key is the value.value, where the value is if the checkbox is checked or not
    [key: string]: boolean;
  };
}

const categoryFilter: WebhookFilters_webhookTagKeysConnection_edges_node = {
  __typename: "WebhookTagKey",
  id: "category-filter",
  name: "Category",
  values: Object.values(WebhookCategory).map(value => ({
    __typename: "WebhookTagValue",
    id: value,
    value
  }))
};

const FiltersList: React.FC<Props> = ({
  loading,
  error,
  filters,
  setAppliedFilters
}) => {
  const hasDataAndIsLoaded = !loading && !error;
  const [filterCheckboxes, setFilterCheckboxes] = useState<
    FilterCheckboxesState
  >({});

  useEffect(() => {
    // transform into Filter objects.
    const filters: Filter[] = [];

    Object.keys(filterCheckboxes).forEach(filterName => {
      const values: string[] = [];

      Object.keys(filterCheckboxes[filterName]).forEach(valueName => {
        if (filterCheckboxes[filterName][valueName]) {
          values.push(valueName);
        }
      });

      filters.push({ name: filterName, includeValues: values });
    });

    setAppliedFilters(filters);
    // eslint-disable-next-line
  }, [filterCheckboxes]);

  useEffect(() => {
    if (hasDataAndIsLoaded) {
      const newState: FilterCheckboxesState = {};

      [categoryFilter, ...(filters! || [])].forEach(filter => {
        newState[filter.name] = {};
        filter.values!.forEach(value => {
          newState[filter.name][value.value] = false;
        });
      });

      setFilterCheckboxes(newState);
    }
    // eslint-disable-next-line
  }, [hasDataAndIsLoaded]);

  const isChecked = (filterName: string, value: string): boolean => {
    if (!filterCheckboxes[filterName]) {
      return false;
    }
    return filterCheckboxes[filterName][value];
  };

  const allSelected = (filterName: string): boolean => {
    if (!filterCheckboxes[filterName]) {
      return false;
    }

    // return true if the length of checkboxes not checked is > 0
    return (
      Object.keys(filterCheckboxes[filterName]).filter(
        k => !filterCheckboxes[filterName][k]
      ).length === 0
    );
  };

  const makeOnChange = (filterName: string, value: string) => (
    newValue: boolean
  ) => {
    const newCheckboxData = { ...filterCheckboxes };
    newCheckboxData[filterName][value] = newValue;
    setFilterCheckboxes(newCheckboxData);
  };

  const makeSelectAll = (filterName: string) => () => {
    // if they're not all selected, mark them all as selected.
    // otherwise if they're all selected, deselect them all.
    const newValue = allSelected(filterName) ? false : true;
    const newCheckboxData = { ...filterCheckboxes };
    Object.keys(newCheckboxData[filterName]).forEach(k => {
      newCheckboxData[filterName][k] = newValue;
    });
    setFilterCheckboxes(newCheckboxData);
  };

  return (
    <div className="filter__list">
      <h4>Filters</h4>
      {loading && <CenteredSpinner />}
      {error && <GenericError error={error} />}
      {hasDataAndIsLoaded &&
        [
          categoryFilter,
          ...(filters || []).filter(f => !/name/i.test(f.name))
        ].map(filter => (
          <FilterSection key={filter.id}>
            <FilterHeader
              allSelected={allSelected(filter.name)}
              toggleSelectAll={makeSelectAll(filter.name)}
            >
              {filter.name}
            </FilterHeader>
            <FilterBody>
              {filter.values!.map(value => (
                <FilterOption
                  key={`${filter.id}-${value.id}`}
                  keyName={filter.name}
                  value={value.value}
                  checked={isChecked(filter.name, value.value)}
                  onChange={makeOnChange(filter.name, value.value)}
                />
              ))}
            </FilterBody>
          </FilterSection>
        ))}
    </div>
  );
};

const filtersQuery = gql`
  query WebhookFilters($first: Int, $after: String, $before: String) {
    webhookTagKeysConnection(first: $first, after: $after, before: $before) {
      edges {
        node {
          id
          name
          values {
            id
            value
          }
        }
        cursor
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

interface QProps {
  setAppliedFilters: (filters: Filter[]) => void;
}

// try to avoid pagination for now...
const WithQuery: React.FC<QProps> = ({ setAppliedFilters }) => {
  return (
    <Query<WebhookFilters, WebhookFiltersVariables>
      query={filtersQuery}
      variables={{ first: 100 }}
    >
      {({ loading, data, error }) => (
        <FiltersList
          setAppliedFilters={setAppliedFilters}
          loading={loading}
          error={error}
          filters={
            data &&
            data.webhookTagKeysConnection &&
            data.webhookTagKeysConnection.edges &&
            data.webhookTagKeysConnection.edges.map(e => e!.node!)
          }
        />
      )}
    </Query>
  );
};

export default WithQuery;
