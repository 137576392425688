import { Node } from "./relay";
import { WebhookTag } from "./webhooks";

export interface StripeAccountNode extends Node {
  isAuthorized: boolean;
  livemode: boolean;
  name?: string;
  createdAt: string;
  stripeUserId: string;
}

export enum StripeWebhookEvent {
  ALL_EVENTS = "ALL_EVENTS",
  ACCOUNT__UPDATED = "ACCOUNT__UPDATED",
  ACCOUNT__APPLICATION__AUTHORIZED = "ACCOUNT__APPLICATION__AUTHORIZED",
  ACCOUNT__APPLICATION__DEAUTHORIZED = "ACCOUNT__APPLICATION__DEAUTHORIZED",
  ACCOUNT__EXTERNAL_ACCOUNT__CREATED = "ACCOUNT__EXTERNAL_ACCOUNT__CREATED",
  ACCOUNT__EXTERNAL_ACCOUNT__DELETED = "ACCOUNT__EXTERNAL_ACCOUNT__DELETED",
  ACCOUNT__EXTERNAL_ACCOUNT__UPDATED = "ACCOUNT__EXTERNAL_ACCOUNT__UPDATED",
  APPLICATION_FEE__CREATED = "APPLICATION_FEE__CREATED",
  APPLICATION_FEE__REFUNDED = "APPLICATION_FEE__REFUNDED",
  APPLICATION_FEE__REFUND__UPDATED = "APPLICATION_FEE__REFUND__UPDATED",
  BALANCE__AVAILABLE = "BALANCE__AVAILABLE",
  CAPABILITY__UPDATED = "CAPABILITY__UPDATED",
  CHARGE__CAPTURED = "CHARGE__CAPTURED",
  CHARGE__EXPIRED = "CHARGE__EXPIRED",
  CHARGE__FAILED = "CHARGE__FAILED",
  CHARGE__PENDING = "CHARGE__PENDING",
  CHARGE__REFUNDED = "CHARGE__REFUNDED",
  CHARGE__SUCCEEDED = "CHARGE__SUCCEEDED",
  CHARGE__UPDATED = "CHARGE__UPDATED",
  CHARGE__DISPUTE__CLOSED = "CHARGE__DISPUTE__CLOSED",
  CHARGE__DISPUTE__CREATED = "CHARGE__DISPUTE__CREATED",
  CHARGE__DISPUTE__FUNDS_REINSTATED = "CHARGE__DISPUTE__FUNDS_REINSTATED",
  CHARGE__DISPUTE__FUNDS_WITHDRAWN = "CHARGE__DISPUTE__FUNDS_WITHDRAWN",
  CHARGE__DISPUTE__UPDATED = "CHARGE__DISPUTE__UPDATED",
  CHARGE__REFUND__UPDATED = "CHARGE__REFUND__UPDATED",
  CHECKOUT__SESSION__COMPLETED = "CHECKOUT__SESSION__COMPLETED",
  COUPON__CREATED = "COUPON__CREATED",
  COUPON__DELETED = "COUPON__DELETED",
  COUPON__UPDATED = "COUPON__UPDATED",
  CREDIT_NOTE__CREATED = "CREDIT_NOTE__CREATED",
  CREDIT_NOTE__UPDATED = "CREDIT_NOTE__UPDATED",
  CREDIT_NOTE__VOIDED = "CREDIT_NOTE__VOIDED",
  CUSTOMER__CREATED = "CUSTOMER__CREATED",
  CUSTOMER__DELETED = "CUSTOMER__DELETED",
  CUSTOMER__UPDATED = "CUSTOMER__UPDATED",
  CUSTOMER__DISCOUNT__CREATED = "CUSTOMER__DISCOUNT__CREATED",
  CUSTOMER__DISCOUNT__DELETED = "CUSTOMER__DISCOUNT__DELETED",
  CUSTOMER__DISCOUNT__UPDATED = "CUSTOMER__DISCOUNT__UPDATED",
  CUSTOMER__SOURCE__CREATED = "CUSTOMER__SOURCE__CREATED",
  CUSTOMER__SOURCE__DELETED = "CUSTOMER__SOURCE__DELETED",
  CUSTOMER__SOURCE__EXPIRING = "CUSTOMER__SOURCE__EXPIRING",
  CUSTOMER__SOURCE__UPDATED = "CUSTOMER__SOURCE__UPDATED",
  CUSTOMER__SUBSCRIPTION__CREATED = "CUSTOMER__SUBSCRIPTION__CREATED",
  CUSTOMER__SUBSCRIPTION__DELETED = "CUSTOMER__SUBSCRIPTION__DELETED",
  CUSTOMER__SUBSCRIPTION__PENDING_UPDATE_APPLIED = "CUSTOMER__SUBSCRIPTION__PENDING_UPDATE_APPLIED",
  CUSTOMER__SUBSCRIPTION__PENDING_UPDATE_EXPIRED = "CUSTOMER__SUBSCRIPTION__PENDING_UPDATE_EXPIRED",
  CUSTOMER__SUBSCRIPTION__TRIAL_WILL_END = "CUSTOMER__SUBSCRIPTION__TRIAL_WILL_END",
  CUSTOMER__SUBSCRIPTION__UPDATED = "CUSTOMER__SUBSCRIPTION__UPDATED",
  CUSTOMER__TAX_ID__CREATED = "CUSTOMER__TAX_ID__CREATED",
  CUSTOMER__TAX_ID__DELETED = "CUSTOMER__TAX_ID__DELETED",
  CUSTOMER__TAX_ID__UPDATED = "CUSTOMER__TAX_ID__UPDATED",
  FILE__CREATED = "FILE__CREATED",
  INVOICE__CREATED = "INVOICE__CREATED",
  INVOICE__DELETED = "INVOICE__DELETED",
  INVOICE__FINALIZED = "INVOICE__FINALIZED",
  INVOICE__MARKED_UNCOLLECTIBLE = "INVOICE__MARKED_UNCOLLECTIBLE",
  INVOICE__PAYMENT_ACTION_REQUIRED = "INVOICE__PAYMENT_ACTION_REQUIRED",
  INVOICE__PAYMENT_FAILED = "INVOICE__PAYMENT_FAILED",
  INVOICE__PAYMENT_SUCCEEDED = "INVOICE__PAYMENT_SUCCEEDED",
  INVOICE__SENT = "INVOICE__SENT",
  INVOICE__UPCOMING = "INVOICE__UPCOMING",
  INVOICE__UPDATED = "INVOICE__UPDATED",
  INVOICE__VOIDED = "INVOICE__VOIDED",
  INVOICEITEM__CREATED = "INVOICEITEM__CREATED",
  INVOICEITEM__DELETED = "INVOICEITEM__DELETED",
  INVOICEITEM__UPDATED = "INVOICEITEM__UPDATED",
  ISSUING_AUTHORIZATION__CREATED = "ISSUING_AUTHORIZATION__CREATED",
  ISSUING_AUTHORIZATION__REQUEST = "ISSUING_AUTHORIZATION__REQUEST",
  ISSUING_AUTHORIZATION__UPDATED = "ISSUING_AUTHORIZATION__UPDATED",
  ISSUING_CARD__CREATED = "ISSUING_CARD__CREATED",
  ISSUING_CARD__UPDATED = "ISSUING_CARD__UPDATED",
  ISSUING_CARDHOLDER__CREATED = "ISSUING_CARDHOLDER__CREATED",
  ISSUING_CARDHOLDER__UPDATED = "ISSUING_CARDHOLDER__UPDATED",
  ISSUING_DISPUTE__CREATED = "ISSUING_DISPUTE__CREATED",
  ISSUING_DISPUTE__UPDATED = "ISSUING_DISPUTE__UPDATED",
  ISSUING_SETTLEMENT__CREATED = "ISSUING_SETTLEMENT__CREATED",
  ISSUING_SETTLEMENT__UPDATED = "ISSUING_SETTLEMENT__UPDATED",
  ISSUING_TRANSACTION__CREATED = "ISSUING_TRANSACTION__CREATED",
  ISSUING_TRANSACTION__UPDATED = "ISSUING_TRANSACTION__UPDATED",
  MANDATE__UPDATED = "MANDATE__UPDATED",
  ORDER__CREATED = "ORDER__CREATED",
  ORDER__PAYMENT_FAILED = "ORDER__PAYMENT_FAILED",
  ORDER__PAYMENT_SUCCEEDED = "ORDER__PAYMENT_SUCCEEDED",
  ORDER__UPDATED = "ORDER__UPDATED",
  ORDER_RETURN__CREATED = "ORDER_RETURN__CREATED",
  PAYMENT_INTENT__AMOUNT_CAPTURABLE_UPDATED = "PAYMENT_INTENT__AMOUNT_CAPTURABLE_UPDATED",
  PAYMENT_INTENT__CANCELED = "PAYMENT_INTENT__CANCELED",
  PAYMENT_INTENT__CREATED = "PAYMENT_INTENT__CREATED",
  PAYMENT_INTENT__PAYMENT_FAILED = "PAYMENT_INTENT__PAYMENT_FAILED",
  PAYMENT_INTENT__SUCCEEDED = "PAYMENT_INTENT__SUCCEEDED",
  PAYMENT_METHOD__ATTACHED = "PAYMENT_METHOD__ATTACHED",
  PAYMENT_METHOD__CARD_AUTOMATICALLY_UPDATED = "PAYMENT_METHOD__CARD_AUTOMATICALLY_UPDATED",
  PAYMENT_METHOD__DETACHED = "PAYMENT_METHOD__DETACHED",
  PAYMENT_METHOD__UPDATED = "PAYMENT_METHOD__UPDATED",
  PAYOUT__CANCELED = "PAYOUT__CANCELED",
  PAYOUT__CREATED = "PAYOUT__CREATED",
  PAYOUT__FAILED = "PAYOUT__FAILED",
  PAYOUT__PAID = "PAYOUT__PAID",
  PAYOUT__UPDATED = "PAYOUT__UPDATED",
  PERSON__CREATED = "PERSON__CREATED",
  PERSON__DELETED = "PERSON__DELETED",
  PERSON__UPDATED = "PERSON__UPDATED",
  PLAN__CREATED = "PLAN__CREATED",
  PLAN__DELETED = "PLAN__DELETED",
  PLAN__UPDATED = "PLAN__UPDATED",
  PRODUCT__CREATED = "PRODUCT__CREATED",
  PRODUCT__DELETED = "PRODUCT__DELETED",
  PRODUCT__UPDATED = "PRODUCT__UPDATED",
  RADAR__EARLY_FRAUD_WARNING__CREATED = "RADAR__EARLY_FRAUD_WARNING__CREATED",
  RADAR__EARLY_FRAUD_WARNING__UPDATED = "RADAR__EARLY_FRAUD_WARNING__UPDATED",
  RECIPIENT__CREATED = "RECIPIENT__CREATED",
  RECIPIENT__DELETED = "RECIPIENT__DELETED",
  RECIPIENT__UPDATED = "RECIPIENT__UPDATED",
  REPORTING__REPORT_RUN__FAILED = "REPORTING__REPORT_RUN__FAILED",
  REPORTING__REPORT_RUN__SUCCEEDED = "REPORTING__REPORT_RUN__SUCCEEDED",
  REPORTING__REPORT_TYPE__UPDATED = "REPORTING__REPORT_TYPE__UPDATED",
  REVIEW__CLOSED = "REVIEW__CLOSED",
  REVIEW__OPENED = "REVIEW__OPENED",
  SETUP_INTENT__CANCELED = "SETUP_INTENT__CANCELED",
  SETUP_INTENT__CREATED = "SETUP_INTENT__CREATED",
  SETUP_INTENT__SETUP_FAILED = "SETUP_INTENT__SETUP_FAILED",
  SETUP_INTENT__SUCCEEDED = "SETUP_INTENT__SUCCEEDED",
  SIGMA__SCHEDULED_QUERY_RUN__CREATED = "SIGMA__SCHEDULED_QUERY_RUN__CREATED",
  SKU__CREATED = "SKU__CREATED",
  SKU__DELETED = "SKU__DELETED",
  SKU__UPDATED = "SKU__UPDATED",
  SOURCE__CANCELED = "SOURCE__CANCELED",
  SOURCE__CHARGEABLE = "SOURCE__CHARGEABLE",
  SOURCE__FAILED = "SOURCE__FAILED",
  SOURCE__MANDATE_NOTIFICATION = "SOURCE__MANDATE_NOTIFICATION",
  SOURCE__REFUND_ATTRIBUTES_REQUIRED = "SOURCE__REFUND_ATTRIBUTES_REQUIRED",
  SOURCE__TRANSACTION__CREATED = "SOURCE__TRANSACTION__CREATED",
  SOURCE__TRANSACTION__UPDATED = "SOURCE__TRANSACTION__UPDATED",
  SUBSCRIPTION_SCHEDULE__ABORTED = "SUBSCRIPTION_SCHEDULE__ABORTED",
  SUBSCRIPTION_SCHEDULE__CANCELED = "SUBSCRIPTION_SCHEDULE__CANCELED",
  SUBSCRIPTION_SCHEDULE__COMPLETED = "SUBSCRIPTION_SCHEDULE__COMPLETED",
  SUBSCRIPTION_SCHEDULE__CREATED = "SUBSCRIPTION_SCHEDULE__CREATED",
  SUBSCRIPTION_SCHEDULE__EXPIRING = "SUBSCRIPTION_SCHEDULE__EXPIRING",
  SUBSCRIPTION_SCHEDULE__RELEASED = "SUBSCRIPTION_SCHEDULE__RELEASED",
  SUBSCRIPTION_SCHEDULE__UPDATED = "SUBSCRIPTION_SCHEDULE__UPDATED",
  TAX_RATE__CREATED = "TAX_RATE__CREATED",
  TAX_RATE__UPDATED = "TAX_RATE__UPDATED",
  TOPUP__CANCELED = "TOPUP__CANCELED",
  TOPUP__CREATED = "TOPUP__CREATED",
  TOPUP__FAILED = "TOPUP__FAILED",
  TOPUP__REVERSED = "TOPUP__REVERSED",
  TOPUP__SUCCEEDED = "TOPUP__SUCCEEDED",
  TRANSFER__CREATED = "TRANSFER__CREATED",
  TRANSFER__FAILED = "TRANSFER__FAILED",
  TRANSFER__PAID = "TRANSFER__PAID",
  TRANSFER__REVERSED = "TRANSFER__REVERSED",
  TRANSFER__UPDATED = "TRANSFER__UPDATED"
}

export const webhookEventToStripeName = (event: string): string => {
  return event === "ALL_EVENTS"
    ? "All Events (*)"
    : event.replace(/__/g, ".").toLowerCase();
};

export interface StripeWebhookNode extends Node {
  secret: string;
  url: string;
  events: StripeWebhookEvent[];
  name: string;
  createdAt: string;
  updatedAt: string;
  account: {
    id: string;
    name: string;
    stripeUserId: string;
  };
  tags: WebhookTag[];
}
