import React, { useState } from "react";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { RemoveUserVariables, RemoveUser } from "../../graphql/types";
import { ApolloError } from "apollo-boost";
import Button from "react-bootstrap/Button";
import ConfirmDialog from "../../components/Dialog/ConfirmDialog";
import { toast } from "react-toastify";

interface LinkProps {
  deleteUser: () => Promise<any>;
  loading: boolean;
  error?: ApolloError;
}

const DeleteUserLink: React.FC<LinkProps> = ({
  deleteUser,
  loading,
  error
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <>
      <Button
        variant="link"
        className="text-danger p-0"
        onClick={() => setShowModal(true)}
      >
        Remove user
      </Button>
      <ConfirmDialog
        variant="danger"
        actionText="Delete"
        show={showModal}
        title="Are you sure you want to delete this user?"
        text="Deleting this user is permanent. They will no longer have access to HookActions."
        loading={loading}
        error={error}
        handleCancel={() => setShowModal(false)}
        handleAction={() =>
          deleteUser().then(() => {
            setShowModal(false);
            toast("User deleted.", { type: "success" });
          })
        }
      />
    </>
  );
};

const MUTATION = gql`
  mutation RemoveUser($id: ID!) {
    removeUserFromOrganization(id: $id)
  }
`;

interface Props {
  userId: string;
}

const WithMutation: React.FC<Props> = ({ userId: id }) => (
  <Mutation<RemoveUser, RemoveUserVariables> mutation={MUTATION}>
    {(mutate, { loading, error }) => (
      <DeleteUserLink
        deleteUser={() => mutate({ variables: { id } })}
        loading={loading}
        error={error}
      />
    )}
  </Mutation>
);

export default WithMutation;
