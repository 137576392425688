import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import { HasError } from "../FormError";
import { ApolloError } from "apollo-boost";
import "./edit-table.scss";
import StringAutoSuggestField from "./StringAutosuggestField";

export interface Column {
  name: string;
  inputName: string;
  placeholder?: string;
  autoSuggestions?: string[];
  getAutoSuggestions?: (rowValues: Value[]) => string[];
}

interface EditTableProps {
  columns: Column[];
  name: string;
  error?: ApolloError;
  onDataChange: (newValues: Value[][]) => void;
}

export interface Value {
  inputName: string;
  value: string;
}

interface EditTableState {
  data: Value[][];
}

// TODO: in the future, add auto-complete functionality for k/v
const EditTable: React.FC<EditTableProps> = ({
  columns,
  name,
  error,
  onDataChange
}) => {
  const [state, setState] = useState<EditTableState>({ data: [] });

  const newRowGenerator = () => {
    return columns.map(col => ({ inputName: col.inputName, value: "" }));
  };

  useEffect(() => {
    if (state.data.length === 0) {
      setState({ data: [newRowGenerator()] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => onDataChange(state.data), [state]); // eslint-disable-line react-hooks/exhaustive-deps

  const addTagClick = () => {
    setState({ data: [...state.data, newRowGenerator()] });
  };
  const makeRowRemover = (row: number) => () => {
    if (state.data.length === 1) {
      setState({ data: [newRowGenerator()] });
    } else {
      const newData = [...state.data.filter((_, i) => i !== row)];
      setState({ data: newData });
    }
  };
  const makeValueChanger = (rowIdx: number, colIdx: number) => (e: any) => {
    const newValue = typeof e === "string" ? e : e.target.value;
    const newData = [...state.data];
    newData[rowIdx][colIdx].value = newValue;
    setState({ data: newData });
  };

  return (
    <>
      <Table borderless={true}>
        <thead>
          <tr>
            {columns.map(col => (
              <th key={col.inputName}>{col.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {state.data.map((row, i) => (
            <tr key={`${name}[${i}]`}>
              {columns.map((col, colIdx) => (
                <td key={`${i}-${col.inputName}`}>
                  {col.autoSuggestions && (
                    <StringAutoSuggestField
                      suggestions={col.autoSuggestions}
                      name={`${name}[${i}].${col.inputName}`}
                      placeholder={col.placeholder}
                      value={row[colIdx].value}
                      onChange={makeValueChanger(i, colIdx)}
                    />
                  )}
                  {col.getAutoSuggestions && (
                    <StringAutoSuggestField
                      suggestions={col.getAutoSuggestions(row)}
                      name={`${name}[${i}].${col.inputName}`}
                      placeholder={col.placeholder}
                      value={row[colIdx].value}
                      onChange={makeValueChanger(i, colIdx)}
                    />
                  )}
                  {!col.autoSuggestions && !col.getAutoSuggestions && (
                    <Form.Control
                      type="text"
                      name={`${name}[${i}].${col.inputName}`}
                      placeholder={col.placeholder}
                      value={row[colIdx].value}
                      onChange={makeValueChanger(i, colIdx)}
                      isInvalid={HasError(
                        error,
                        `${name}[${i}].${col.inputName}`
                      )}
                    />
                  )}
                </td>
              ))}
              <td className="align-middle">
                <span className="remove-table-row" onClick={makeRowRemover(i)}>
                  <FontAwesomeIcon icon={faTimesCircle} />
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Button
        size="sm"
        type="button"
        variant="outline-primary"
        onClick={addTagClick}
      >
        <FontAwesomeIcon icon={faPlus} />
        &nbsp;Add tag
      </Button>
    </>
  );
};

export default EditTable;
