import React from "react";
import { ApolloError } from "apollo-client";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";

interface LinkProps {
  resendInvitation: () => Promise<any>;
  loading: boolean;
  error?: ApolloError;
  data?: Response;
}

const ResendActivationLink: React.FC<LinkProps> = ({
  resendInvitation,
  loading,
  error
}) => {
  const onClick = () => {
    if (loading) {
      return;
    }

    resendInvitation()
      .then(() => {
        toast("Invitation resent", { type: "success" });
      })
      .catch(() => {
        console.log(error);
        toast("Unable to send invitation", { type: "error" });
      });
  };

  return (
    <Button variant="link" className="p-0" disabled={loading} onClick={onClick}>
      Resend invitation
    </Button>
  );
};

const MUTATION = gql`
  mutation ResendActivationLink($userId: ID!) {
    resendUserInvitation(userId: $userId)
  }
`;

interface Props {
  userId: string;
}

interface Response {
  resendUserInvitation: boolean;
}

interface Vars {
  userId: string;
}

const WithMutation: React.FC<Props> = ({ userId }) => {
  return (
    <Mutation<Response, Vars> mutation={MUTATION}>
      {(mutate, { loading, error, data }) => (
        <ResendActivationLink
          resendInvitation={() => mutate({ variables: { userId } })}
          loading={loading}
          error={error}
          data={data}
        />
      )}
    </Mutation>
  );
};

export default WithMutation;
