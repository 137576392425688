import React from "react";
import useBodyClass from "../../utils/useBodyClass";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Helmet from "react-helmet";
import { Link, Redirect } from "react-router-dom";
import HeaderLoggedOut from "../../components/HeaderLoggedOut";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import useForm from "react-hook-form";
import { extractValidationErrors } from "../../utils/graphql";
import FormError, { HasError } from "../../components/FormError";
import StatusEventsBanner from "../../components/StatusEvents";
import { RBRef } from "../../components/form/types";

const LOGIN = gql`
  mutation Login($emailAddress: String!, $password: String!) {
    createAccessToken(
      input: { emailAddress: $emailAddress, password: $password }
    ) {
      token
      expiration
    }
  }
`;

interface LoginVars {
  emailAddress: string;
  password: string;
}

interface LoginResponse {
  createAccessToken?: {
    token: string;
    expiration: Date;
  };
}

interface Props {
  setUserToken: (token: string) => void;
}

const LoginPage: React.FC<Props> = (props: Props) => {
  const { setUserToken } = props;
  const { register, handleSubmit } = useForm();

  useBodyClass("bg-gradient-primary");

  const onCompleted = (data: LoginResponse) => {
    if (data.createAccessToken) {
      setUserToken(data.createAccessToken.token);
    }
  };

  return (
    <Mutation<LoginResponse, LoginVars>
      mutation={LOGIN}
      onCompleted={onCompleted}
    >
      {(login, { data, loading, error }) => {
        const validationErrors = extractValidationErrors(error);

        const onSubmit = (data: Record<string, any>) => {
          login({
            variables: {
              emailAddress: data.emailAddress,
              password: data.password
            }
          });
        };

        if (data && data.createAccessToken) {
          const params = new URLSearchParams(window.location.search);
          const to = params.get("next") || "/webhooks";

          return <Redirect to={to} />;
        }

        return (
          <Container>
            <Helmet>
              <title>Login – HookActions</title>
            </Helmet>

            <HeaderLoggedOut />

            <Row className="justify-content-center">
              <Col xl="10" lg="12" md="9">
                <Card className="o-hidden border-0 shadow-lg my-5">
                  <Card.Body className="p-0">
                    <Row>
                      <Col lg="6" className="d-lg-lbock p-5">
                        <img
                          src="/img/authentication.svg"
                          alt="login"
                          style={{ width: "100%" }}
                        />
                      </Col>
                      <Col lg="6">
                        <div className="p-5">
                          <StatusEventsBanner />
                          <div className="text-center">
                            <h1 className="h4 text-gray-900 mb-4">
                              Welcome Back!
                            </h1>
                          </div>
                          <Form
                            className="user"
                            onSubmit={handleSubmit(onSubmit)}
                          >
                            <Form.Group>
                              <Form.Control
                                type="email"
                                className="form-control-user"
                                id="emailAddress"
                                name="emailAddress"
                                aria-describedby="emailHelp"
                                placeholder="Email Address"
                                ref={register({ required: true }) as RBRef}
                                disabled={loading}
                                isInvalid={HasError(
                                  validationErrors,
                                  "email_address"
                                )}
                              />
                              <FormError
                                errors={validationErrors}
                                fieldName="email_address"
                              />
                            </Form.Group>
                            <Form.Group>
                              <Form.Control
                                type="password"
                                className="form-control-user"
                                id="password"
                                name="password"
                                placeholder="Password"
                                ref={register({ required: true }) as RBRef}
                                disabled={loading}
                                isInvalid={HasError(
                                  validationErrors,
                                  "password"
                                )}
                              />
                              <FormError
                                errors={validationErrors}
                                fieldName="password"
                              />
                            </Form.Group>

                            <Button
                              block={true}
                              className="btn-user"
                              type="submit"
                              disabled={loading}
                            >
                              Login
                            </Button>
                          </Form>
                          <hr />
                          <div className="text-center">
                            <Link className="small" to="/forgot-password">
                              Forgot Password?
                            </Link>
                          </div>
                          <div className="text-center">
                            <Link className="small" to="/register">
                              Create an Account!
                            </Link>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        );
      }}
    </Mutation>
  );
};

export default LoginPage;
