import React from "react";
import useBodyClass from "../../utils/useBodyClass";
import Container from "react-bootstrap/Container";
import Helmet from "react-helmet";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Link, Redirect } from "react-router-dom";
import HeaderLoggedOut from "../../components/HeaderLoggedOut";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { ApolloError } from "apollo-boost";
import useForm from "react-hook-form";
import FormError, { HasError } from "../../components/FormError";
import Spinner from "react-bootstrap/Spinner";
import { RBRef } from "../../components/form/types";

interface Props {
  mutate: (emailAddress: string) => Promise<any>;
  loading: boolean;
  data?: ForgotPasswordResponse;
  error?: ApolloError;
}

const ForgotPasswordPage: React.FC<Props> = props => {
  const { mutate, loading, data, error } = props;
  const { handleSubmit, register } = useForm();
  useBodyClass("bg-gradient-primary");

  const onSubmit = (data: Record<string, any>) => mutate(data.emailAddress);

  if (data && data.requestPasswordReset === true) {
    return <Redirect to="/forgot-password/complete" />;
  }

  return (
    <Container>
      <Helmet>
        <title>Forgot Password – HookActions</title>
      </Helmet>

      <HeaderLoggedOut />

      <Row className="justify-content-center">
        <Col xl="10" lg="12" md="9">
          <Card className="o-hidden border-0 shadow-lg my-5">
            <Card.Body className="p-0">
              <Row>
                <Col lg="6" className="d-lg-block p-5">
                  <img
                    className="img-fluid"
                    src="/img/forgot_password.svg"
                    alt="forgot password"
                  />
                </Col>
                <Col lg="6">
                  <div className="p-5">
                    <div className="text-center">
                      <h1 className="h4 text-gray-900 mb-2">
                        Forgot Your Password?
                      </h1>
                      <p className="mb-4">
                        We get it, stuff happens. Just enter your email address
                        below and we'll send you a link to reset your password!
                      </p>
                    </div>
                    <Form className="user" onSubmit={handleSubmit(onSubmit)}>
                      <Form.Group>
                        <Form.Control
                          type="email"
                          name="emailAddress"
                          placeholder="Email Address"
                          className="form-control-user"
                          ref={register({ required: true }) as RBRef}
                          disabled={loading}
                          isInvalid={HasError(error, "email_address")}
                        />
                        <FormError errors={error} fieldName="email_address" />
                      </Form.Group>
                      <Button
                        className="btn-user"
                        block={true}
                        type="submit"
                        disabled={loading}
                      >
                        <span>Reset Password</span>
                        {loading && (
                          <Spinner
                            className="ml-2"
                            animation="border"
                            size="sm"
                          />
                        )}
                      </Button>
                    </Form>
                    <hr />
                    <div className="text-center">
                      <Link className="small" to="/register">
                        Create an Account!
                      </Link>
                    </div>
                    <div className="text-center">
                      <Link className="small" to="/login">
                        Already have an account? Login!
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($emailAddress: String!) {
    requestPasswordReset(emailAddress: $emailAddress)
  }
`;

interface ForgotPasswordVars {
  emailAddress: string;
}

interface ForgotPasswordResponse {
  requestPasswordReset: boolean;
}

const WithMutation: React.FC = () => {
  return (
    <Mutation<ForgotPasswordResponse, ForgotPasswordVars>
      mutation={FORGOT_PASSWORD}
    >
      {(mutate, { data, loading, error }) => (
        <ForgotPasswordPage
          mutate={emailAddress => mutate({ variables: { emailAddress } })}
          loading={loading}
          data={data}
          error={error}
        />
      )}
    </Mutation>
  );
};

export default WithMutation;
