import React from "react";
import useBodyClass from "../../utils/useBodyClass";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Link, Redirect } from "react-router-dom";
import Helmet from "react-helmet";
import HeaderLoggedOut from "../../components/HeaderLoggedOut";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import useForm from "react-hook-form";
import { ApolloError } from "apollo-boost";
import FormError, { HasError } from "../../components/FormError";
import { toast } from "react-toastify";
import StatusEventsBanner from "../../components/StatusEvents";
import { RBRef } from "../../components/form/types";
import AccountCreationLegalease from "../../components/form/AccountCreationLegalease";

interface Props {
  registerUser: (vars: RegisterVars) => Promise<any>;
  loading: boolean;
  error?: ApolloError;
  data?: boolean;
  emailAddress?: string;
}

const RegisterPage: React.FC<Props> = ({
  registerUser,
  loading,
  error,
  data,
  emailAddress
}) => {
  useBodyClass("bg-gradient-primary");

  const { register, handleSubmit } = useForm();

  const onSubmit = (values: Record<string, any>) =>
    registerUser({
      emailAddress: values.emailAddress,
      firstName: values.firstName,
      lastName: values.lastName,
      password: values.password
    });

  if (!loading && data) {
    if (emailAddress) {
      // pre-registered user
      toast("You're all set! Login now.", { type: "success" });
      return <Redirect to="/login" />;
    }

    return <Redirect to="/register/complete" />;
  }

  return (
    <Container>
      <Helmet>
        <title>Register – HookActions</title>
      </Helmet>

      <HeaderLoggedOut />

      <Card className="o-hidden border-0 shadow-lg my-5">
        <Card.Body className="p-0">
          <Row>
            <Col lg="5" className="d-lg-block">
              <img
                src="/img/register.svg"
                alt="register"
                className="p-5"
                style={{ width: "100%" }}
              />
            </Col>
            <Col lg="7">
              <div className="p-5">
                <StatusEventsBanner />
                <div className="text-center">
                  <h1 className="h4 text-gray-900 mb-4">Create an Account!</h1>
                </div>
                <Form className="user" onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group className="row">
                    <Col sm="6" className="mb-3 mb-sm-0">
                      <Form.Control
                        className="form-control-user"
                        type="name"
                        placeholder="First Name"
                        name="firstName"
                        ref={register({ required: true }) as RBRef}
                        disabled={loading}
                        isInvalid={HasError(error, "first_name")}
                      />
                      <FormError errors={error} fieldName="first_name" />
                    </Col>
                    <Col sm="6">
                      <Form.Control
                        className="form-control-user"
                        type="name"
                        placeholder="Last Name"
                        name="lastName"
                        ref={register({ required: true }) as RBRef}
                        disabled={loading}
                        isInvalid={HasError(error, "last_name")}
                      />
                      <FormError errors={error} fieldName="last_name" />
                    </Col>
                  </Form.Group>
                  <Form.Group>
                    <Form.Control
                      className="form-control-user"
                      type="email"
                      placeholder="Email Address"
                      name="emailAddress"
                      defaultValue={emailAddress}
                      ref={register({ required: true }) as RBRef}
                      disabled={loading}
                      isInvalid={HasError(error, "email_address")}
                    />
                    <FormError errors={error} fieldName="email_address" />
                  </Form.Group>
                  <Form.Group>
                    <Form.Control
                      className="form-control-user"
                      type="password"
                      placeholder="Password"
                      name="password"
                      ref={register({ required: true }) as RBRef}
                      disabled={loading}
                      isInvalid={HasError(error, "password")}
                    />
                    <FormError errors={error} fieldName="password" />
                  </Form.Group>
                  <Button
                    block={true}
                    className="btn-user"
                    type="submit"
                    disabled={loading}
                  >
                    Register
                  </Button>

                  <AccountCreationLegalease className="mt-2 text-center" />
                </Form>
                <hr />
                <div className="text-center">
                  <Link className="small" to="/forgot-password">
                    Forgot Password?
                  </Link>
                </div>
                <div className="text-center">
                  <Link className="small" to="/login">
                    Already have an account? Login!
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

const REGISTER = gql`
  mutation RegisterUser(
    $emailAddress: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $preRegistrationUserId: ID
  ) {
    registerUser(
      input: {
        emailAddress: $emailAddress
        password: $password
        firstName: $firstName
        lastName: $lastName
        preRegistrationUserId: $preRegistrationUserId
      }
    )
  }
`;

interface RegisterVars {
  emailAddress: string;
  password: string;
  firstName: string;
  lastName: string;
  preRegistrationUserId?: string;
}

interface RegisterResponse {
  registerUser: boolean;
}

interface WithMutationProps {
  emailAddress?: string;
  preRegistrationUserId?: string;
}

const WithMutation: React.FC<WithMutationProps> = ({
  emailAddress,
  preRegistrationUserId
}) => {
  return (
    <Mutation<RegisterResponse, RegisterVars> mutation={REGISTER}>
      {(mutate, { data, loading, error }) => (
        <RegisterPage
          registerUser={variables =>
            mutate({ variables: { ...variables, preRegistrationUserId } })
          }
          loading={loading}
          error={error}
          data={data && data.registerUser}
          emailAddress={emailAddress}
        />
      )}
    </Mutation>
  );
};

export default WithMutation;
