import React, { useState } from "react";
import { webhookEventToCalendlyName } from "../../../graphql/calendly";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { Redirect } from "react-router";
import { toast } from "react-toastify";
import { ApolloError } from "apollo-boost";
import useForm from "react-hook-form";
import Form from "react-bootstrap/Form";
import FormError, { HasError } from "../../../components/FormError";
import CalendlyAccountSelect from "./CalendlyAccountSelect";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import TagEditor from "../../../components/form/tags/TagEditorWithQuery";
import GenericError from "../../../components/GenericError";
import { RBRef } from "../../../components/form/types";
import {
  CalendlyEvent as CalendlyWebhookEvent,
  CreateCalendlyWebhookVariables,
  CreateCalendlyWebhook
} from "../../../graphql/types";

interface Props {
  loading: boolean;
  error?: ApolloError;
  createWebhook: (variables: CreateCalendlyWebhookVariables) => void;
}

const CalendlyWebhookForm: React.FC<Props> = props => {
  const [tags, setTags] = useState();
  const { register, handleSubmit } = useForm();
  const { error, loading, createWebhook } = props;

  const onSubmit = (record: Record<string, any>) =>
    createWebhook({
      accountId: record.accountId,
      url: record.url,
      events: record.events,
      tags
    });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <GenericError error={error} isForm={true} />

      <CalendlyAccountSelect register={register} />
      <Form.Group>
        <Form.Label htmlFor="events">Events</Form.Label>
        <Form.Control
          as="select"
          multiple={true}
          ref={register({ required: true }) as RBRef}
          name="events"
          isInvalid={HasError(error, "events")}
        >
          {Object.keys(CalendlyWebhookEvent).map(event => (
            <option value={event} key={event}>
              {webhookEventToCalendlyName(event)}
            </option>
          ))}
        </Form.Control>
        <Form.Text>Select multiple with shift, ctrl, or cmd.</Form.Text>
        <FormError errors={error} fieldName="events" />
      </Form.Group>
      <Form.Group>
        <Form.Label htmlFor="url">URL</Form.Label>
        <Form.Control
          type="text"
          placeholder="e.g. https://example.com/my/webhook"
          name="url"
          ref={register({ required: true }) as RBRef}
          isInvalid={HasError(error, "url")}
        />
        <FormError errors={error} fieldName="url" />
      </Form.Group>
      <Form.Group>
        <Form.Label htmlFor="tags">Tags</Form.Label>
        <Form.Text>
          <strong>Pro tip:</strong> add a "name" tag which will be used in the
          webhooks table.
        </Form.Text>
        <TagEditor
          name="tags"
          setValue={(_, tags) => setTags(tags)}
          existingTags={[]}
          mutationError={error}
        />
      </Form.Group>

      <Button type="submit" block={true} variant="primary" disabled={loading}>
        <span>Create webhook</span>
        {loading && <Spinner animation="border" className="ml-2" size="sm" />}
      </Button>
    </Form>
  );
};

const CREATE_WEBHOOK = gql`
  mutation CreateCalendlyWebhook(
    $accountId: ID!
    $events: [CalendlyEvent!]!
    $url: String!
    $tags: [TagInput!]
  ) {
    createCalendlyWebhook(
      input: { accountId: $accountId, url: $url, events: $events, tags: $tags }
    ) {
      id
      createdAt
      updatedAt
      tags {
        key
        value
      }
      account {
        id
        name
      }
      url
      events
    }
  }
`;

const WithMutation: React.FC = () => (
  <Mutation<CreateCalendlyWebhook, CreateCalendlyWebhookVariables>
    mutation={CREATE_WEBHOOK}
  >
    {(mutate, { loading, error, data }) => {
      if (
        !loading &&
        !error &&
        data &&
        data.createCalendlyWebhook &&
        data.createCalendlyWebhook.id
      ) {
        toast("Webhook created", { type: "success" });
        return <Redirect to="/webhooks" />;
      }

      return (
        <CalendlyWebhookForm
          loading={loading}
          error={error}
          createWebhook={variables => mutate({ variables })}
        />
      );
    }}
  </Mutation>
);

export default WithMutation;
