import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faClipboard } from "@fortawesome/free-solid-svg-icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import clsx from "clsx";

const HiddenText: React.FC<{ value: string }> = ({ value }) => {
  const [show, setShow] = useState(false);
  const [copied, setCopied] = useState(false);

  const onCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  if (show) {
    return (
      <>
        <span className="mr-2">{value}</span>
        <CopyToClipboard text={value} onCopy={onCopy}>
          <FontAwesomeIcon icon={faClipboard} className="clickable" />
        </CopyToClipboard>
        <span
          className={clsx("ml-2 text-success d-none", { "d-inline": copied })}
        >
          Copied!
        </span>
      </>
    );
  }

  return (
    <span onClick={() => setShow(true)} className="clickable font-italic">
      <FontAwesomeIcon icon={faLock} className="mr-2" />
      Click to show value
    </span>
  );
};

export default HiddenText;
