import React from "react";
import { SalesforceWebhookNode } from "../../../graphql/salesforce";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import TimeRenderer from "../../../components/TimeRenderer";
import HiddenText from "../../../components/HiddenText";
import { ApolloError } from "apollo-boost";
import CenteredSpinner from "../../../components/CenteredSpinner";
import GenericError from "../../../components/GenericError";
import { TagList } from "../../../components/renderers/tags";

interface Props {
  webhook?: SalesforceWebhookNode;
  show: boolean;
  onHide: () => void;
  loading?: boolean;
  error?: ApolloError;
}

const SalesforceWebhookDetailModal: React.FC<Props> = ({
  webhook,
  show,
  onHide,
  loading,
  error
}) => {
  if (!webhook || !show) {
    return null;
  }

  const isLoading: boolean = loading == null ? false : loading;

  return (
    <Modal size="lg" show={true} onHide={onHide}>
      <Modal.Header closeButton={true}>
        <h4>{webhook.name}</h4>
      </Modal.Header>
      <Modal.Body>
        {isLoading && <CenteredSpinner />}
        {error && <GenericError error={error} />}
        {!isLoading && !error && (
          <>
            <h3>Details</h3>
            <Table className="kv">
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>{webhook.name}</td>
                </tr>
                <tr>
                  <td>URL</td>
                  <td>{webhook.url}</td>
                </tr>
                <tr>
                  <td>HMAC header</td>
                  <td>{webhook.hmacHeaderName}</td>
                </tr>
                <tr>
                  <td>HMAC Key</td>
                  <td>
                    <HiddenText value={webhook.hmacKey} />
                  </td>
                </tr>
                <tr>
                  <td>SObject</td>
                  <td>{webhook.object.name}</td>
                </tr>
                <tr>
                  <td>Events</td>
                  <td>{webhook.events.join(", ")}</td>
                </tr>
                <tr>
                  <td>Tags</td>
                  <td>
                    <TagList tags={webhook.tags} />
                  </td>
                </tr>
                <tr>
                  <td>Created At</td>
                  <td>
                    <TimeRenderer cell={webhook.createdAt} />
                  </td>
                </tr>
                <tr>
                  <td>Updated At</td>
                  <td>
                    <TimeRenderer cell={webhook.updatedAt} />
                  </td>
                </tr>
              </tbody>
            </Table>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SalesforceWebhookDetailModal;
