import React from "react";
import { AppContext } from "../App";

interface Props {
  subject: string;
  message: string;
  children?: React.ReactNode;
}

export const getContactUsLink = (
  subject: string,
  message: string,
  userId?: string
) => {
  const params = new URLSearchParams();
  params.set("subject", subject);
  params.set("body", message + `\n\nuser id: ${userId || "not set"}`);

  return `mailto:${process.env.REACT_APP_SUPPORT_EMAIL}?${params.toString()}`;
};

const ContactUs: React.FC<Props> = (props: Props) => {
  return (
    <AppContext.Consumer>
      {({ userId }) => {
        const { subject, message, children = "Contact us" } = props;
        const link = getContactUsLink(subject, message, userId);

        return <a href={link}>{children}</a>;
      }}
    </AppContext.Consumer>
  );
};

export default ContactUs;
