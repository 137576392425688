import React from "react";
import gql from "graphql-tag";
import { Connection, ConnectionQueryVars } from "../../../graphql/relay";
import { WebhookTagKeyNode } from "../../../graphql/webhooks";
import { Query } from "react-apollo";
import TagEditor, { TagEditorProps } from "./TagEditor";

const TAGS_QUERY = gql`
  query AvailableTagsQuery($first: Int, $before: String, $after: String) {
    webhookTagKeysConnection(first: $first, before: $before, after: $after) {
      edges {
        cursor
        node {
          id
          name
          values {
            id
            value
          }
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

type Response = {
  webhookTagKeysConnection?: Connection<WebhookTagKeyNode>;
};
const maxTags = 200; // TODO: paginate

type ExcludeVals = "loading" | "error" | "autoFillTags";

const WithQuery: React.FC<Omit<TagEditorProps, ExcludeVals>> = props => {
  return (
    <Query<Response, ConnectionQueryVars>
      query={TAGS_QUERY}
      variables={{ first: maxTags }}
    >
      {({ data, loading, error }) => {
        return (
          <TagEditor
            loading={loading}
            queryError={error}
            autoFillTags={
              data &&
              data.webhookTagKeysConnection &&
              data.webhookTagKeysConnection.edges
                ? data.webhookTagKeysConnection.edges.map(e => e.node!)
                : []
            }
            {...props}
          />
        );
      }}
    </Query>
  );
};

export default WithQuery;
