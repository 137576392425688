import React, { useState } from "react";
import gql from "graphql-tag";
import { CalendlyAccountNode } from "../../../graphql/calendly";
import TimeRenderer from "../../../components/TimeRenderer";
import { Link } from "react-router-dom";
import { Column } from "../../../components/Table";
import Table from "../../../components/GraphQLTable";
import Button from "react-bootstrap/Button";
import { Mutation } from "react-apollo";
import ConfirmDialog from "../../../components/Dialog/ConfirmDialog";
import { ApolloError } from "apollo-boost";
import { toast } from "react-toastify";

const CALENDLY_ACCOUNTS = gql`
  query CalendlyAccounts($first: Int, $after: String, $before: String) {
    calendlyAccountsConnection(first: $first, after: $after, before: $before) {
      edges {
        node {
          id
          createdAt
          updatedAt
          name
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

interface Props {
  deleteAccount: (id: string) => Promise<any>;
  deleteInProgress: boolean;
  error?: ApolloError;
}

interface DeleteModalState {
  show: boolean;
  accountId?: string;
}

const CalendlyAccountsTable: React.FC<Props> = ({
  deleteAccount,
  deleteInProgress,
  error
}) => {
  const [deleteModalState, setDeleteModalState] = useState<DeleteModalState>({
    show: false
  });
  const columns: Column<CalendlyAccountNode>[] = [
    {
      key: "id",
      hidden: true
    },
    {
      key: "name",
      label: "Name"
    },
    {
      key: "createdAt",
      label: "Created at",
      renderer: TimeRenderer
    },
    {
      key: "id",
      // TODO: link to specific account
      renderer: () => <Link to={`/webhooks`}>View webhooks</Link>
    },
    {
      key: "id",
      renderer: ({ cell }) => (
        <Button
          variant="link"
          className="text-danger p-0"
          onClick={() => setDeleteModalState({ show: true, accountId: cell })}
        >
          Delete
        </Button>
      )
    }
  ];

  return (
    <>
      <Table<CalendlyAccountNode>
        query={CALENDLY_ACCOUNTS}
        name="calendlyAccountsConnection"
        columns={columns}
      />
      <ConfirmDialog
        variant="danger"
        actionText="Delete"
        loading={deleteInProgress}
        text={
          <>
            <p>Are you sure you want to delete this Calendly account?</p>
            <p>
              This will delete <strong>all</strong> associated webhooks.
            </p>
          </>
        }
        title="Delete Calendly account"
        error={error}
        show={deleteModalState.show}
        handleCancel={() => setDeleteModalState({ show: false })}
        handleAction={() =>
          deleteAccount(deleteModalState.accountId!).then(() =>
            setDeleteModalState({ show: false })
          )
        }
      />
    </>
  );
};

const DELETE_CALENDLY_ACCOUNT = gql`
  mutation DeleteCalendlyAccount($id: ID!) {
    deleteCalendlyAccount(id: $id)
  }
`;

interface Vars {
  id: string;
}

interface Response {
  deleteCalendlyAccount: boolean;
}

const WithMutation: React.FC = () => {
  return (
    <Mutation<Response, Vars> mutation={DELETE_CALENDLY_ACCOUNT}>
      {(mutate, { loading, error }) => (
        <CalendlyAccountsTable
          deleteAccount={id =>
            mutate({ variables: { id } }).then(() =>
              toast("Account deleted", { type: "success" })
            )
          }
          deleteInProgress={loading}
          error={error}
        />
      )}
    </Mutation>
  );
};

export default WithMutation;
