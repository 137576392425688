import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { ApolloError } from "apollo-boost";
import ConfirmDialog from "../../components/Dialog/ConfirmDialog";

interface Props {
  cancelSubscription: () => Promise<any>;
  loading: boolean;
  error?: ApolloError;
}

const CancelSubscriptionForm: React.FC<Props> = ({
  cancelSubscription,
  loading,
  error
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <Button variant="outline-danger" onClick={() => setModalOpen(true)}>
        Cancel subscription
      </Button>
      <ConfirmDialog
        variant="danger"
        actionText="Cancel"
        show={modalOpen}
        text="Are you sure you want to cancel your subscription?"
        title="Cancel subscription"
        loading={loading}
        error={error}
        handleCancel={() => setModalOpen(false)}
        handleAction={() =>
          cancelSubscription().then(() => window.location.reload())
        }
      />
    </>
  );
};

const MUTATION = gql`
  mutation CancelSubscription {
    cancelSubscription
  }
`;

interface Response {
  cancelSubscription: boolean;
}

const WithMutation: React.FC = () => (
  <Mutation<Response> mutation={MUTATION}>
    {(cancelSubscription, { loading, error }) => (
      <CancelSubscriptionForm
        cancelSubscription={cancelSubscription}
        loading={loading}
        error={error}
      />
    )}
  </Mutation>
);

export default WithMutation;
