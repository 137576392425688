import React from "react";
import { Switch, Route } from "react-router-dom";
import Page404 from "../404";
import StartSubscriptionPage from "./StartSubscriptionPage";
import CheckoutCancelPage from "./checkout/cancel";
import CheckoutSuccessPage from "./checkout/success";
import BillingSummaryPage from "./BillingSummaryPage";

const BillingPageContainer: React.FC = () => (
  <Switch>
    <Route path="/billing" exact={true} component={BillingSummaryPage} />

    <Route
      path="/billing/start-subscription"
      exact={true}
      component={StartSubscriptionPage}
    />

    <Route
      path="/billing/checkout/cancel"
      exact={true}
      component={CheckoutCancelPage}
    />
    <Route
      path="/billing/checkout/success"
      exact={true}
      component={CheckoutSuccessPage}
    />

    <Route component={Page404} />
  </Switch>
);

export default BillingPageContainer;
