import React, { useState } from "react";
import SideBar from "../../components/SideBar";
import TopBar from "../../components/TopBar";
import Container from "react-bootstrap/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { Route, Switch, Redirect } from "react-router";
import SettingsPage from "../settings";
import useEventListener from "../../utils/useEventListener";
import Page404 from "../404";
import SalesforceConnectedAccountsPage from "../accounts/salesforce";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import OAuthPage from "../oauth";
import DebugPage from "../debug";
import { HasAuthError } from "../../components/FormError";
import { toast } from "react-toastify";
import {
  saveSidebarPreferences,
  getSavedSidebarPreference
} from "../../utils/localStorage";
import StripeConnectedAccountsPage from "../accounts/stripe";
import WebhooksPage from "../webhooks";
import CalendlyAccountsPage from "../accounts/calendly";
import CopperCRMAccountsPage from "../accounts/copper";
import PaymentsBanner from "../../components/PaymentsBanner";
import Features from "../../components/Features";
import BillingPageContainer from "../billing";
import StatusEventsBanner from "../../components/StatusEvents";
import OrganizationPage from "../organization";
import InviteUserPage from "../organization/users/invite";
import { User } from "../../graphql/types";
import { ApolloError } from "apollo-client";

const USER_QUERY = gql`
  query User {
    me {
      id
      emailAddress
      firstName
      lastName

      organization {
        id
        name
      }
    }
  }
`;

interface Props {
  userToken: string | null;
  setUserId: (id: string) => void;
  setOrganizationId: (id: string) => void;
  setUserToken: (token: string | null) => void;
}

const encodeGetParams = (p: Object) =>
  Object.entries(p)
    .map(kv => kv.map(encodeURIComponent).join("="))
    .join("&");

const DashboardContainerPage: React.FC<Props> = (props: Props) => {
  const { userToken, setUserId, setUserToken, setOrganizationId } = props;
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(
    getSavedSidebarPreference()
  );

  useEventListener("resize", () => {
    if (window.outerWidth < 768) {
      setIsSideBarOpen(false);
    }
  });

  const setSideBarOpen = (value: boolean) => {
    setIsSideBarOpen(value);
    saveSidebarPreferences(value);
  };

  const onCompleted = (data: User) => {
    if (data && data.me) {
      setUserId(data.me.id);
      setOrganizationId(data.me.organization.id);
    }
  };
  const onError = (error: ApolloError) => {
    if (HasAuthError(error)) {
      toast("You've been logged out", {
        type: "error",
        autoClose: false
      });
      setUserToken(null);
    }
    console.error("unable to fetch user ", error);
  };

  if (!userToken) {
    const params = { next: window.location.pathname };
    return <Redirect to={`/login?${encodeGetParams(params)}`} />;
  }

  return (
    <Features>
      <div id="wrapper">
        <SideBar isOpen={isSideBarOpen} setIsOpen={setSideBarOpen} />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Query<User>
              query={USER_QUERY}
              fetchPolicy="cache-and-network"
              onCompleted={onCompleted}
              onError={onError}
            >
              {({ data }) => {
                let userName = "";

                if (data && data.me) {
                  userName = `${data.me.firstName} ${data.me.lastName}`;
                }

                return (
                  <TopBar
                    setSideBarOpen={setSideBarOpen}
                    isSideBarOpen={isSideBarOpen}
                    userName={userName}
                  />
                );
              }}
            </Query>

            <Container fluid={true}>
              <StatusEventsBanner />
              <PaymentsBanner />

              <Switch>
                <Route
                  path="/"
                  exact={true}
                  component={() => <Redirect to="/webhooks" />}
                />
                <Route
                  path="/dashboard"
                  exact={true}
                  component={() => <Redirect to="/webhooks" />}
                />
                <Route path="/settings" exact={true} component={SettingsPage} />

                {/* Accounts */}
                <Route
                  path="/accounts/calendly"
                  component={CalendlyAccountsPage}
                />
                <Route
                  path="/accounts/copper"
                  component={CopperCRMAccountsPage}
                />
                <Route
                  path="/accounts/salesforce"
                  exact={true}
                  component={SalesforceConnectedAccountsPage}
                />
                <Route
                  path="/accounts/stripe"
                  exact={true}
                  component={StripeConnectedAccountsPage}
                />

                {/* Webhooks */}
                <Route path="/webhooks" component={WebhooksPage} />

                {/* OAuth */}
                <Route path="/oauth" component={OAuthPage} />

                {/* Billing */}
                <Route path="/billing" component={BillingPageContainer} />

                {/* Organization Pages */}
                <Route
                  path="/organization"
                  exact={true}
                  component={OrganizationPage}
                />
                <Route
                  path="/organization/users/invite"
                  exact={true}
                  component={InviteUserPage}
                />

                {/* -- DEBUG -- */}
                <Route path="/dev-debug" component={DebugPage} />

                <Route component={Page404} />
              </Switch>
            </Container>
          </div>

          <footer className="sticky-footer bg-white">
            <div className="container my-auto">
              <div className="copyright text-center my-auto">
                <span>
                  Made with{" "}
                  <FontAwesomeIcon icon={faHeart} className="text-danger" />{" "}
                  HookActions {new Date().getFullYear()}
                </span>
              </div>
              <div className="copyright text-center my-auto pt-2">
                <span>
                  <a
                    href="https://status.hookactions.com"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Status
                  </a>
                  {" | "}
                  <a href="mailto:support@hookactions.com?subject=Help">
                    Support
                  </a>
                </span>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </Features>
  );
};

export default DashboardContainerPage;
