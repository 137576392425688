import React, { useEffect } from "react";
import Helmet from "react-helmet";
import gql from "graphql-tag";
import { ApolloError } from "apollo-boost";
import { Redirect } from "react-router";
import LoadingDots from "../../../components/LoadingDots";
import Spinner from "react-bootstrap/Spinner";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Alert from "react-bootstrap/Alert";
import { extractValidationErrors } from "../../../utils/graphql";
import ContactUs from "../../../components/ContactUs";
import { Link } from "react-router-dom";
import { Mutation } from "react-apollo";

const COMPLETE_STRIPE_OAUTH_MUTATION = gql`
  mutation CompleteStripeOauth($code: String!) {
    completeStripeOauth(input: { code: $code }) {
      id
    }
  }
`;

interface CompleteOauthVars {
  code: string;
}

interface CompleteOauthResponse {
  completeStripeOauth?: {
    id: string;
  };
}

interface Props {
  connectAccount: () => void;
  loading: boolean;
  errors?: ApolloError;

  missingQueryParams: boolean;
  data?: CompleteOauthResponse;
  called: boolean;
}

const CompleteStripeOauthCallbackPage: React.FC<Props> = props => {
  const {
    errors,
    missingQueryParams,
    loading,
    data,
    called,
    connectAccount
  } = props;

  useEffect(() => {
    if (!missingQueryParams && !called) {
      connectAccount();
    }
    // eslint-disable-next-line
  }, []);

  const validationErrors = extractValidationErrors(errors);

  if (data && data.completeStripeOauth) {
    return <Redirect to="/accounts/stripe" />;
  }

  return (
    <>
      <Helmet>
        <title>Connecting Stripe Account - HookActions</title>
      </Helmet>

      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">
          Connecting Stripe Account
          <LoadingDots />
        </h1>
        {loading && (
          <Spinner
            animation="border"
            style={{ width: "3rem", height: "3rem" }}
          />
        )}
        {(errors || missingQueryParams) && (
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            style={{ width: "3rem", height: "3rem" }}
          />
        )}
      </div>
      {errors && (
        <Alert variant="danger">
          <Alert.Heading>Error connecting Stripe account</Alert.Heading>
          {validationErrors &&
            validationErrors.map((err, idx) => (
              <p key={idx}>
                {err.field || "Stripe"}: {err.message}
              </p>
            ))}
          <ContactUs
            subject="Unable to connect Stripe account"
            message={`Errors:\n\n${JSON.stringify(errors)}`}
          />
        </Alert>
      )}
      {missingQueryParams && (
        <Alert variant="warning">
          <Alert.Heading>Missing query parameters</Alert.Heading>
          <p>
            This usually happens if you've already connected your Stripe
            account.
          </p>
          <Link to="/accounts/stripe">&larr; Go back</Link>
        </Alert>
      )}
      <div
        className="d-flex justify-content-center my-4"
        style={{ maxHeight: "500px" }}
      >
        <img src="/img/friends.svg" alt="friends" className="img-fluid" />
      </div>
    </>
  );
};

const WithMutation: React.FC = () => {
  const params = new URLSearchParams(window.location.search);
  const code = params.get("code");
  const missingQueryParams = !code;

  return (
    <Mutation<CompleteOauthResponse, CompleteOauthVars>
      mutation={COMPLETE_STRIPE_OAUTH_MUTATION}
    >
      {(mutate, { loading, error, data, called }) => {
        const connectAccount = () => {
          mutate({
            variables: { code: code! }
          });
        };

        return (
          <CompleteStripeOauthCallbackPage
            connectAccount={connectAccount}
            loading={loading}
            errors={error}
            missingQueryParams={missingQueryParams}
            data={data}
            called={called}
          />
        );
      }}
    </Mutation>
  );
};

export default WithMutation;
