import React from "react";
import Badge from "react-bootstrap/Badge";
import "./index.scss";

interface Props {
  cell: any;
}

interface Tag {
  key: string;
  value: string;
}

type Tags = Tag[];

export const MakeSingleValueTagRenderer = (
  keyName: RegExp,
  defaultValue: string | null = null
) => ({ cell }: Props) => {
  const tags = cell as Tags;
  const matchingTags = tags.filter(t => keyName.test(t.key));
  if (matchingTags.length !== 1) return defaultValue;

  return matchingTags[0].value || defaultValue;
};

export const MultiTagRenderer: React.FC<Props> = ({ cell }) => {
  return <TagList tags={cell as Tags} />;
};

interface TagListProps {
  tags: Tags;
}

export const TagList: React.FC<TagListProps> = ({ tags }) => {
  tags.sort((a, b) => (a.key > b.key ? 1 : -1));

  return (
    <div className="tags__container">
      {tags.map(t => (
        <Badge key={t.key} variant="secondary" className="tags__tag">
          {`${t.key}: ${t.value}`}
        </Badge>
      ))}
    </div>
  );
};
