import React from "react";
import Helmet from "react-helmet";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import UsersList from "./UsersList";
import { LinkContainer } from "react-router-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import EditOrganizationForm from "./EditOrganizationForm";
import OrgDetails from "./OrgDetails";

const OrganizationPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Organization – HookActions</title>
      </Helmet>

      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Organization</h1>
      </div>

      <Row>
        <Col sm="12" md="6">
          <Card className="shadow mb-4">
            <Card.Header>Details</Card.Header>
            <Card.Body>
              <OrgDetails />
            </Card.Body>
          </Card>
        </Col>
        <Col sm="12" md="6">
          <Card className="shadow mb-4">
            <Card.Header>Edit</Card.Header>
            <Card.Body>
              <EditOrganizationForm />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Card className="shadow mb-4">
        <Card.Header>
          <span>Users</span>
          <LinkContainer to="/organization/users/invite">
            <Button
              size="sm"
              className="float-right d-flex align-items-center justify-content-between"
              variant="secondary"
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              Invite
            </Button>
          </LinkContainer>
        </Card.Header>
        <Card.Body>
          <UsersList />
        </Card.Body>
      </Card>
    </>
  );
};

export default OrganizationPage;
