import React from "react";
import ContactUs from "./ContactUs";
import { ApolloError } from "apollo-boost";
import Alert from "react-bootstrap/Alert";
import { extractValidationErrors } from "../utils/graphql";
import get from "lodash.get";
import { Link } from "react-router-dom";

interface Props {
  error?: ApolloError;
  isForm?: boolean;
}

const GenericError: React.FC<Props> = ({ error, isForm }) => {
  if (!error) {
    return null;
  }

  let subject = "Error";
  let message = `graphql error: ${JSON.stringify(error)}`;
  let errs = null;

  if (error.networkError) {
    subject = "Network Error";
    message = `Message: ${error.networkError.message}\n\nStack: ${error.networkError.stack}`;
  } else if (
    get(error, "graphQLErrors[0].extensions.type") === "authorization_error"
  ) {
    subject = "Authorization error";
    errs = (
      <>
        <p>It appears you are not authorized to perform that action.</p>
        <p>
          <Link to="/login">Login?</Link>
        </p>
      </>
    );
  } else if (
    get(error, "graphQLErrors[0].extensions.type") ===
    "missing_subscription_error"
  ) {
    subject = "Missing subscription";
    errs = (
      <>
        <p>
          It appears that you are missing an active subscription, which is
          required to perform that action.
        </p>
        <Link to="/billing" className="mr-4">
          Activate your subscription
        </Link>
      </>
    );
  } else if (
    get(error, "graphQLErrors[0].extensions.type") === "internal_server_error"
  ) {
    // nothing, just pass
  } else if (error.graphQLErrors) {
    const validationErrors = extractValidationErrors(error);
    errs = (
      <ul>
        {validationErrors.map((err, idx) => (
          <li key={idx}>
            {err.field && <strong>{err.field}</strong>} {err.message}
          </li>
        ))}
      </ul>
    );
    subject = "Unhandled validation error";
    message = `Validation errors: ${JSON.stringify(validationErrors)}`;

    if (validationErrors.length > 0 && isForm) {
      return null;
    }
  }

  return (
    <Alert variant="danger">
      <Alert.Heading>{subject}</Alert.Heading>
      {errs}
      <ContactUs subject={subject} message={message} />
    </Alert>
  );
};

export default GenericError;
