import React from "react";
import Helmet from "react-helmet";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { getContactUsLink } from "../../components/ContactUs";
import { AppContext } from "../../App";
import UserForm from "./UserForm";
import ChangePasswordForm from "./ChangePasswordForm";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const SettingsPage: React.FC = () => {
  return (
    <AppContext.Consumer>
      {({ userId }) => (
        <>
          <Helmet>
            <title>Settings – HookActions</title>
          </Helmet>

          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">Settings</h1>
          </div>

          <Row>
            <Col md={6} sm={12}>
              <Card className="shadow mb-4">
                <Card.Header>Update user</Card.Header>
                <Card.Body>
                  <UserForm userId={userId!} />
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} sm={12}>
              <Card className="shadow mb-4">
                <Card.Header>Change password</Card.Header>
                <Card.Body>
                  <ChangePasswordForm userId={userId!} />
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* Remove for now, until users want it. */}
          {/* <Card className="shadow mb-4">
            <Card.Header>Login history</Card.Header>
            <Card.Body>
              <UserLoginsTable />
            </Card.Body>
          </Card> */}

          <Card className="shadow mb-4">
            <Card.Header>Delete account</Card.Header>
            <Card.Body>
              <AppContext.Consumer>
                {({ userId }) => (
                  <Button
                    href={getContactUsLink(
                      "Account deletion request",
                      "I am requesting that my account is deleted.",
                      userId
                    )}
                    variant="outline-danger"
                  >
                    Contact us
                  </Button>
                )}
              </AppContext.Consumer>
            </Card.Body>
          </Card>
        </>
      )}
    </AppContext.Consumer>
  );
};

export default SettingsPage;
