import React from "react";
import { ApolloError, gql } from "apollo-boost";
import { CalendlyAccountNode } from "../../../graphql/calendly";
import { Mutation } from "react-apollo";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import useForm from "react-hook-form";
import Form from "react-bootstrap/Form";
import FormError, { HasError } from "../../../components/FormError";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import { RBRef } from "../../../components/form/types";

interface Props {
  loading: boolean;
  error?: ApolloError;
  createAccount: (variables: AccountVars) => Promise<any>;
}

interface AccountVars {
  name: string;
  apiKey: string;
}

const ConnectCalendlyAccountForm: React.FC<Props> = ({
  loading,
  error,
  createAccount
}) => {
  const { register, handleSubmit } = useForm();

  const onSubmit = (record: Record<string, any>) =>
    createAccount({
      name: record.name,
      apiKey: record.apiKey
    });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group>
        <Form.Label htmlFor="name">Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="e.g. Sales"
          name="name"
          ref={register({ required: true }) as RBRef}
          isInvalid={HasError(error, "name")}
        />
        <FormError errors={error} fieldName="name" />
      </Form.Group>
      <Form.Group>
        <Form.Label htmlFor="name">Api Key</Form.Label>
        <Form.Control
          type="password"
          name="apiKey"
          ref={register({ required: true }) as RBRef}
          isInvalid={HasError(error, "api_key")}
        />
        <Form.Text>
          <FontAwesomeIcon icon={faLock} />
          <span className="ml-1">
            Encrypted{" "}
            <a href="https://en.wikipedia.org/wiki/Data_at_rest#Encryption">
              at rest
            </a>{" "}
            in our database. Get your api key{" "}
            <a
              href="https://calendly.com/integrations"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            .
          </span>
        </Form.Text>
        <FormError errors={error} fieldName="api_key" />
      </Form.Group>

      <Button type="submit" block={true} variant="primary" disabled={loading}>
        <span>Connect account</span>
        {loading && <Spinner animation="border" className="ml-2" size="sm" />}
      </Button>
    </Form>
  );
};

interface AccountResponse {
  createCalendlyAccount?: CalendlyAccountNode;
}

const CREATE_ACCOUNT = gql`
  mutation CreateCalendlyAccount($name: String!, $apiKey: String!) {
    createCalendlyAccount(input: { name: $name, apiKey: $apiKey }) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

const WithMutation: React.FC = () => (
  <Mutation<AccountResponse, AccountVars> mutation={CREATE_ACCOUNT}>
    {(mutate, { loading, error, data }) => {
      if (
        !loading &&
        !error &&
        data &&
        data.createCalendlyAccount &&
        data.createCalendlyAccount.id
      ) {
        toast("Account created", { type: "success" });
        return <Redirect to="/accounts/calendly" />;
      }

      return (
        <ConnectCalendlyAccountForm
          loading={loading}
          error={error}
          createAccount={variables => mutate({ variables })}
        />
      );
    }}
  </Mutation>
);

export default WithMutation;
