import React from "react";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import TimeRenderer from "../../../components/TimeRenderer";
import { ApolloError } from "apollo-boost";
import CenteredSpinner from "../../../components/CenteredSpinner";
import GenericError from "../../../components/GenericError";
import {
  TagList,
  MakeSingleValueTagRenderer
} from "../../../components/renderers/tags";
import { webhookEventToCalendlyName } from "../../../graphql/calendly";
import { CalendlyWebhook_calendlyWebhook } from "../../../graphql/types";

interface Props {
  webhook?: CalendlyWebhook_calendlyWebhook | null;
  show: boolean;
  onHide: () => void;
  loading?: boolean;
  error?: ApolloError;
}

const CalendlyWebhookDetailModal: React.FC<Props> = ({
  webhook,
  show,
  onHide,
  loading,
  error
}) => {
  if (!webhook || !show) {
    return null;
  }

  const isLoading: boolean = loading == null ? false : loading;
  const name = isLoading
    ? ""
    : MakeSingleValueTagRenderer(
        /name/i,
        "missing name"
      )({
        cell: webhook.tags
      });

  return (
    <Modal size="lg" show={true} onHide={onHide}>
      <Modal.Header closeButton={true}>
        <h4>{name}</h4>
      </Modal.Header>
      <Modal.Body>
        {isLoading && <CenteredSpinner />}
        {error && <GenericError error={error} />}
        {!isLoading && !error && (
          <>
            <h3>Details</h3>
            <Table className="kv">
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>{name}</td>
                </tr>
                <tr>
                  <td>Account</td>
                  <td>{webhook.account.name}</td>
                </tr>
                <tr>
                  <td>URL</td>
                  <td>{webhook.url}</td>
                </tr>
                <tr>
                  <td>Events</td>
                  <td>
                    {webhook.events.map(webhookEventToCalendlyName).join(", ")}
                  </td>
                </tr>
                <tr>
                  <td>Tags</td>
                  <td>
                    <TagList tags={webhook.tags} />
                  </td>
                </tr>
                <tr>
                  <td>Created At</td>
                  <td>
                    <TimeRenderer cell={webhook.createdAt} />
                  </td>
                </tr>
                <tr>
                  <td>Updated At</td>
                  <td>
                    <TimeRenderer cell={webhook.updatedAt} />
                  </td>
                </tr>
              </tbody>
            </Table>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default CalendlyWebhookDetailModal;
