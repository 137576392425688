import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import useForm from "react-hook-form";
import FormError, { capitalize, HasError } from "../../../components/FormError";
import { ApolloError, gql } from "apollo-boost";
import {
  SalesforceEvent,
  SalesforceWebhookNode
} from "../../../graphql/salesforce";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import uuid4 from "uuid/v4";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import SalesforceAccountSelect from "./SalesforceAccountSelect";
import { Mutation } from "react-apollo";
import { Redirect } from "react-router";
import { toast } from "react-toastify";
import TagEditor from "../../../components/form/tags/TagEditorWithQuery";
import GenericError from "../../../components/GenericError";
import { RBRef } from "../../../components/form/types";

interface Props {
  loading: boolean;
  error?: ApolloError;
  createWebhook: (variables: WebhookVars) => void;
}

const SalesforceWebhookForm: React.FC<Props> = props => {
  const [tags, setTags] = useState();
  const { register, handleSubmit, watch } = useForm();
  const { error, loading, createWebhook } = props;

  const onSubmit = (record: Record<string, any>) =>
    createWebhook({
      name: record.name,
      objectName: record.objectName,
      events: record.events,
      url: record.url,
      hmacKey: record.hmacKey,
      hmacHeader: record.hmacHeader,
      accountId: record.accountId,
      tags
    });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <GenericError error={error} isForm={true} />

      <Form.Group>
        <Form.Label htmlFor="name">Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="e.g. Account webhook"
          name="name"
          ref={register({ required: true }) as RBRef}
          isInvalid={HasError(error, "name")}
        />
        <FormError errors={error} fieldName="name" />
      </Form.Group>
      <SalesforceAccountSelect watch={watch} register={register} />
      <Form.Group>
        <Form.Label htmlFor="events">Events</Form.Label>
        <Form.Control
          as="select"
          multiple={true}
          ref={register({ required: true }) as RBRef}
          name="events"
          isInvalid={HasError(error, "events")}
        >
          {Object.keys(SalesforceEvent).map(item => (
            <option value={item} key={item}>
              {capitalize(item.replace(/_/g, " ").toLocaleLowerCase())}
            </option>
          ))}
        </Form.Control>
        <Form.Text>Select multiple with shift, ctrl, or cmd.</Form.Text>
        <FormError errors={error} fieldName="events" />
      </Form.Group>
      <Form.Group>
        <Form.Label htmlFor="url">URL</Form.Label>
        <Form.Control
          type="text"
          placeholder="e.g. https://example.com/my/webhook"
          name="url"
          ref={register({ required: true }) as RBRef}
          isInvalid={HasError(error, "url")}
        />
        <FormError errors={error} fieldName="url" />
      </Form.Group>
      <Form.Group>
        <Form.Label htmlFor="tags">Tags</Form.Label>
        <TagEditor
          name="tags"
          setValue={(_, tags) => setTags(tags)}
          existingTags={[]}
          mutationError={error}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label htmlFor="hmacHeader">
          <span className="mr-2">HMAC header name</span>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="hmac-header-help">
                An HMAC header verifies the webhook came from a trusted source.
              </Tooltip>
            }
          >
            <FontAwesomeIcon icon={faQuestionCircle} />
          </OverlayTrigger>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="e.g. X-SFDC-HMAC"
          name="hmacHeader"
          defaultValue="X-SFDC-HMAC"
          ref={register({ required: true }) as RBRef}
          isInvalid={HasError(error, "hmac_header")}
        />
        <FormError errors={error} fieldName="hmac_header" />
      </Form.Group>
      <Form.Group>
        <Form.Label htmlFor="hmacKey">
          <span className="mr-2">HMAC Secret</span>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="hmac-secret-help">
                The secret used to generate the HMAC signature.
              </Tooltip>
            }
          >
            <FontAwesomeIcon icon={faQuestionCircle} />
          </OverlayTrigger>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="e.g. 792e641e-c1ab-11e9-a484-3c15c2eb7774"
          name="hmacKey"
          defaultValue={uuid4()}
          ref={register({ required: true }) as RBRef}
          isInvalid={HasError(error, "hmac_key")}
        />
        <FormError errors={error} fieldName="hmac_key" />
      </Form.Group>

      <Button type="submit" block={true} variant="primary" disabled={loading}>
        <span>Create webhook</span>
        {loading && <Spinner animation="border" className="ml-2" size="sm" />}
      </Button>
    </Form>
  );
};

interface WebhookVars {
  name: string;
  objectName: string;
  events: string[];
  url: string;
  hmacKey: string;
  hmacHeader: string;
  accountId: string;
  tags?: Array<{ key: string; value: string }>;
}

interface WebhookResponse {
  createSalesforceWebhook?: SalesforceWebhookNode;
}

const CREATE_WEBHOOK = gql`
  mutation CreateSalesforceWebhook(
    $accountId: ID!
    $events: [SalesforceEvent!]!
    $objectName: String!
    $url: String!
    $hmacKey: String!
    $hmacHeader: String!
    $name: String!
    $tags: [TagInput!]
  ) {
    createSalesforceWebhook(
      input: {
        accountId: $accountId
        events: $events
        objectName: $objectName
        url: $url
        hmacKey: $hmacKey
        hmacHeader: $hmacHeader
        name: $name
        tags: $tags
      }
    ) {
      id
      name
      createdAt
      updatedAt
      object {
        name
      }
      url
      events
      hmacHeaderName
      hmacKey
    }
  }
`;

const WithMutation: React.FC = () => (
  <Mutation<WebhookResponse, WebhookVars> mutation={CREATE_WEBHOOK}>
    {(mutate, { loading, error, data }) => {
      if (
        !loading &&
        !error &&
        data &&
        data.createSalesforceWebhook &&
        data.createSalesforceWebhook.id
      ) {
        toast("Webhook created", { type: "success" });
        return <Redirect to="/webhooks/salesforce" />;
      }
      return (
        <SalesforceWebhookForm
          loading={loading}
          error={error}
          createWebhook={variables => mutate({ variables })}
        />
      );
    }}
  </Mutation>
);

export default WithMutation;
