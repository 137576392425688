import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLink,
  faAngleLeft,
  faAngleRight,
  faBroadcastTower,
  faUsers,
  faMoneyBillWave
} from "@fortawesome/free-solid-svg-icons";
import { LinkContainer } from "react-router-bootstrap";
import NavLinkCollapse from "../NavLinkCollapse";
import clsx from "clsx";
import { Context as FeaturesContext } from "../Features";
import { FeatureFlag } from "../../graphql/types";

interface Props {
  setIsOpen: (value: boolean) => void;
  isOpen: boolean;
}

const SideBar = (props: Props) => {
  const { isOpen, setIsOpen } = props;

  return (
    <ul
      className={clsx(
        "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion",
        { toggled: !isOpen }
      )}
    >
      {/* Sidebar - Brand */}
      <Link
        className="sidebar-brand d-flex align-items-center justify-content-center"
        to="/webhooks"
      >
        <div className="sidbar-brand-icon" style={{ width: "100%" }}>
          <img
            src="/logo_white.svg"
            className="img-fluid"
            alt="hookactions logo"
            style={{ maxWidth: "40px" }}
          />
        </div>
        <div className="sidebar-brand-text mx-3">HookActions</div>
      </Link>

      <hr className="sidebar-divider my-0" />

      {/* Nav Item - Webhooks */}
      <LinkContainer to="/webhooks">
        <li className="nav-item">
          <a className="nav-link" href="/webhooks">
            <div>
              <FontAwesomeIcon
                fixedWidth={true}
                icon={faBroadcastTower}
                className={clsx({ "mr-2": isOpen })}
              />
              <span>Webhooks</span>
            </div>
          </a>
        </li>
      </LinkContainer>

      <NavLinkCollapse
        name="Accounts"
        icon={faLink}
        items={[
          {
            to: "/accounts/calendly",
            name: "Calendly"
          },
          {
            to: "/accounts/copper",
            name: "Copper CRM"
          },
          {
            to: "/accounts/salesforce",
            name: "Salesforce"
          },
          {
            to: "/accounts/stripe",
            name: "Stripe"
          }
        ]}
        isSideBarOpen={isOpen}
      />

      <LinkContainer to="/organization">
        <li className="nav-item">
          <a className="nav-link" href="/organization">
            <div>
              <FontAwesomeIcon
                fixedWidth={true}
                icon={faUsers}
                className={clsx({ "mr-2": isOpen })}
              />
              <span>Organization</span>
            </div>
          </a>
        </li>
      </LinkContainer>

      <FeaturesContext.Consumer>
        {({ featuresByFlag }) => (
          <>
            {featuresByFlag[FeatureFlag.PAYMENTS] &&
              featuresByFlag[FeatureFlag.PAYMENTS].isEnabled && (
                <LinkContainer to="/billing">
                  <li className="nav-item">
                    <a className="nav-link" href="/billing">
                      <div>
                        <FontAwesomeIcon
                          fixedWidth={true}
                          icon={faMoneyBillWave}
                          className={clsx({ "mr-2": isOpen })}
                        />
                        <span>Billing</span>
                      </div>
                    </a>
                  </li>
                </LinkContainer>
              )}
          </>
        )}
      </FeaturesContext.Consumer>

      <hr className="sidebar-divider d-none d-md-block" />

      <div className="text-center d-none d-md-inline">
        <button
          className="rounded-circle border-0"
          id="sidebarToggle"
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen && (
            <FontAwesomeIcon
              icon={faAngleLeft}
              style={{ color: "rgba(255,255,255,.5)" }}
            />
          )}
          {!isOpen && (
            <FontAwesomeIcon
              icon={faAngleRight}
              style={{ color: "rgba(255,255,255,.5)" }}
            />
          )}
        </button>
      </div>
    </ul>
  );
};

export default SideBar;
