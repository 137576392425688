import React, { useState } from "react";
import Helmet from "react-helmet";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FiltersList from "./filters";
import AllWebhooksTable from "./WebhooksTable";
import { FilterContext, Filter } from "./context";
import CreateLinks from "./CreateLinks";

import "./index.scss";

const WebhooksListPage: React.FC = () => {
  const [filters, setFilters] = useState<Filter[]>([]);

  return (
    <>
      <Helmet>
        <title>Webhooks – HookActions</title>
      </Helmet>

      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Webhooks</h1>
        <CreateLinks />
      </div>

      <Card className="shadow mb-4">
        <Card.Body>
          <FilterContext.Provider value={{ filters }}>
            <Row>
              <Col md="3" lg="2" className="filter-col">
                <FiltersList setAppliedFilters={setFilters} />
              </Col>
              <Col>
                <AllWebhooksTable />
              </Col>
            </Row>
          </FilterContext.Provider>
        </Card.Body>
      </Card>
    </>
  );
};

export default WebhooksListPage;
