import React from "react";
import Helmet from "react-helmet";
import Card from "react-bootstrap/Card";
import ConnectLink from "./ConnectLink";
import CalendlyAccountsTable from "./CalendlyAccountsTable";
import ConnectPage from "./ConnectPage";
import { Switch, Route } from "react-router-dom";
import Page404 from "../../404";

const CalendlyAccountsPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Calendly Accounts – HookActions</title>
      </Helmet>

      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Calendly Accounts</h1>
        <ConnectLink />
      </div>

      <Card className="shadow mb-4">
        <Card.Header>Accounts</Card.Header>
        <Card.Body>
          <CalendlyAccountsTable />
        </Card.Body>
      </Card>
    </>
  );
};

export default () => (
  <Switch>
    <Route
      path="/accounts/calendly"
      exact={true}
      component={CalendlyAccountsPage}
    />
    <Route path="/accounts/calendly/new" exact={true} component={ConnectPage} />

    <Route component={Page404} />
  </Switch>
);
