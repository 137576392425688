import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ApolloError } from "apollo-boost";
import CenteredSpinner from "../CenteredSpinner";
import GenericError from "../GenericError";

interface Props {
  variant: "primary" | "danger";
  actionText: string;
  show: boolean;

  text: React.ReactNode;
  title: React.ReactNode;

  loading: boolean;
  error?: ApolloError;

  handleCancel: () => void;
  handleAction: () => void;
}

const ConfirmDialog: React.FC<Props> = props => {
  const {
    variant,
    actionText,
    handleAction,
    handleCancel,
    show,
    text,
    title,
    loading,
    error
  } = props;

  return (
    <Modal show={show} onHide={handleCancel} centered={true} size="sm">
      <Modal.Header closeButton={true}>{title}</Modal.Header>
      <Modal.Body>
        <GenericError error={error} />
        {loading && <CenteredSpinner />}
        {!loading && text}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant={variant} onClick={handleAction}>
          {actionText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDialog;
