import jwtDecode from "jwt-decode";
import moment from "moment";

const JWT_LS_NAME = "@@HOOKACTIONS/JWT";
const requiredTokenLife = moment.duration(1, "day");

interface IJWT {
  exp: number;
  user_id: string;
  org_id: string;
}

export const getToken = () => {
  const savedToken = localStorage.getItem(JWT_LS_NAME);

  if (!!savedToken) {
    const decodedToken = jwtDecode<IJWT>(savedToken);
    const exp = moment(new Date(decodedToken.exp * 1000));
    const diff = moment.duration(exp.diff(moment()));

    // token must be good for at least 1 day, otherwise prompt the user to login again
    if (diff.asMilliseconds() >= requiredTokenLife.asMilliseconds()) {
      return savedToken;
    }
  }

  return null;
};

export const setToken = (newToken: string | null) => {
  if (!newToken) {
    removeToken();
    return;
  }
  localStorage.setItem(JWT_LS_NAME, newToken);
};

export const removeToken = () => {
  localStorage.removeItem(JWT_LS_NAME);
};

const sideBarName = "@@HOOKACTIONS/SIDE_BAR";

export const getSavedSidebarPreference = () =>
  JSON.parse(localStorage.getItem(sideBarName) || "true");

export const saveSidebarPreferences = (newValue: boolean) =>
  localStorage.setItem(sideBarName, JSON.stringify(newValue));
