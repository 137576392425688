import React from "react";
import { Switch, Route } from "react-router-dom";
import Helmet from "react-helmet";
import Page404 from "../../404";
import Card from "react-bootstrap/Card";
import AccountConnectLinks from "../../../components/ConnectLinks/AccountConnectLinks";
import CopperCRMAccountsTable from "./CopperCRMAccountsTable";
import ConnectPage from "./ConnectPage";

const CopperCRMAccountsPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Copper CRM Accounts – HookActions</title>
      </Helmet>

      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Copper CRM Accounts</h1>
        <AccountConnectLinks
          connectAccountLink="/accounts/copper/new"
          createWebhookLink="/webhooks/copper/create"
        />
      </div>

      <Card className="shadow mb-4">
        <Card.Header>Accounts</Card.Header>
        <Card.Body>
          <CopperCRMAccountsTable />
        </Card.Body>
      </Card>
    </>
  );
};

export default () => (
  <Switch>
    <Route
      path="/accounts/copper"
      exact={true}
      component={CopperCRMAccountsPage}
    />
    <Route path="/accounts/copper/new" exact={true} component={ConnectPage} />

    <Route component={Page404} />
  </Switch>
);
