import React from "react";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { ApolloError } from "apollo-boost";
import useForm from "react-hook-form";
import Form from "react-bootstrap/Form";
import GenericError from "../../../../components/GenericError";
import FormError, { HasError } from "../../../../components/FormError";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { RBRef } from "../../../../components/form/types";

interface Props {
  mutate: (variables: Vars) => Promise<any>;
  loading: boolean;
  error?: ApolloError;
}

const InviteUserForm: React.FC<Props> = ({ mutate, loading, error }) => {
  const { register, handleSubmit } = useForm();

  const onSubmit = (data: Record<string, any>) =>
    mutate({
      firstName: data.firstName,
      lastName: data.lastName,
      emailAddress: data.emailAddress
    });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <GenericError error={error} isForm={true} />

      <Form.Group>
        <Form.Label htmlFor="firstName">First name</Form.Label>
        <Form.Control
          type="text"
          placeholder="e.g. Mary"
          name="firstName"
          id="firstName"
          ref={register({ required: true }) as RBRef}
          isInvalid={HasError(error, "first_name")}
        />
        <FormError errors={error} fieldName="first_name" />
      </Form.Group>
      <Form.Group>
        <Form.Label htmlFor="lastName">Last name</Form.Label>
        <Form.Control
          type="text"
          placeholder="e.g. Stuart"
          name="lastName"
          id="lastName"
          ref={register({ required: true }) as RBRef}
          isInvalid={HasError(error, "last_name")}
        />
        <FormError errors={error} fieldName="last_name" />
      </Form.Group>
      <Form.Group>
        <Form.Label htmlFor="emailAddress">Email address</Form.Label>
        <Form.Control
          type="email"
          placeholder="e.g. mary@hookactions.com"
          name="emailAddress"
          id="emailAddress"
          ref={register({ required: true }) as RBRef}
          isInvalid={HasError(error, "email_address")}
        />
        <FormError errors={error} fieldName="email_address" />
      </Form.Group>

      <Button type="submit" block={true} variant="primary" disabled={loading}>
        <span>Invite user</span>
        {loading && <Spinner animation="border" className="ml-2" size="sm" />}
      </Button>
    </Form>
  );
};

const MUTATION = gql`
  mutation InviteUser(
    $firstName: String!
    $lastName: String!
    $emailAddress: String!
  ) {
    inviteUserToOrganization(
      input: {
        firstName: $firstName
        lastName: $lastName
        emailAddress: $emailAddress
      }
    )
  }
`;

interface Vars {
  firstName: string;
  lastName: string;
  emailAddress: string;
}

interface Response {
  inviteUserToOrganization: boolean;
}
const WithMutation: React.FC = () => {
  return (
    <Mutation<Response, Vars> mutation={MUTATION}>
      {(mutate, { loading, error, data }) => {
        if (
          !loading &&
          !error &&
          data &&
          data.inviteUserToOrganization === true
        ) {
          toast("User invited", { type: "success" });
          return <Redirect to="/organization" />;
        }

        return (
          <InviteUserForm
            mutate={variables => mutate({ variables })}
            loading={loading}
            error={error}
          />
        );
      }}
    </Mutation>
  );
};

export default WithMutation;
