import React from "react";
import DetailModal from "./DetailModal";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import {
  CopperCRMWebhook,
  CopperCRMWebhookVariables
} from "../../../graphql/types";

const WEBHOOK_QUERY = gql`
  query CopperCRMWebhook($id: ID!) {
    copperCRMWebhook(id: $id) {
      id
      createdAt
      updatedAt
      url
      event
      secret
      webhookType
      account {
        id
        name
      }
      tags {
        key
        value
      }
    }
  }
`;

interface Props {
  show: boolean;
  onHide: () => void;
  webhookId: string;
}

const DetailModalWithQuery: React.FC<Props> = ({ show, onHide, webhookId }) => (
  <Query<CopperCRMWebhook, CopperCRMWebhookVariables>
    query={WEBHOOK_QUERY}
    variables={{ id: webhookId }}
  >
    {({ loading, error, data }) => (
      <DetailModal
        show={show}
        loading={loading}
        onHide={onHide}
        webhook={data && data.copperCRMWebhook}
        error={error}
      />
    )}
  </Query>
);

export default DetailModalWithQuery;
