import React from "react";
import gql from "graphql-tag";
import {
  CreateCopperCRMAccount,
  CreateCopperCRMAccountVariables
} from "../../../graphql/types";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import useForm from "react-hook-form";
import Form from "react-bootstrap/Form";
import { RBRef } from "../../../components/form/types";
import FormError, { HasError } from "../../../components/FormError";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { Mutation, MutationFn } from "react-apollo";
import { ApolloError } from "apollo-boost";
import GenericError from "../../../components/GenericError";

interface Props {
  mutate: MutationFn<CreateCopperCRMAccount, CreateCopperCRMAccountVariables>;
  loading: boolean;
  error?: ApolloError;
}

const ConnectCopperCRMAccountForm: React.FC<Props> = ({
  mutate,
  loading,
  error
}) => {
  const { register, handleSubmit } = useForm();

  const onSubmit = (record: Record<string, any>): Promise<any> =>
    mutate({
      variables: {
        name: record.name,
        apiKey: record.apiKey,
        tokenOwnerEmail: record.tokenOwnerEmail
      }
    });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <GenericError error={error} isForm={true} />

      <Form.Group>
        <Form.Label htmlFor="name">Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="e.g. Sales"
          name="name"
          ref={register}
          isInvalid={HasError(error, "name")}
        />
        <Form.Text>
          If not set, we will use the name of your Copper CRM account.
        </Form.Text>
        <FormError errors={error} fieldName="name" />
      </Form.Group>
      <Form.Group>
        <Form.Label htmlFor="name">Token User Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="e.g. mary@hookactions.com"
          name="tokenOwnerEmail"
          ref={register({ required: true }) as RBRef}
          isInvalid={HasError(error, "token_owner_email")}
        />
        <Form.Text>
          Email address of token owner.{" "}
          <a
            href="https://developer.copper.com/introduction/requests.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more &rsaquo;
          </a>
        </Form.Text>
        <FormError errors={error} fieldName="token_owner_email" />
      </Form.Group>
      <Form.Group>
        <Form.Label htmlFor="name">Api Key</Form.Label>
        <Form.Control
          type="password"
          name="apiKey"
          ref={register({ required: true }) as RBRef}
          isInvalid={HasError(error, "api_key")}
        />
        <Form.Text>
          <FontAwesomeIcon icon={faLock} />
          <span className="ml-1">
            Encrypted{" "}
            <a href="https://en.wikipedia.org/wiki/Data_at_rest#Encryption">
              at rest
            </a>{" "}
            in our database. Get your api key{" "}
            <a
              href="https://developer.copper.com/introduction/authentication.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            .
          </span>
        </Form.Text>
        <FormError errors={error} fieldName="api_key" />
      </Form.Group>

      <Button type="submit" block={true} variant="primary" disabled={loading}>
        <span>Connect account</span>
        {loading && <Spinner animation="border" className="ml-2" size="sm" />}
      </Button>
    </Form>
  );
};

const CREATE_ACCOUNT = gql`
  mutation CreateCopperCRMAccount(
    $name: String
    $apiKey: String!
    $tokenOwnerEmail: String!
  ) {
    createCopperCRMAccount(
      input: { name: $name, apiKey: $apiKey, tokenOwnerEmail: $tokenOwnerEmail }
    ) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

const WithMutation: React.FC = () => (
  <Mutation<CreateCopperCRMAccount, CreateCopperCRMAccountVariables>
    mutation={CREATE_ACCOUNT}
  >
    {(mutate, { loading, error, data }) => {
      if (
        !loading &&
        !error &&
        data &&
        data.createCopperCRMAccount &&
        data.createCopperCRMAccount.id
      ) {
        toast("Account created", { type: "success" });
        return <Redirect to="/accounts/copper" />;
      }

      return (
        <ConnectCopperCRMAccountForm
          mutate={mutate}
          loading={loading}
          error={error}
        />
      );
    }}
  </Mutation>
);

export default WithMutation;
