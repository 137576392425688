import { ApolloError } from "apollo-boost";
import get from "lodash.get";

interface Error {
  field?: string;
  message: string;
}

export const extractValidationErrors = (errors?: ApolloError): Error[] => {
  return get(errors, "graphQLErrors[0].extensions.errors", []);
};
