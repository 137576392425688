import React from "react";
import { StripeAccountNode } from "../../../graphql/stripe";
import gql from "graphql-tag";
import { ApolloError } from "apollo-boost";
import { Query } from "react-apollo";
import { Connection } from "../../../graphql/relay";
import Form from "react-bootstrap/Form";
import GenericError from "../../../components/GenericError";
import LoadingDots from "../../../components/LoadingDots";
import Alert from "react-bootstrap/Alert";
import { LinkContainer } from "react-router-bootstrap";
import { RegisterFn, RBRef } from "../../../components/form/types";

interface Props {
  register: RegisterFn;
  accounts: StripeAccountNode[];
  loading: boolean;
  error?: ApolloError;
}

const StripeAccountSelect: React.FC<Props> = props => {
  const { register, accounts, loading, error } = props;

  const accountLookup: Map<string, StripeAccountNode> = new Map();
  accounts.forEach(account => {
    accountLookup.set(account.id, account);
  });

  return (
    <>
      {!error && !loading && accounts.length === 0 && (
        <Alert variant="danger">
          <span className="mr-2">
            A Stripe account must be connected first.
          </span>
          <LinkContainer to="/accounts/stripe">
            <Alert.Link>Connect now &rsaquo;</Alert.Link>
          </LinkContainer>
        </Alert>
      )}

      <GenericError error={error} />

      <Form.Group>
        <Form.Label htmlFor="accountId">Account</Form.Label>
        <Form.Control
          as="select"
          name="accountId"
          disabled={loading}
          ref={register({ required: true }) as RBRef}
        >
          <option value="" />
          {accounts.map(account => (
            <option key={account.id} value={account.id}>
              {`${account.name || account.stripeUserId} - Live?: ${
                account.livemode
              }`}
            </option>
          ))}
        </Form.Control>
        {loading && (
          <Form.Text>
            Loading
            <LoadingDots />
          </Form.Text>
        )}
      </Form.Group>
    </>
  );
};

const STRIPE_ACCOUNTS = gql`
  query StripeAccountsBulk {
    stripeAccountsConnection(first: 1000) {
      edges {
        node {
          id
          name
          livemode
          createdAt
          stripeUserId
        }
      }
    }
  }
`;

interface QueryProps {
  register: RegisterFn;
}

interface Response {
  stripeAccountsConnection?: Connection<StripeAccountNode>;
}

const WithQuery: React.FC<QueryProps> = ({ register }) => (
  <Query<Response> query={STRIPE_ACCOUNTS}>
    {({ data, loading, error }) => (
      <StripeAccountSelect
        register={register}
        loading={loading}
        error={error}
        accounts={
          data &&
          data.stripeAccountsConnection &&
          data.stripeAccountsConnection.edges
            ? data.stripeAccountsConnection.edges.map(i => i.node!)
            : []
        }
      />
    )}
  </Query>
);

export default WithQuery;
