import React from "react";
import Helmet from "react-helmet";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import LoadingDots from "../../../components/LoadingDots";
import ContactUs from "../../../components/ContactUs";
import { ApolloError } from "apollo-boost";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { extractValidationErrors } from "../../../utils/graphql";
import { Link, Redirect } from "react-router-dom";

const CONNECT_SALESFORCE_MUTATION = gql`
  mutation ConnectSalesforceAccount(
    $code: String!
    $environment: SalesforceEnvironment!
  ) {
    completeSalesforceOauth(input: { code: $code, environment: $environment }) {
      id
    }
  }
`;

interface ConnectVars {
  code: string;
  environment: string;
}

interface ConnectResponse {
  completeSalesforceOauth?: {
    id: string;
  };
}

interface Props {
  connectAccount: () => void;
  loading: boolean;
  errors?: ApolloError;

  missingQueryParams: boolean;
  data?: ConnectResponse;
  called: boolean;
}

class SalesforceOAuthCallbackPage extends React.Component<Props> {
  componentDidMount() {
    if (!this.props.missingQueryParams && !this.props.called) {
      this.props.connectAccount();
    }
  }

  render() {
    const { errors, missingQueryParams, loading, data } = this.props;
    const validationErrors = extractValidationErrors(errors);

    if (data && data.completeSalesforceOauth) {
      return <Redirect to="/accounts/salesforce" />;
    }

    return (
      <>
        <Helmet>
          <title>Connecting Salesforce Account – HookActions</title>
        </Helmet>

        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">
            Connecting Salesforce Account
            <LoadingDots />
          </h1>
          {loading && (
            <Spinner
              animation="border"
              style={{ width: "3rem", height: "3rem" }}
            />
          )}
          {(errors || missingQueryParams) && (
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              style={{ width: "3rem", height: "3rem" }}
            />
          )}
        </div>
        {errors && (
          <Alert variant="danger">
            <Alert.Heading>Error connecting Salesforce account</Alert.Heading>
            {validationErrors &&
              validationErrors.map((err, idx) => (
                <p key={idx}>
                  {err.field || "Salesforce"}: {err.message}
                </p>
              ))}
            <ContactUs
              subject="Unable to connect Salesforce account"
              message={`Errors:\n\n${JSON.stringify(errors)}`}
            />
          </Alert>
        )}
        {missingQueryParams && (
          <Alert variant="warning">
            <Alert.Heading>Missing query parameters</Alert.Heading>
            <p>
              This usually happens if you've already connected your Salesforce
              account.
            </p>
            <Link to="/accounts/salesforce">&larr; Go back</Link>
          </Alert>
        )}
        <div
          className="d-flex justify-content-center my-4"
          style={{ maxHeight: "500px" }}
        >
          <img src="/img/friends.svg" alt="friends" className="img-fluid" />
        </div>
      </>
    );
  }
}

const WithMutation = () => {
  const params = new URLSearchParams(window.location.search);
  const [code, environment] = [params.get("code"), params.get("state")];
  const missingParams = !(code && environment);

  return (
    <Mutation<ConnectResponse, ConnectVars>
      mutation={CONNECT_SALESFORCE_MUTATION}
    >
      {(mutate, { loading, error, data, called }) => {
        const connectAccount = () => {
          mutate({
            variables: {
              code: code!,
              environment: environment!
            }
          });
        };

        return (
          <SalesforceOAuthCallbackPage
            connectAccount={connectAccount}
            loading={loading}
            errors={error}
            missingQueryParams={missingParams}
            data={data}
            called={called}
          />
        );
      }}
    </Mutation>
  );
};

export default WithMutation;
