import React from "react";
import { ApolloError, gql } from "apollo-boost";
import { Mutation } from "react-apollo";
import { Redirect } from "react-router";
import useBodyClass from "../../utils/useBodyClass";
import useForm from "react-hook-form";
import { toast } from "react-toastify";
import Container from "react-bootstrap/Container";
import Helmet from "react-helmet";
import HeaderLoggedOut from "../../components/HeaderLoggedOut";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import FormError, { HasError } from "../../components/FormError";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { RBRef } from "../../components/form/types";

interface Props {
  resetPassword: (newPassword: string) => Promise<any>;
  data?: ResetPasswordResponse;
  error?: ApolloError;
  loading: boolean;
}

const ResetPasswordPage: React.FC<Props> = props => {
  const { resetPassword, loading, data, error } = props;
  const { handleSubmit, register } = useForm();
  useBodyClass("bg-gradient-primary");

  const onSubmit = (data: Record<string, any>) =>
    resetPassword(data.newPassword);

  if (data && data.finishPasswordReset === true) {
    toast("Your password has been changed, you can login now.", {
      type: "success"
    });
    return <Redirect to="/login" />;
  }

  return (
    <Container>
      <Helmet>
        <title>Reset Password – HookActions</title>
      </Helmet>

      <HeaderLoggedOut />

      <Row className="justify-content-center">
        <Col xl="10" lg="12" md="9">
          <Card className="o-hidden border-0 shadow-lg my-5">
            <Card.Body className="p-0">
              <Row>
                <Col lg="6" className="d-lg-block p-5">
                  <img
                    className="img-fluid"
                    src="/img/reset_password.svg"
                    alt="reset password"
                  />
                </Col>
                <Col
                  lg="6"
                  className="d-flex flex-col justify-content-center align-items-center"
                >
                  <div className="p-5">
                    <div className="text-center">
                      <h1 className="h4 text-gray-900 mb-2">Reset password</h1>
                      <p className="mb-4">
                        Finish resetting your password to login again.
                      </p>
                    </div>
                    <Form className="user" onSubmit={handleSubmit(onSubmit)}>
                      <Form.Group>
                        <Form.Control
                          type="password"
                          name="newPassword"
                          placeholder="Password"
                          className="form-control-user"
                          autoComplete="new-password"
                          ref={register({ required: true }) as RBRef}
                          disabled={loading}
                          isInvalid={
                            HasError(error, "password") ||
                            HasError(error, "code")
                          }
                        />
                        <FormError errors={error} fieldName="password" />
                        <FormError errors={error} fieldName="code" />
                      </Form.Group>
                      <Button
                        className="btn-user"
                        block={true}
                        type="submit"
                        disabled={loading}
                      >
                        <span>Reset Password</span>
                        {loading && (
                          <Spinner
                            className="ml-2"
                            animation="border"
                            size="sm"
                          />
                        )}
                      </Button>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

interface ResetPasswordResponse {
  finishPasswordReset: boolean;
}

interface ResetPasswordVars {
  code: string;
  newPassword: string;
}

const RESET_PASSWORD = gql`
  mutation ResetPassword($code: UUID!, $newPassword: String!) {
    finishPasswordReset(code: $code, newPassword: $newPassword)
  }
`;

const WithMutation: React.FC = () => {
  const params = new URLSearchParams(window.location.search);
  const code = params.get("code");

  if (!code) {
    return <Redirect to="/login" />;
  }

  return (
    <Mutation<ResetPasswordResponse, ResetPasswordVars>
      mutation={RESET_PASSWORD}
    >
      {(mutate, { data, loading, error }) => (
        <ResetPasswordPage
          resetPassword={newPassword =>
            mutate({ variables: { newPassword, code } })
          }
          data={data}
          loading={loading}
          error={error}
        />
      )}
    </Mutation>
  );
};

export default WithMutation;
