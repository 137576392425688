import React from "react";
import Alert from "react-bootstrap/Alert";
import moment from "moment";
import { Link } from "react-router-dom";

interface Props {
  paymentsFeatureEnabled: boolean;
  daysLeftInFreeTrial?: number;
  freeTrialEndDate?: string;
  hasValidSubscription: boolean;
}

const PaymentsBanner: React.FC<Props> = ({
  paymentsFeatureEnabled,
  daysLeftInFreeTrial,
  freeTrialEndDate,
  hasValidSubscription
}) => {
  if (!paymentsFeatureEnabled) {
    return null;
  }

  if (freeTrialEndDate == null || daysLeftInFreeTrial == null) {
    return null;
  }

  if (hasValidSubscription && daysLeftInFreeTrial === 0) {
    return null;
  }

  if (!hasValidSubscription && daysLeftInFreeTrial === 0) {
    return (
      <Alert variant="danger">
        Your free trial has ended.{" "}
        <Link to="/billing/start-subscription" className="alert-link">
          Start your subscription now to avoid service interruptions &rsaquo;
        </Link>
      </Alert>
    );
  }

  const variant = daysLeftInFreeTrial <= 5 ? "danger" : "warning";

  return (
    <Alert variant={variant}>
      Your free trial will end in{" "}
      <span title={moment(freeTrialEndDate).format("ll")}>
        {daysLeftInFreeTrial}&nbsp;
        {`day${daysLeftInFreeTrial === 1 ? "" : "s"}`}
      </span>
      . Thank you for trying HookActions!
    </Alert>
  );
};

export default PaymentsBanner;
