import React from "react";
import useBodyClass from "../../utils/useBodyClass";
import Container from "react-bootstrap/Container";
import Helmet from "react-helmet";
import HeaderLoggedOut from "../../components/HeaderLoggedOut";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

const ForgotPasswordCompletePage: React.FC = () => {
  useBodyClass("bg-gradient-primary");

  return (
    <Container>
      <Helmet>
        <title>Forgot Password Complete – HookActions</title>
      </Helmet>

      <HeaderLoggedOut />

      <Row className="justify-content-center">
        <Col xl="10" lg="12" md="9">
          <Card className="o-hidden border-0 shadow-lg my-5">
            <Card.Body className="p-0">
              <Row>
                <Col lg="6" className="d-lg-block p-5">
                  <img
                    className="img-fluid"
                    src="/img/mail_sent.svg"
                    alt="mail sent"
                  />
                </Col>
                <Col
                  lg="6"
                  className="d-flex flex-row align-items-center justify-content-center"
                >
                  <div className="p-5">
                    <div className="text-center">
                      <h1 className="h4 text-gray-900 mb-2">Email sent!</h1>
                      <p>Check your email for the password reset link.</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPasswordCompletePage;
