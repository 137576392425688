import React, { useState } from "react";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { ApolloError } from "apollo-boost";
import useForm from "react-hook-form";
import Form from "react-bootstrap/Form";
import FormError, { HasError } from "../../components/FormError";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import { AppContext } from "../../App";
import Alert from "react-bootstrap/Alert";
import { RBRef } from "../../components/form/types";

interface Props {
  loading: boolean;
  error?: ApolloError;
  mutate: (variables: Vars) => Promise<any>;
  organizationId?: string;
}

const ChangeBillingEmailForm: React.FC<Props> = ({
  loading,
  error,
  mutate,
  organizationId
}) => {
  const { register, handleSubmit, reset } = useForm();
  const [showAlert, setShowAlert] = useState(false);

  const onSubmit = (data: Record<string, any>) =>
    mutate({
      orgId: organizationId!,
      newEmail: data.billing_email_address
    }).then(() => {
      reset();
      setShowAlert(true);
    });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {showAlert && (
        <Alert
          dismissible={true}
          onClose={() => setShowAlert(false)}
          variant="success"
        >
          Billing email updated.
        </Alert>
      )}

      <Form.Group>
        <Form.Control
          name="billing_email_address"
          type="email"
          placeholder="e.g. mary@hookactions.com"
          ref={register({ required: true }) as RBRef}
          disabled={loading}
          isInvalid={HasError(error, "billing_email_address")}
        />
        <FormError errors={error} fieldName="billing_email_address" />
      </Form.Group>

      <Button type="submit" block={true} variant="primary" disabled={loading}>
        <span>Change billing email</span>
        {loading && <Spinner animation="border" className="ml-2" size="sm" />}
      </Button>
    </Form>
  );
};

const MUTATION = gql`
  mutation ChangeBillingEmail($orgId: ID!, $newEmail: String!) {
    patchOrganization(id: $orgId, patch: { billingEmailAddress: $newEmail }) {
      id
      billingEmailAddress
    }
  }
`;

interface Vars {
  orgId: string;
  newEmail: string;
}

interface Response {
  patchOrganization?: {
    id: string;
    billingEmailAddress: string;
  };
}

const WithMutation: React.FC = () => {
  return (
    <AppContext.Consumer>
      {({ organizationId }) => (
        <Mutation<Response, Vars> mutation={MUTATION}>
          {(mutate, { loading, error }) => (
            <ChangeBillingEmailForm
              loading={loading}
              error={error}
              mutate={variables => mutate({ variables })}
              organizationId={organizationId}
            />
          )}
        </Mutation>
      )}
    </AppContext.Consumer>
  );
};

export default WithMutation;
