import React from "react";
import Row from "react-bootstrap/Row";

const HeaderLoggedOut = () => {
  return (
    <Row className="justify-content-center align-items-center mt-5">
      <img
        src="/logo_white.svg"
        alt="hookactions logo"
        className="img-fluid"
        style={{ maxHeight: "60px" }}
      />
      <p className="h4 text-white mx-3">HookActions</p>
    </Row>
  );
};

export default HeaderLoggedOut;
