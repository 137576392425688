import React from "react";

const LoadingDots = () => {
  return (
    <span className="loading">
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </span>
  );
};

export default LoadingDots;
