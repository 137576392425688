import React, { useState } from "react";
import {
  StripeWebhookNode,
  StripeWebhookEvent,
  webhookEventToStripeName
} from "../../../graphql/stripe";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { Redirect } from "react-router";
import { toast } from "react-toastify";
import { ApolloError } from "apollo-boost";
import useForm from "react-hook-form";
import Form from "react-bootstrap/Form";
import FormError, { HasError } from "../../../components/FormError";
import StripeAccountSelect from "./StripeAccountSelect";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import TagEditor from "../../../components/form/tags/TagEditorWithQuery";
import GenericError from "../../../components/GenericError";
import { RBRef } from "../../../components/form/types";

interface Props {
  loading: boolean;
  error?: ApolloError;
  createWebhook: (variables: WebhookVars) => void;
}

const StripeWebhookForm: React.FC<Props> = props => {
  const [tags, setTags] = useState();
  const { register, handleSubmit } = useForm();
  const { error, loading, createWebhook } = props;

  const onSubmit = (record: Record<string, any>) =>
    createWebhook({
      name: record.name,
      accountId: record.accountId,
      url: record.url,
      events: record.events,
      tags
    });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <GenericError error={error} isForm={true} />

      <Form.Group>
        <Form.Label htmlFor="name">Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="e.g. Stripe charges"
          name="name"
          ref={register({ required: true }) as RBRef}
          isInvalid={HasError(error, "name")}
        />
        <FormError errors={error} fieldName="name" />
      </Form.Group>
      <StripeAccountSelect register={register} />
      <Form.Group>
        <Form.Label htmlFor="events">Events</Form.Label>
        <Form.Control
          as="select"
          multiple={true}
          ref={register({ required: true }) as RBRef}
          name="events"
          isInvalid={HasError(error, "events")}
        >
          {Object.keys(StripeWebhookEvent).map(event => (
            <option value={event} key={event}>
              {webhookEventToStripeName(event)}
            </option>
          ))}
        </Form.Control>
        <Form.Text>Select multiple with shift, ctrl, or cmd.</Form.Text>
        <FormError errors={error} fieldName="events" />
      </Form.Group>
      <Form.Group>
        <Form.Label htmlFor="url">URL</Form.Label>
        <Form.Control
          type="text"
          placeholder="e.g. https://example.com/my/webhook"
          name="url"
          ref={register({ required: true }) as RBRef}
          isInvalid={HasError(error, "url")}
        />
        <FormError errors={error} fieldName="url" />
      </Form.Group>
      <Form.Group>
        <Form.Label htmlFor="tags">Tags</Form.Label>
        <TagEditor
          name="tags"
          setValue={(_, tags) => setTags(tags)}
          existingTags={[]}
          mutationError={error}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label htmlFor="secret">
          <span className="mr-2">Secret</span>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="hmac-secret-help">
                The secret used to generate the HMAC signature.
              </Tooltip>
            }
          >
            <FontAwesomeIcon icon={faQuestionCircle} />
          </OverlayTrigger>
        </Form.Label>
        <Form.Control type="text" name="secret" disabled={true} />
        <Form.Text>
          This is automatically generated by{" "}
          <a href="https://stripe.com/docs/webhooks/signatures">Stripe</a>.
        </Form.Text>
      </Form.Group>

      <Button type="submit" block={true} variant="primary" disabled={loading}>
        <span>Create webhook</span>
        {loading && <Spinner animation="border" className="ml-2" size="sm" />}
      </Button>
    </Form>
  );
};

interface WebhookVars {
  accountId: string;
  events: string[];
  name: string;
  url: string;
  tags?: Array<{ key: string; value: string }>;
}

interface WebhookResponse {
  createStripeWebhook?: StripeWebhookNode;
}

const CREATE_WEBHOOK = gql`
  mutation CreateStripeWebhook(
    $accountId: ID!
    $events: [StripeWebhookEvent!]!
    $name: String!
    $url: String!
    $tags: [TagInput!]
  ) {
    createStripeWebhook(
      input: {
        accountId: $accountId
        name: $name
        url: $url
        events: $events
        tags: $tags
      }
    ) {
      id
      name
      createdAt
      updatedAt
      url
      events
      secret
    }
  }
`;

const WithMutation: React.FC = () => (
  <Mutation<WebhookResponse, WebhookVars> mutation={CREATE_WEBHOOK}>
    {(mutate, { loading, error, data }) => {
      if (
        !loading &&
        !error &&
        data &&
        data.createStripeWebhook &&
        data.createStripeWebhook.id
      ) {
        toast("Webhook created", { type: "success" });
        return <Redirect to="/webhooks/stripe" />;
      }

      return (
        <StripeWebhookForm
          loading={loading}
          error={error}
          createWebhook={variables => mutate({ variables })}
        />
      );
    }}
  </Mutation>
);

export default WithMutation;
