import React, { useEffect, useState } from "react";
import { RouterProps, withRouter, Redirect } from "react-router";
import { ApolloError, gql } from "apollo-boost";
import { Mutation } from "react-apollo";
import useBodyClass from "../../utils/useBodyClass";
import Container from "react-bootstrap/Container";
import Helmet from "react-helmet";
import HeaderLoggedOut from "../../components/HeaderLoggedOut";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import CenteredSpinner from "../../components/CenteredSpinner";
import LoadingDots from "../../components/LoadingDots";
import { toast } from "react-toastify";
import GenericError from "../../components/GenericError";
import Alert from "react-bootstrap/Alert";
import ContactUs from "../../components/ContactUs";

interface Props extends RouterProps {
  hasVerificationCode: boolean;
  confirmEmail: () => Promise<any>;
  loading: boolean;
  error?: ApolloError;
  data?: {
    id: string;
  };
}

const ConfirmEmailPage: React.FC<Props> = props => {
  const { hasVerificationCode, confirmEmail, loading, error, data } = props;

  const [timedOut, setTimedOut] = useState(false);

  useBodyClass("bg-gradient-primary");

  useEffect(() => {
    if (hasVerificationCode) {
      confirmEmail();
    }
    setTimeout(() => setTimedOut(true), 5000);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!hasVerificationCode) {
    toast("Missing verification code, try again.", { type: "error" });
    return <Redirect to="/login" />;
  }

  if (data && data.id) {
    return <Redirect to="/confirm-email/complete" />;
  }

  return (
    <Container>
      <Helmet>
        <title>Completing email verification – HookActions</title>
      </Helmet>

      <HeaderLoggedOut />

      <Row className="justify-content-center">
        <Col xl="10" lg="12" md="9">
          <Card className="o-hidden border-0 shadow-lg my-5">
            <Card.Body className="p-0">
              <Row>
                <Col lg="6" className="d-lg-block p-5">
                  <img
                    className="img-fluid"
                    src="/img/loading.svg"
                    alt="verifying email"
                  />
                </Col>
                <Col
                  lg="6"
                  className="d-flex flex-row align-items-center justify-content-center"
                >
                  <div className="p-5">
                    <div className="text-center">
                      <h1 className="h4 text-gray-900 mb-4">
                        Completing email verification <LoadingDots />
                      </h1>
                      {loading && <CenteredSpinner />}
                      <GenericError error={error} />
                      {timedOut && (
                        <Alert variant="danger" style={{ textAlign: "left" }}>
                          <p>Unable to confirm email.</p>
                          <ContactUs
                            subject="Unable to confirm email"
                            message={`url params: ${window.location.search}`}
                          />
                        </Alert>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

interface Vars {
  code: string;
}
interface Response {
  confirmUserEmail?: {
    id: string;
  };
}

const CONFIRM_USER_EMAIL = gql`
  mutation ConfirmUserEmail($code: UUID!) {
    confirmUserEmail(code: $code) {
      id
    }
  }
`;

const WithMutation: React.FC<RouterProps> = props => {
  const params = new URLSearchParams(window.location.search);

  return (
    <Mutation<Response, Vars> mutation={CONFIRM_USER_EMAIL}>
      {(mutate, { loading, data, error }) => (
        <ConfirmEmailPage
          hasVerificationCode={params.has("verification_code")}
          confirmEmail={() =>
            mutate({ variables: { code: params.get("verification_code")! } })
          }
          loading={loading}
          error={error}
          data={data && data.confirmUserEmail}
          {...props}
        />
      )}
    </Mutation>
  );
};

export default withRouter(WithMutation);
