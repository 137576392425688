import React, { useState } from "react";
import gql from "graphql-tag";
import Table from "../../../components/GraphQLTable";
import {
  CopperCRMAccounts_copperCRMAccountsConnection_edges_node,
  DeleteCopperCRMAccount,
  DeleteCopperCRMAccountVariables
} from "../../../graphql/types";
import { Column } from "../../../components/Table";
import TimeRenderer from "../../../components/TimeRenderer";
import Button from "react-bootstrap/Button";
import { Mutation } from "react-apollo";
import ConfirmDialog from "../../../components/Dialog/ConfirmDialog";
import { ApolloError } from "apollo-client";
import { toast } from "react-toastify";

const COPPER_CRM_ACCOUNTS = gql`
  query CopperCRMAccounts($first: Int, $after: String, $before: String) {
    copperCRMAccountsConnection(first: $first, after: $after, before: $before) {
      edges {
        node {
          id
          createdAt
          updatedAt
          name
          tokenOwnerEmail
        }
        cursor
      }

      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }

      totalCount
    }
  }
`;

interface DeleteModalState {
  show: boolean;
  accountId?: string;
}

interface Props {
  deleteAccount: (id: string) => Promise<any>;
  deleteInProgress: boolean;
  error?: ApolloError;
}

const CopperCRMAccountsTable: React.FC<Props> = ({
  deleteAccount,
  deleteInProgress,
  error
}) => {
  const [deleteModalState, setDeleteModalState] = useState<DeleteModalState>({
    show: false
  });

  const columns: Column<
    CopperCRMAccounts_copperCRMAccountsConnection_edges_node
  >[] = [
    {
      key: "id",
      hidden: true
    },
    {
      key: "name",
      label: "Name"
    },
    {
      key: "createdAt",
      label: "Created at",
      renderer: TimeRenderer
    },
    {
      key: "tokenOwnerEmail",
      label: "Token owner email"
    },
    {
      key: "id",
      renderer: ({ cell }) => (
        <Button
          variant="link"
          className="text-danger p-0"
          onClick={() => setDeleteModalState({ show: true, accountId: cell })}
        >
          Delete
        </Button>
      )
    }
  ];

  return (
    <>
      <Table<CopperCRMAccounts_copperCRMAccountsConnection_edges_node>
        query={COPPER_CRM_ACCOUNTS}
        name="copperCRMAccountsConnection"
        columns={columns}
      />
      <ConfirmDialog
        variant="danger"
        actionText="Delete"
        loading={deleteInProgress}
        text={
          <>
            <p>Are you sure you want to delete this Copper CRM account?</p>
            <p>
              This will delete <strong>all</strong> associated webhooks.
            </p>
          </>
        }
        title="Delete Copper CRM account"
        error={error}
        show={deleteModalState.show}
        handleCancel={() => setDeleteModalState({ show: false })}
        handleAction={() =>
          deleteAccount(deleteModalState.accountId!).then(() =>
            setDeleteModalState({ show: false })
          )
        }
      />
    </>
  );
};

const DELETE_COPPER_CRM_ACCOUNT = gql`
  mutation DeleteCopperCRMAccount($id: ID!) {
    deleteCopperCRMAccount(id: $id)
  }
`;

const WithMutation: React.FC = () => (
  <Mutation<DeleteCopperCRMAccount, DeleteCopperCRMAccountVariables>
    mutation={DELETE_COPPER_CRM_ACCOUNT}
  >
    {(mutate, { loading, error }) => (
      <CopperCRMAccountsTable
        deleteAccount={id =>
          mutate({ variables: { id } }).then(() =>
            toast("Account deleted", { type: "success" })
          )
        }
        deleteInProgress={loading}
        error={error}
      />
    )}
  </Mutation>
);

export default WithMutation;
