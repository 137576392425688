import React from "react";
import { ApolloError } from "apollo-boost";
import { WebhookTagKeyNode, WebhookTag } from "../../../graphql/webhooks";
import EditTable, { Column, Value } from "../EditTable";
import GenericError from "../../GenericError";
import CenteredSpinner from "../../CenteredSpinner";
import uniq from "lodash.uniq";

export interface TagEditorProps {
  loading: boolean;
  queryError?: ApolloError;
  mutationError?: ApolloError;
  name: string;
  setValue: (name: string, value: any) => void;
  autoFillTags: WebhookTagKeyNode[];
  existingTags: WebhookTag[];
}

const keyInputName = "key";
const valueInputName = "value";

const valuesToObject = (values: Value[]) => {
  const keys = values.filter(v => v.inputName === keyInputName);
  const vs = values.filter(v => v.inputName === valueInputName);

  if (keys.length === 0 || vs.length === 0) {
    return null;
  } else if (!keys[0].value || !vs[0].value) {
    return null;
  }

  return {
    key: keys[0].value,
    value: vs[0].value
  };
};

const transformListTagsToProperValues = (values: Value[][]) => {
  return values.map(valuesToObject).filter(v => v != null);
};

const TagEditor: React.FC<TagEditorProps> = ({
  loading,
  queryError,
  mutationError,
  name,
  setValue,
  autoFillTags
}) => {
  if (loading) {
    return <CenteredSpinner />;
  }
  const valuesByKeyName = new Map<string, string[]>();
  autoFillTags.forEach(t => {
    valuesByKeyName.set(t.name, t.values.map(v => v.value));
  });
  const getAutoSuggestions = (values: Value[]): string[] => {
    const keyValue = values.filter(v => v.inputName === keyInputName)[0].value;
    if (keyValue.toLowerCase() === "name") {
      return [];
    }
    return valuesByKeyName.get(keyValue) || [];
  };

  const columns: Column[] = [
    {
      name: "Key",
      inputName: keyInputName,
      placeholder: "e.g. Environment",
      autoSuggestions: uniq(["name", ...autoFillTags.map(t => t.name)])
    },
    {
      name: "Value",
      inputName: valueInputName,
      placeholder: "e.g. Production",
      getAutoSuggestions
    }
  ];

  return (
    <>
      <GenericError error={queryError} />
      <EditTable
        columns={columns}
        name={name}
        error={mutationError}
        onDataChange={data =>
          setValue(name, transformListTagsToProperValues(data))
        }
      />
    </>
  );
};

export default TagEditor;
