import React from "react";
import FormControl from "react-bootstrap/FormControl";
import { ApolloError } from "apollo-boost";
import get from "lodash.get";
import { extractValidationErrors } from "../utils/graphql";

export interface Error {
  field?: string;
  message: string;
}

interface Props {
  errors?: Error[] | ApolloError;
  fieldName: string;
}

export default (props: Props) => {
  const { errors: propErrors, fieldName } = props;
  let errors: Error[] = [];

  if (!!propErrors) {
    if (propErrors instanceof ApolloError) {
      errors = extractValidationErrors(propErrors);
    } else {
      errors = propErrors as Error[];
    }
  }

  if (!HasError(errors, fieldName)) {
    return null;
  }

  const err = errors.filter(e => e.field === fieldName)[0];
  return (
    <FormControl.Feedback type="invalid">
      {capitalize(err.message)}
    </FormControl.Feedback>
  );
};

export const HasError = (
  propErrors: Error[] | ApolloError | undefined,
  fieldName: string
) => {
  let errors: Error[] = [];

  if (!!propErrors) {
    if (propErrors instanceof ApolloError) {
      errors = extractValidationErrors(propErrors);
    } else {
      errors = propErrors as Error[];
    }
  }

  return errors.filter(err => err.field === fieldName).length > 0;
};

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

export const HasAuthError = (error?: ApolloError) => {
  if (!error) return false;
  if (error.networkError && (error.networkError as any).statusCode === 401) {
    return true;
  }
  const authErrors = error.graphQLErrors.filter(e => {
    return get(e, "extensions.type") === "authorization_error";
  });
  return authErrors.length > 0;
};
